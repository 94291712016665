import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import SEO from "../components/seo/SEO";

const Categories = () => {
  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  // const [catSelected, setCatSelected] = useState({
  //   id: null,
  //   title: ''
  // })
  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoryAlphabet();
    // return () => {

    // }
  }, []);

  const getCategoryAlphabet = () => {
    // const apiUrl = process.env.REACT_APP_API_URL+'/api/category/alphabet';
    const apiUrl = process.env.REACT_APP_API_URL + "/api/category/index";
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          setListData(
            results.map((r) => {
              return {
                ...r,
                isActive: false,
              };
            })
          );
          setLoading(false);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  // console.log({listData});

  return (
    <Layout>
      <SEO
        title="Browse Ads Categories | Paseero.ng"
        description="Explore a wide range of product categories on Paseero.ng. Find the best deals on electronics, fashion, real estate, jewelry, and more!"
        name="Paseero.ng | Ad Categories"
        type="website"
        robots="index, follow"
      />
      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">
            All Categories
          </div>
          {/* <div className=" mt-2 text-sm lg:text-xl text-[#212529]">List of all categories</div>
          <div className=" lg:flex flex-row space-x-8 items-center mt-4 hidden">
            <div 
              className={` ${catSelected.id === null ? 'bg-black p-1 px-4 rounded text-xl font-medium text-white' : 'text-xl text-blue-500 hover:underline cursor-pointer'}`}
              onClick={() => setCatSelected({ id: null, title: ''})}
            >
                All
            </div>
            {!isLoading && listData.map((row, index) => (
              <div 
                key={index} 
                className={` ${catSelected.id === index ? 'bg-black p-1 px-4 rounded text-xl font-medium text-white' : 'text-xl text-blue-500 hover:underline cursor-pointer'}`}
                onClick={() => setCatSelected({ id: index, title: row.alphabet})}
              >
                {row.alphabet}
              </div>
            ))}
          </div>
          <select 
            className=" mt-4 w-full lg:hidden border rounded p-2"
            onChange={(e) => {
              let str = e.target.value.split("-")
              setCatSelected({ id: e.target.value === '' ? null : str[0]  , title: e.target.value === '' ? '' : str[1]})
            }}
          >
            <option value="">All Categories</option>
            {listData.map((row, index) => (
              <option key={row?.id} value={index+'-'+row.alphabet}>{row?.data[0]?.title}</option>
            ))}
          </select> */}
        </div>
      </div>
      {/* <div className=" flex justify-center w-full py-8 bg-white">
        {!isLoading ? (        
          <div className=" w-[1300px] space-y-8 px-4 lg:px-0">
            {listData.filter((f) => typeof f.alphabet === "string" && f.alphabet.includes(catSelected.title)).map((row, index) => (
              <div key={index} >
                <div                   
                  className=" border-b border-[#E5E5E5] pb-2 text-[#212529] text-2xl lg:text-3xl font-medium"
                >
                  {row.alphabet}
                </div>
                <div className=" flex flex-col space-y-4 mt-6">
                  {row?.data?.map(ls => (
                    <Link 
                      key={ls.id}
                      to={`/market-place?category=${ls.title}`}
                      className="text-[#212529] lg:text-2xl cursor-pointer hover:underline"
                    >
                      {ls.title}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ): null}
      </div> */}
      <div className=" flex justify-center w-full py-8 bg-white">
        {!isLoading ? (
          <div className=" w-[1300px] space-y-8 px-4 lg:px-0">
            {/* {listData.filter((f) => typeof f.alphabet === "string" && f.alphabet.includes(catSelected.title)).map((row, index) => ( */}
            {listData.map((row, index) => (
              <div key={index}>
                <div
                  className=" flex flex-row items-center border-b border-[#E5E5E5] pb-2 space-x-4 cursor-pointer"
                  onClick={() => {
                    let list = [...listData];
                    list[index] = {
                      ...(list[index] = {
                        ...list[index],
                        isActive: !list[index].isActive,
                      }),
                    };
                    setListData(list);
                  }}
                >
                  <img alt={row.title} src={row.image} className=" w-8 h-8" />
                  <div className="  text-[#212529] text-2xl lg:text-3xl font-medium">
                    {row.title}
                  </div>
                </div>
                <div
                  className={` flex flex-col space-y-4 mt-6 ${
                    row.isActive ? "block" : "hidden"
                  }`}
                >
                  {row?.subcategories?.map((ls) => (
                    <Link
                      key={ls.id}
                      // to={`/market-place?category=${ls.title}`}
                      to={`/market-place?category=${row.id}&title=${row.title}&subcategory=${ls.id}`}
                      className="text-[#212529] lg:text-2xl cursor-pointer hover:underline"
                    >
                      {ls.title}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <hr className=" my-12" />
    </Layout>
  );
};

export default Categories;
