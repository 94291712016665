import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import Layout from "./Layout";
import axios from "axios";
import SEO from "../components/seo/SEO";

const PrivacyPolicy = () => {
  const [optionActive, setOptionActive] = useState({
    id: 0,
    isActive: false,
  });
  const [isLoading, setLoading] = useState(true);
  const [listPolicy, setListPolicy] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchAll = () => {
      Promise.all([getApi("POLICY", "/api/master/policies/index")])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          if (type === "POLICY") {
            setListPolicy(results);
          }
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleShowHide = (id) => {
    setOptionActive({
      id: optionActive.id === id ? 0 : id,
      isActive: optionActive.id === id ? false : true,
    });
  };

  return (
    <Layout>
      <SEO
        title="Privacy Policy | Paseero – Your Data, Your Privacy"
        description="Learn how Paseero collects, uses, and protects your personal data. Read our Privacy Policy to understand your rights and how we ensure your information stays secure."
        name="Paseero.ng | Privacy Policy"
        type="website"
        robots="index, follow"
      />

      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">
            Privacy Policy
          </div>
          {/* <div className=" mt-2 text-xl text-[#212529]">Let's Talk</div> */}
        </div>
      </div>

      <div className=" flex justify-center w-full mt-4 lg:mt-12">
        <div className="flex flex-col lg:flex-row w-[1300px] lg:space-x-12 px-4 lg:px-0">
          <div className=" w-1/4 hidden lg:block space-y-8">
            <div className=" bg-white border border-gray-300 rounded flex items-center text-center flex-col space-y-6 p-4">
              <div>
                <IoMailOpenOutline className=" text-9xl" />
              </div>
              <div className=" text-black text-xl font-semibold">
                Join Our Newsletter
              </div>
              <div className=" text-[#7F7F7F] font-light tracking-wide text-sm">
                Join our subscribers list to get the latest <br />
                news, updates and special offers <br />
                delivered directly in your inbox.
              </div>
              <div className=" items-center flex">
                <input
                  className=" h-12 px-4 w-60 bg-white placeholder-grey border border-gray-300 rounded-l focus:outline-none"
                  placeholder="Email address here.."
                />
                <button className=" h-12 w-20 font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-l-[#B38C32] hover:border-white duration-300">
                  Join
                </button>
              </div>
            </div>
          </div>
          <div className=" lg:w-3/4 space-y-8">
            <div className=" text-sm lg:text-lg tracking-wide leading-7">
              Paseero takes seriously the privacy of users’ data. Data
              protection is taken seriously to ensure that personal data are
              secure. The Privacy Policy states how users’ data are collected,
              used, disclosed, transferred, and stored. You are strongly advised
              to read this information. Should you have concerns about how your
              data is handled or other questions, please contact us with
              contact@paseero.ng or use the contact form on our&nbsp;
              <Link
                to={"/contact-us"}
                className=" text-[#B38C32] hover:underline font-bold"
              >
                contact us&nbsp;
              </Link>
              page.
            </div>
            <div className=" grid grid-cols-1 gap-4 gap-x-8">
              {!isLoading &&
                listPolicy.map((row) => (
                  <div
                    className=" p-4 lg:px-6 bg-white border rounded cursor-pointer"
                    onClick={() => handleShowHide(row.id)}
                  >
                    <div className=" flex items-center justify-between">
                      <div className=" text-sm lg:text-xl font-semibold">
                        {row.title}
                      </div>
                      {optionActive.id === row.id && optionActive.isActive ? (
                        <FaChevronDown className=" lg:text-2xl" />
                      ) : (
                        <FaChevronRight className=" lg:text-2xl" />
                      )}
                    </div>
                    {optionActive.id === row.id && optionActive.isActive ? (
                      <div
                        className=" text-sm lg:text-lg leading-7 font-light my-4"
                        dangerouslySetInnerHTML={{ __html: row.description }}
                      />
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <hr className=" my-12" />
    </Layout>
  );
};

export default PrivacyPolicy;
