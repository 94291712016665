import React, { useState } from "react";
import { LiaTimesCircleSolid } from "react-icons/lia";

const ModalAdTermsBeforeAdPost = ({ onConfirm, onCancel }) => {
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-30">
      <div className="bg-white rounded-lg shadow-lg p-8 w-[630px] max-w-full">
        {/* Header */}
        <div className="text-2xl font-semibold text-[#1E3A8A] mb-3">
          Review Our Posting Rules Before Posting an Ad
        </div>
        <hr className="border-t-2 border-gray-200 mb-3" />
        {/* Body */}
        <div className="bg-gray-100 p-5 rounded-md mb-6">
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li>Each ad must include details and photos for only one item.</li>
            <li>Ads featuring multiple or mixed items will be rejected.</li>
            <li>
              Provide real-life photos, as internet-sourced images may be
              rejected.
            </li>
            <li>
              Ads with incorrect descriptions, prices, or photos will be
              rejected.
            </li>
            <li>Detailed and accurate descriptions increase ad engagement.</li>
            <li>
              Provide the correct item category, brand, year, size, color, etc.
            </li>
          </ul>
        </div>

        {/* Checkbox */}
        <div className="flex items-center mb-6">
          <input
            type="checkbox"
            id="terms"
            checked={isAccepted}
            onChange={() => setIsAccepted(!isAccepted)}
            className="mr-2 w-5 h-5 cursor-pointer accent-[#B38C32]"
          />
          <label htmlFor="terms" className="text-gray-800 text-sm">
            Tick the box and click "Continue" to proceed
          </label>
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            className="bg-gray-200 text-gray-700 border border-gray-400 px-5 py-2 rounded-md hover:bg-gray-300 transition"
            onClick={() => onCancel(false)}
          >
            Cancel
          </button>
          <button
            className={`px-5 py-2 rounded-md transition ${
              isAccepted
                ? "bg-[#B38C32] text-white font-semibold border border-[#B38C32] hover:bg-white hover:text-[#B38C32]"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isAccepted}
            onClick={() => {
              if (isAccepted) {
                onConfirm(false);
              }
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAdTermsBeforeAdPost;
