import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaTimes, FaPlus, FaImage } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setUser } from "../redux/authSlice";
import { setCity } from "../redux/filterSlice";
import LoadingThreeDots from "./LoadingThreeDots";

const SettingsContent = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const detail = useSelector((state) => state.auth.user);
  const filter = useSelector((state) => state.filter);

  const refPhoto = useRef();
  const refBackground = useRef();
  const [isLoading, setLoading] = useState(false);
  // const [showUsername, setShowUsername] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [showBasic, setShowBasic] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAvatar, setShowAvatar] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [state, setState] = useState(detail);
  const [address, setAddress] = useState(detail.address);
  const [bank, setBank] = useState(detail.bank);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [listCity, setListCity] = useState(filter?.city);
  const [listLocal, setListLocal] = useState(filter?.local);
  const [listBank, setListBank] = useState([]);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");
  const [files, setFiles] = useState({
    photo: "",
    background: "",
  });
  const [isLoadingSave, setLoadingSave] = useState(false);
  // const [progressBar, setProgressBar] = useState(1);

  useEffect(() => {
    document.title = "Settings | Paseero";

    const fetchAll = () => {
      Promise.all([
        filter?.city?.length === 0 && getApi("CITY", "/api/category/states"),
        getApi(
          "LOCAL",
          "/api/category/localgovernments?state_id=" + detail?.address?.state_id
        ),
        getApi("BANK", "/api/master/banks"),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            if (type === "CITY") {
              dispatch(setCity(results));
              setListCity(results);
            } else if (type === "LOCAL") {
              setListLocal(results);
            } else if (type == "DELETE-ACCOUNT") {
              console.log("account deleted");
            } else {
              setListBank(results);
              // setBank(prevState => {
              //   return {
              //     ...prevState,
              //     swift_code_bic: results[0].code
              //   }
              // })
            }
            // setLoading(false)
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
        setLoading(false);
      });
  };

  const postApi = (type, endPoint) => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(apiUrl, {}, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            if (type == "DELETE-ACCOUNT") {
              console.log("account deleted");
            } else {
              console.log("######");
            }
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setAddress((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === "state_id") {
      let list = listCity.filter((f) => f.id === parseInt(value))[0]
        .local_governments;
      setListLocal(list);
    }
  };

  const handleChangeBank = (e) => {
    const { name, value } = e.target;
    setBank((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeFiles = (e) => {
    const { name, files } = e.target;
    // console.log('files',files);
    if (files && files.length) {
      setFiles((prevState) => {
        return {
          ...prevState,
          [name]: { file: files[0] },
        };
      });

      setState((prevState) => {
        return {
          ...prevState,
          photo:
            name === "photo" ? URL.createObjectURL(files[0]) : prevState.photo,
          background_image:
            name === "background"
              ? URL.createObjectURL(files[0])
              : prevState.background_image,
        };
      });
    }
  };

  const handleSaveChange = () => {
    setLoadingSave(true);

    const apiUrl = process.env.REACT_APP_API_URL + "/api/auth/me";
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      "Content-Type": "multipart/form-data",
    };

    const bodyForm = new FormData();

    if (!!password) {
      bodyForm.append("password", password);
    }
    if (!!rePassword) {
      bodyForm.append("password_confirmation", rePassword);
    }

    bodyForm.append("phone_code", state.phone_code);
    bodyForm.append("phone_number", state.phone_number);
    bodyForm.append("description", state.description);
    bodyForm.append("link", state.link);
    // bodyForm.append('preferences', state.preferences);
    bodyForm.append("business_name", state.business_name);
    bodyForm.append("first_name", state.first_name);
    bodyForm.append("last_name", state.last_name);

    bodyForm.append("country", "Nigera");
    bodyForm.append("address", address.address);
    bodyForm.append("state_id", address.state_id);
    bodyForm.append("local_government_id", address.local_government_id);

    if (files.photo !== "") {
      bodyForm.append("photo", files.photo.file);
    }
    if (files.background !== "") {
      bodyForm.append("background_image", files.background.file);
    }

    bodyForm.append("account_name", bank.account_name);
    bodyForm.append("iban", bank.iban);
    bodyForm.append("swift_code_bic", bank.swift_code_bic);

    axios
      .post(apiUrl, bodyForm, {
        headers,
        // onUploadProgress: (event) => {
        //     let progressValue = Math.round((event.loaded / event.total) * 100);
        //     console.log({progressValue});
        //     setProgressBar(progressValue)
        // }
      })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results, messages } = response.data.response;
            dispatch(setUser(results));
            Swal.fire({
              icon: "success",
              title: "Success",
              text: messages,
              confirmButtonColor: "#B38C32",
            });
            setLoadingSave(false);
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        const { messages, results } = error.response.data.response;
        Swal.fire({
          icon: "error",
          title: results.title,
          text: messages,
          confirmButtonColor: "#B38C32",
        });
        setLoadingSave(false);
      });
  };

  const onClickDeleteAccount = () => {
    postApi("DELETE-ACCOUNT", "/api/user/delete-account");
  };

  const LoadingIndicator = () => <LoadingThreeDots size={"20"} />;

  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">Settings</div>
        <div className=" mt-2 text-sm lg:text-lg">
          Please keep your account details updated.
        </div>
      </div>

      <div className=" mt-8">
        {/* <div 
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowUsername(!showUsername)}
        >
          <div className=" text-xl font-medium">Username</div>
          {showUsername ? <FaTimes className=" text-2xl"/> : <FaPlus className=" text-2xl"/> }
        </div>
        {showUsername ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-lg font-light text-gray-500">The username or email can be used to login.</div>
            <div className=" flex flex-row space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 w-1/2">
                <label className=" text-sm font-semibold">Username</label>
                <input 
                  disabled={true}
                  className=" p-2 px-4 bg-gray-50 rounded border focus:outline-none cursor-not-allowed" 
                  placeholder="Username" 
                  value={state?.user_name} 
                />
              </div>
              <div className=" flex flex-col space-y-1 w-1/2">
                <label className=" text-sm font-semibold">Display Name</label>
                <input 
                  className=" p-2 px-4 bg-gray-50 rounded border focus:outline-none" 
                  placeholder="Display name"
                  name="full_name"
                  value={state?.full_name}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        ): null} */}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          <div className=" lg:text-xl font-medium">Password</div>
          <button onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <FaTimes className=" lg:text-2xl" />
            ) : (
              <FaPlus className=" lg:text-2xl" />
            )}
          </button>
        </div>
        {showPassword ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              Only complete these fields if you wish to change the existing
              password.
            </div>
            <div className=" flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">Password</label>
                <input
                  type={"password"}
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">
                  Password Re-type
                </label>
                <input
                  type={"password"}
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="Password re-type"
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowEmail(!showEmail)}
        >
          <div className=" lg:text-xl font-medium">Email & Phone</div>
          {showEmail ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showEmail ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              A valid email is required.
            </div>
            <div className=" flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">Email</label>
                <input
                  disabled={true}
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none cursor-not-allowed"
                  placeholder="Email"
                  name="email"
                  value={state?.email}
                  onChange={handleChange}
                />
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">
                  Mobile Number <span className=" text-red-600">*</span>
                </label>
                <div className=" items-center flex">
                  {/* <select 
                    className=" p-2 w-14 bg-gray-50 rounded rounded-r-none border focus:outline-none"
                    name="phone_code"
                    value={state.phone_code}
                    onChange={handleChange}
                  >
                    <option value={'+234'}>🇳🇬 Nigeria (+234)</option>
                    <option value={'+227'}>🇳🇪 Niger (+227)</option>
                    <option value={'+505'}>🇳🇮 Nicaragua (+505)</option>
                    <option value={'+638'}>🇳🇺 Niue (+683)</option>
                    <option value={'+686723'}>🇳🇫 Norfolk Island (+686723)</option>
                  </select> */}
                  <input
                    className=" p-2 px-4 text-sm lg:text-base w-full bg-gray-50 rounded border focus:outline-none"
                    placeholder="Mobile number"
                    name="phone_number"
                    value={state.phone_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowAbout(!showAbout)}
        >
          <div className=" lg:text-xl font-medium">About Me</div>
          {showAbout ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showAbout ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              This information will be displayed on your public profile.
            </div>
            <div className=" flex flex-col space-y-4 mt-3">
              <div className=" flex flex-col space-y-1 w-full">
                <label className=" text-sm font-semibold">My Description</label>
                <textarea
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="My description"
                  rows={5}
                  name="description"
                  value={state?.description}
                  onChange={handleChange}
                />
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">My Web</label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="My web"
                  name="link"
                  value={state?.link}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowPreferences(!showPreferences)}
        >
          <div className=" lg:text-xl font-medium">Business Name</div>
          {showPreferences ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showPreferences ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              Business name to display on your public profile page.
            </div>
            <div className=" flex flex-row space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 w-full">
                <div className=" flex flex-col lg:flex-row lg:items-center space-y-1 lg:space-y-0 justify-between">
                  <label className=" text-sm font-semibold">
                    Enter Business Name
                  </label>
                  {state?.business_name.length < 51 ? (
                    <label className=" text-xs lg:text-sm">
                      <span className=" font-semibold">
                        {50 - state?.business_name.length}
                      </span>{" "}
                      Minimum characters remaining
                    </label>
                  ) : null}
                </div>
                <textarea
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="Business name"
                  rows={5}
                  name="business_name"
                  value={state?.business_name}
                  onChange={handleChange}
                />
                {/* <select 
                  className=" p-2 bg-gray-50 rounded border focus:outline-none"
                  name="preferences"
                  value={state.preferences}
                  onChange={handleChange}
                >
                  <option disabled>Enter Business Name</option>
                  <option value={'0'}>All Ads</option>
                  <option value={'1'}>Classified Only</option>
                  <option value={'2'}>Auction Only</option>
                </select> */}
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowBasic(!showBasic)}
        >
          <div className=" lg:text-xl font-medium">Basic Details</div>
          {showBasic ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showBasic ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              This information is not displayed on your profile.
            </div>
            <div className=" flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">
                  First Name <span className=" text-red-600">*</span>
                </label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none cursor-no-drop"
                  placeholder="First name"
                  name="first_name"
                  value={state.first_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">Last Name</label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none cursor-no-drop"
                  placeholder="Last name"
                  name="last_name"
                  value={state.last_name}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className=" lg:pr-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">My Language</label>
                <select
                  className=" p-2 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  defaultValue={1}
                >
                  <option value={1}>English</option>
                </select>
              </div>
              <div />
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowAddress(!showAddress)}
        >
          <div className=" lg:text-xl font-medium">Address </div>
          {showAddress ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showAddress ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              Displayed on invoices and purchase reciepts.
            </div>
            <div className=" flex flex-row  space-x-2 lg:space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 w-full">
                <label className=" text-sm font-semibold">
                  My Address <span className=" text-red-600">*</span>
                </label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="My address"
                  name="address"
                  value={address.address}
                  onChange={handleChangeAddress}
                />
              </div>
            </div>
            <div className=" flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:space-x-4 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/3">
                <label className=" text-sm font-semibold">Country</label>
                <select
                  className=" p-2 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  defaultValue={1}
                >
                  <option value={1}>Nigeria</option>
                </select>
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/3">
                <label className=" text-sm font-semibold">
                  State <span className=" text-red-600">*</span>
                </label>
                <select
                  className=" p-2 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  name="state_id"
                  value={address?.state_id}
                  onChange={handleChangeAddress}
                >
                  <option disabled>Select</option>
                  {!isLoading &&
                    listCity?.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className=" flex flex-col space-y-1 lg:w-1/3">
                <label className=" text-sm font-semibold">
                  Local Government <span className=" text-red-600">*</span>
                </label>
                <select
                  className=" p-2 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  name="local_government_id"
                  value={address.local_government_id}
                  onChange={handleChangeAddress}
                >
                  <option disabled>Select</option>
                  {!isLoading &&
                    listLocal?.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowAvatar(!showAvatar)}
        >
          <div className=" lg:text-xl font-medium">Profile Photo</div>
          {showAvatar ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showAvatar ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              Photo to display on your public profile page.
            </div>
            <div className=" flex flex-row space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">Photo</label>
                <div className=" flex flex-row space-x-8">
                  <div className=" relative">
                    <img
                      alt="avatar"
                      src={
                        state?.photo !== ""
                          ? state?.photo
                          : process.env.PUBLIC_URL + "/assets/Default.png"
                      }
                      className=" w-20 h-20 lg:w-40 lg:h-40 object-cover rounded-full border"
                    />
                    {files.photo !== "" ? (
                      <FaTimes
                        className=" text-4xl text-white bg-red-600 rounded-full p-1 cursor-pointer absolute right-0 top-0"
                        onClick={() => {
                          setFiles((prevState) => {
                            return { ...prevState, photo: "" };
                          });
                          setState((prevState) => {
                            return { ...prevState, photo: detail?.photo };
                          });
                        }}
                      />
                    ) : null}
                  </div>
                  {files.photo === "" ? (
                    <div
                      className=" justify-center flex items-center rounded w-40 lg:w-60 lg:h-40 bg-gray-50 border flex-col hover:bg-gray-100 duration-300 cursor-pointer"
                      onClick={() => refPhoto.current.click()}
                    >
                      <input
                        className=" hidden"
                        accept=".jpg,.jpeg,.png"
                        name="photo"
                        ref={refPhoto}
                        onChange={handleChangeFiles}
                        type="file"
                      />
                      <FaImage className=" text-3xl lg:text-5xl text-gray-500" />
                      <div className=" text-sm lg:text-lg text-gray-500">
                        Select Photo
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowBackground(!showBackground)}
        >
          <div className=" lg:text-xl font-medium">Background</div>
          {showBackground ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showBackground ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              The background image is displayed on your public profile page.
            </div>
            <div className=" flex flex-row space-x-8 mt-3">
              <div className=" flex flex-col space-y-2 w-full">
                <label className=" text-sm font-semibold">
                  Background Image
                </label>
                <label className=" text-sm  font-light text-gray-400">
                  Upload your own artwork (1300px / 200px)
                </label>
                {/* <img 
                    alt="avatar"
                    src={state?.photo !== '' ? state?.photo : process.env.PUBLIC_URL+'/assets/Default.png'  }
                    className=" w-40 h-40 object-cover rounded-full border"
                  />
                <div className=" justify-center flex items-center rounded w-full bg-gray-50 border p-12 flex-col hover:bg-gray-100 duration-300 cursor-pointer">
                  <FaImage className=" text-5xl text-gray-500" />
                  <div className=" text-lg text-gray-500">Select Photo</div>
                </div> */}
                <div className=" flex flex-col space-y-4">
                  <div className=" relative">
                    <img
                      alt="background"
                      src={
                        state?.background_image !== ""
                          ? state?.background_image
                          : process.env.PUBLIC_URL + "/assets/Background.png"
                      }
                      className=" w-full h-60 object-cover rounded border"
                    />
                    {files.background !== "" ? (
                      <FaTimes
                        className=" text-4xl text-white bg-red-600 rounded-full p-1 cursor-pointer absolute -right-1 -top-1"
                        onClick={() => {
                          setFiles((prevState) => {
                            return { ...prevState, background: "" };
                          });
                          setState((prevState) => {
                            return {
                              ...prevState,
                              background_imagephoto: detail?.background_image,
                            };
                          });
                        }}
                      />
                    ) : null}
                  </div>
                  {files.background === "" ? (
                    <div
                      className=" justify-center flex items-center rounded w-full h-60 bg-gray-50 border flex-col hover:bg-gray-100 duration-300 cursor-pointer"
                      onClick={() => refBackground.current.click()}
                    >
                      <input
                        className=" hidden"
                        accept=".jpg,.jpeg,.png"
                        name="background"
                        ref={refBackground}
                        onChange={handleChangeFiles}
                        type="file"
                      />
                      <FaImage className=" text-5xl text-gray-500" />
                      <div className=" text-lg text-gray-500">
                        Select Background
                      </div>
                    </div>
                  ) : null}
                </div>
                <label className=" text-sm  font-light text-gray-400">
                  or select a default display image.
                </label>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowBankAccount(!showBankAccount)}
        >
          <div className=" lg:text-xl font-medium">Bank Account </div>
          {showBankAccount ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showBankAccount ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" text-sm lg:text-lg font-light text-gray-500">
              Paseero will use this information to send you payments for your
              online sales.
            </div>
            <div className=" flex flex-row space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 w-full">
                <label className=" text-sm font-semibold">
                  Account Name <span className=" text-red-600">*</span>
                </label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="Account name"
                  name="account_name"
                  value={bank.account_name}
                  onChange={handleChangeBank}
                />
              </div>
            </div>
            <div className=" flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8 mt-3">
              <div className=" flex flex-col space-y-1 lg:w-1/2">
                <label className=" text-sm font-semibold">Account Number</label>
                <input
                  className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  placeholder="Account number"
                  name="iban"
                  value={bank.iban}
                  onChange={handleChangeBank}
                />
              </div>
              {/* <div className=" flex flex-col space-y-1 w-1/2">
                <label className=" text-sm font-semibold">Bank Name</label>
                <input 
                  className=" p-2 px-4 bg-gray-50 rounded border focus:outline-none" 
                  placeholder="Bank name" 
                  name="swift_code_bic"
                  value={bank.swift_code_bic}
                  onChange={handleChangeBank}
                />
              </div> */}
              <div className=" flex flex-col space-y-1 lg:w-1/2 ">
                <label className=" text-sm font-semibold">Bank Name</label>
                <select
                  className=" p-2 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                  name="swift_code_bic"
                  value={bank.swift_code_bic}
                  onChange={handleChangeBank}
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  {!isLoading &&
                    listBank?.map((row) => (
                      <option key={row.id} value={row.code}>
                        {row.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        ) : null}

        <div
          className=" flex justify-between border-b border-gray-400 pb-4 pt-4 cursor-pointer"
          onClick={() => setShowAccount(!showAccount)}
        >
          <div className=" lg:text-xl font-medium">Delete Account</div>
          {showAccount ? (
            <FaTimes className=" lg:text-2xl" />
          ) : (
            <FaPlus className=" lg:text-2xl" />
          )}
        </div>
        {showAccount ? (
          <div className=" mt-4 border-b pb-6 border-gray-400">
            <div className=" p-4 rounded border ">
              <p className=" text-sm lg:text-lg font-light">
                We're really sad to see you go, but we understand that
                situations change. Click to 'delete account' button below to
                confirm you wish to delete your account. Once processed, you
                must logout and remain logged out for 30 days.
              </p>
              {/* {showDeleteAccount && (
                <div>
                  <p className=" text-sm lg:text-lg font-light mt-4">
                    Please type 'DELETE' to confirm.
                  </p>
                  <input
                    type={"text"}
                    className=" p-2 px-4 text-sm lg:text-base bg-gray-50 rounded border focus:outline-none"
                    value={confirmDelete}
                    onChange={(e) => setConfirmDelete(e.target.value)}
                  />
                  <button
                    style={{ width: "fit-content" }}
                    className=" p-2 mt-2 ml-2 px-4 text-sm lg:text-base border rounded focus:outline-none hover:bg-red-500 border-red-500 text-red-500 hover:text-white duration-300 cursor-pointer"
                    onClick={() => onClickDeleteAccount()}
                  >
                    Confirm Delete
                  </button>
                </div>
              )} */}
              {!showDeleteAccount && (
                <div className="flex mt-8">
                  <div
                    className=" p-2 px-4 text-sm lg:text-base border rounded focus:outline-none hover:bg-red-500 border-red-500 text-red-500 hover:text-white duration-300 cursor-pointer"
                    onClick={() => alert("Coming soon")}
                  >
                    Delete Account
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className=" flex mt-8">
        <div
          className=" p-2 w-40 flex justify-center rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
          onClick={handleSaveChange}
        >
          {isLoadingSave ? <LoadingIndicator /> : "Save Changes"}
        </div>
      </div>

      {/* <div className=" mt-12 flex flex-row gap-4 items-center">
          <div className=" h-4 rounded-lg bg-gray-400 w-full">
            <div className={` h-4 rounded-lg bg-[#366C38]`} style={{width: `${progressBar}%`}} />
          </div>
          <div>{progressBar}%</div>
      </div> */}
    </div>
  );
};

export default SettingsContent;
