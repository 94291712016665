import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { 
  HiMenu,
 } from 'react-icons/hi'
import { 
  LiaTimesSolid,
 } from 'react-icons/lia'
 import { useDispatch, useSelector } from "react-redux"
 import toast, { Toaster } from 'react-hot-toast';
 import Swal from "sweetalert2";
 import axios from "axios"

import ModalLoginRegister from "./ModalLoginRegister"
import { clearFilter, setShowNav } from "../redux/filterSlice"
import { clearAuth, clearUser, setUser } from "../redux/authSlice";
import ModalBankAccount from "./ModalBankAccount"
import { onMessageListener } from "../utils/useFirebaseConfig"

const Header = () => {
  const menus = [
    {
      id: 1,
      name: 'Home',
      to: '/',
    },
    {
      id: 2,
      name: 'Market Place',
      to: '/market-place',
    },
    {
      id: 3,
      name: 'About Us',
      to: '/about-us',
    },
    {
      id: 4,
      name: 'How It Works',
      to: '/how-it-works',
    },
    {
      id: 5,
      name: 'Categories',
      to: '/categories',
    },
    {
      id: 6,
      name: 'Ad Pricings',
      to: '/ad-pricings',
    },
  ]

  const mobileMenus = [
    {
      id: 1,
      name: 'Home',
      to: '/',
    },
    {
      id: 2,
      name: 'Categories',
      to: '/categories',
    },
    {
      id: 3,
      name: 'Market Place',
      to: '/market-place',
    },
    {
      id: 4,
      name: 'Post Ad',
      to: '/add-listing',
    },
    {
      id: 5,
      name: 'Wishlist',
      to: '/my-account?tabs=wishlist',
    },
    {
      id: 6,
      name: 'Messages',
      to: '/my-account?tabs=messages',
    },
    {
      id: 7,
      name: 'Ad Pricings',
      to: '/ad-pricings',
    },
    {
      id: 8,
      name: 'FAQs',
      to: '/faq',
    },
    {
      id: 9,
      name: 'Blog',
      to: '/blog',
    },
    {
      id: 10,
      name: 'Contact Us',
      to: '/contact-us',
    },
    // {
    //   id: 11,
    //   name: 'About Us',
    //   to: '/about-us',
    // },
  ]

  // const [showNav, setShowNav] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalBank, setShowModalBank] = useState(false)
  const [isLogin, setIsLogin] = useState(1)
  const [isLoadingBank, setLoadingBank] = useState(false)
  const [listBank, setListBank] = useState([])
  const [stateBank, setStateBank] = useState({
    accountName: '',
    businessName: '',
    iban: '',
    swiftCode: '',
    country: 1,
    phoneNumber: ''
  }) 
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auth = useSelector(state => state.auth.data)
  const showNav = useSelector(state => state.filter.showNav)
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (user?.check_bank_information === 1) {
      getApi('BANK', '/api/master/banks')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (_type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
              setListBank(results)
              // setStateBank(prevState => {
              //   return {
              //     ...prevState,
              //     swiftCode: results[0].code
              //   }
              // })
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        // setLoading(false)
      });
  }

  const handleChangeBank = (e) => {
    const { name, value } = e.target
    setStateBank(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleSubmitBank = () => {
    setLoadingBank(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/bank-create';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      account_name: stateBank?.accountName,
      business_name: stateBank?.businessName,
      iban: stateBank?.iban,
      swift_code_bic: stateBank?.swiftCode,
      country: stateBank?.country,
      // phone_number: stateBank?.phoneNumber,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          }, 500);
          let userNew = {
            ...user,
            bank: results.bank
          }
          // dispatch(clearUser())
          dispatch(setUser(userNew))
          setLoadingBank(false)
          setShowModalBank(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingBank(false)
      });
  }  
  
  const [notification, setNotification] = useState({title: '', body: '', url: '/'});
  const notify = () => toast.custom((t) => (
    // <Link
    //   to={'/market-place'} 
    <div
      onClick={() => {
        toast.dismiss(t.id)
        navigate(notification?.url)
        // navigate('/market-place')
      }}
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 cursor-pointer`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5">
            <img
              className="h-10 w-auto "
              src={process.env.PUBLIC_URL+'/assets/Logo.png'}
              alt=""
            />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
            {notification?.title}
            </p>
            <p className="mt-1 text-sm text-gray-500 line-clamp-2">
              {notification?.body}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div> */}
    </div>
  ))

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.data?.title, 
        body: payload?.data?.body,
        url: payload?.data?.foreground_url
      });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      {/* <button onClick={notify}>Make me a toast</button> */}
      <Toaster 
        position="bottom-right"
        toastOptions={{
          duration: 10000,
        }}
      />
      {/* Nav Mobile */}
      <div className={` bg-transparent w-full h-full fixed flex justify-end transition-all duration-700 z-20 ${!showNav ? ' ml-[100%]' : '' } `}>
        {/* <div className=" bg-black h-full w-[40%] p-2 pt-16 relative flex flex-col items-end"> */}
        <div className=" bg-white opacity-95 h-full w-[40%] p-2 pt-8 relative flex flex-col items-end">
          <div className=" flex items-center justify-between">
            <LiaTimesSolid className=" text-2xl text-[#B38c32] mr-4" onClick={() => dispatch(setShowNav(false))} />
            <img 
              // src={'https://paseero.com/wp-content/uploads/2023/03/paseero-high-resolution-logo-color-on-transparent-background.png'} 
              src={process.env.PUBLIC_URL+'/assets/Logo.png'} 
              className=" w-[75px] md:max-w-full max-h-[150px]"
              alt="logo"
            />          
          </div>        
          <div className=" flex flex-col space-y-3 pt-12 pr-4">
            {mobileMenus.map(row => {
              if (['/add-listing', '/my-account?tabs=wishlist', '/my-account?tabs=messages'].includes(row?.to) && auth === null) {
                return (
                  <div 
                    key={row.id}
                    className=" text-sm lg:text-xl text-[#B38c32] text-right"
                    onClick={() => {
                      dispatch(setShowNav(false))
                      setShowModal(true)
                    }}
                  >
                    {row.name}
                  </div>
                )
              }else if(row?.to === '/add-listing' && user?.check_bank_information === 1) {
                return (
                  <div 
                  key={row.id}
                  className=" text-sm lg:text-xl text-[#B38c32] text-right"
                  onClick={() => {
                    setShowModalBank(true)
                  }}
                  >
                    {row.name}
                  </div>
                )              
              }else if(row?.to === '/add-listing' && !user?.membership?.length) {
                return (
                  <div 
                  key={row.id}
                  className=" text-sm lg:text-xl text-[#B38c32] text-right"
                  onClick={() => {
                    Swal.fire({
                      icon: 'warning',
                      title: 'You have no active ad plan(s)',
                      text: `Purchase an ad plan to start posting ads.`,
                      showCancelButton: true,
                      confirmButtonText: 'Get ad plan',
                      confirmButtonColor: '#B38C32',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // navigate('/ad-pricings')
                        // navigate('/my-account?tabs=membership')
                        dispatch(setShowNav(false))
                        window.location.replace(process.env.PUBLIC_URL+'/my-account?tabs=membership')
                      }
                    })
                  }}
                  >
                    {row.name}
                  </div>
                )  
              } else {
                return (
                  <a
                    key={row.id}
                    href={`${process.env.PUBLIC_URL}${row.to}`}
                    className=" text-sm lg:text-xl text-[#B38c32] text-right"
                    onClick={() => {
                      dispatch(setShowNav(false))
                      if (row?.name === '/add-listing') {
                        dispatch(setUser({...user, adTemp: null}))
                      }
                    }}
                  >
                    {row.name}
                  </a>
                )
              }
            })}
          </div>
          {auth !== null ? (
            <div className=" absolute bottom-8 right-5 text-right space-y-3">
              <Link 
                to={'/my-account'}
                className=" text-sm lg:text-xl text-[#B38C32]"
                onClick={() => dispatch(setShowNav(false))}
              >
                My Account
              </Link>
              <div
                className=" text-sm lg:text-xl text-[#B38C32]"
                onClick={() => {
                  dispatch(clearAuth())
                  dispatch(clearUser())
                  dispatch(clearFilter())
                  dispatch(setShowNav(false))
                  navigate('/')
                }}
              >
                Logout
              </div>
            </div>
          ): (
            <div className=" absolute bottom-8 flex flex-col space-y-3 right-5">
              <button 
                className=" p-1 w-28 text-xs lg:text-base bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
                onClick={() => {
                  setIsLogin(1)
                  setTimeout(() => {
                    setShowModal(true)
                  }, 100);
                }}
              >
                  Sign In
              </button>
              <button 
                className=" p-1 w-28 text-xs lg:text-base bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
                onClick={() => {
                  setIsLogin(2)
                  setTimeout(() => {
                    setShowModal(true)
                  }, 100);
                }}
              >
                Register
              </button>
            </div>
          )}        
        </div>
      </div>

      <nav className=" flex justify-center w-full bg-[#366C38]">
        <div className=" flex flex-col w-[1300px] ">    
          {/* <div className=" py-6 justify-between items-center px-4 lg:px-0 lg:justify-start flex">
            <Link to={'/'}>
              <img
                src={'https://paseero.com/wp-content/uploads/2023/03/paseero-high-resolution-logo-color-on-transparent-background.png'} 
                className=" w-[100px] lg:w-auto max-h-[150px]"
                alt="logo"
              />
            </Link>
            <HiMenu className="lg:hidden text-5xl text-white" onClick={() => setShowNav(true)} />
          </div> */}
          <div className=" flex justify-between items-center lg:items-end py-6 px-2 lg:px-0 ">
            <div className=" flex flex-row space-x-2 lg:space-x-8 ">
              <Link to={'/'}>
                <img
                  src={process.env.PUBLIC_URL+'/assets/Logo.png'} 
                  className=" w-[100px] lg:w-auto max-h-[150px]"
                  alt="logo"
              />
              </Link>
              <div className=" flex flex-col justify-between space-y-8">
                <div className=" space-y-1">
                  <h1 className=" text-sm lg:text-4xl text-white font-bold">Freedom - Sell or Auction</h1>
                  <h2 className=" text-sm lg:text-4xl text-white font-bold">Find Deals - Buy or Bid</h2>
                  <h3 className=" text-white text-[0.5rem] md:flex lg:text-lg">Nigeria-based item selling, auctioning, buying, and bidding platform</h3>
                </div>
                <div className=" hidden lg:flex flex-row space-x-12">
                  {menus.map(row => (
                    <Link
                      key={row.id}
                      to={row.to}
                      className=" hidden lg:flex text-lg font-medium text-white hover:underline underline-offset-8 hover:cursor-pointer"
                    >
                      {row.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {auth !== null ? (
              <Link 
                to={'/my-account?tabs=dashboard'}
                className=" hidden lg:flex text-lg font-medium text-white hover:underline underline-offset-8 hover:cursor-pointer"
              >
                My Account
              </Link>
            ): (            
              <div className=" flex flex-row space-x-2">
                <div 
                  className=" hidden lg:flex text-lg font-medium text-white hover:underline underline-offset-8 hover:cursor-pointer"
                  onClick={() => {
                    setIsLogin(2)
                    setTimeout(() => {
                      setShowModal(true)
                    }, 100);
                  }}
                >
                  Sign Up
                </div>
                <div className=" hidden lg:flex text-lg font-medium text-white">|</div>
                <div 
                  className=" hidden lg:flex text-lg font-medium text-white hover:underline underline-offset-8 hover:cursor-pointer"
                  onClick={() => {
                    setIsLogin(1)
                    setTimeout(() => {
                      setShowModal(true)
                    }, 100);
                  }}
                >
                  Sign In
                </div>
              </div>
            )}
            <HiMenu className="lg:hidden text-5xl text-white" onClick={() => dispatch(setShowNav(!showNav))} />
          </div>
        </div>
      </nav>

      {showModalBank ? (
        <ModalBankAccount
          {...stateBank}
          listBank={listBank}
          onChange={handleChangeBank}
          onShowModal={setShowModalBank}
          onSubmit={handleSubmitBank}
          isLoading={isLoadingBank}
          onHandleLink={() => {
            if (user?.membership?.length > 0) {
              dispatch(setUser({...user, adTemp: null}))
              setTimeout(() => {
                navigate('/add-listing')
              }, 100);
            }else{
              Swal.fire({
                icon: 'warning',
                title: 'You have no active ad plan(s)',
                text: `Purchase an ad plan to start posting ads.`,
                showCancelButton: true,
                confirmButtonText: 'Get ad plan',
                confirmButtonColor: '#B38C32',
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate('/ad-pricings')
                  navigate('/my-account?tabs=membership')
                }
              })
            }
          }}
        />
      ): null}
      
      {showModal ? (
        <ModalLoginRegister
          type={isLogin}
          onShowModal={() => setShowModal(false)}
        />
      ) : null}      
    </>
  )
}

export default Header