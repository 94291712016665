import React from "react";
import { TbMessage, TbHeart, TbHeartFilled } from "react-icons/tb";
import { FaRegClock, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { useCountdown } from "../utils/useCountdown";
import AverageRating from "./AverageRating";
import { useSelector } from "react-redux";

const ListAdItem = (props) => {
  // const navigate =  useNavigate()

  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.auth.user);

  const ExpiredCountdown = ({ date }) => {
    const NOW_IN_MS = new Date(date).getTime();
    const [days, hours, minutes, seconds] = useCountdown(NOW_IN_MS);

    return (
      <div className=" flex flex-row items-center space-x-2">
        <FaRegClock />
        <div className=" text-black font-medium">
          {days < 0 ? `0d 0:0:0` : `${days}d ${hours}:${minutes}:${seconds}`}
        </div>
      </div>
    );
  };

  return (
    <div
      className=" shadow-xl rounded-b hover:scale-105 duration-300  relative"
    >
      {props.is_featured === 1 ? (
        <div className=" absolute right-0 top-4 flex flex-col space-y-3 z-10">
          <div className=" text-white bg-red-600 p-1 px-3 font-medium rounded-l">
            Featured
          </div>
        </div>
      ) : null}
      {props.is_new === 1 ? (
        <div
          className={` ${
            props.is_featured === 1 ? "top-14" : "top-4"
          } absolute right-0 flex flex-col space-y-3 z-10`}
        >
          <div className=" text-white bg-[#F9C301] p-1 px-3 font-medium rounded-l">
            New
          </div>
        </div>
      ) : null}

      <Link to={`/ads/${props?.slug}`} className=" relative">
        <img
          alt={props?.title}
          // src={props?.photos[0]?.url}
          src={props?.thumbnail}
          className=" rounded-t h-[175px] w-full object-cover"
        />
        {props?.qty === 0 ? (
          <div className=" absolute left-0 bottom-0 flex flex-col space-y-3">
            <div className=" text-white bg-gray-500 text-sm p-1 px-3 font-medium ">
              Out Of Stock
            </div>
          </div>
        ) : null}
      </Link>
      <div className="   border-gray-300">
        <Link
          to={`/ads/${props?.slug}`}
        >
          <div
            className=" text-sm font-medium text-black pt-2 px-3 hover:underline underline-offset-1 line-clamp-2 cursor-pointer"
          >
            {props?.title}
          </div>
        </Link>
        <div className=" flex items-center space-x-1 px-3 pt-1">
          <FaMapMarkerAlt className=" text-sm" />
          <div className=" text-xs text-gray-500">
            {props?.state?.name}, {props?.local_government?.name}
          </div>
        </div>
        <div className=" flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 justify-between p-3">
          {props.ads_type === 1 ? (
            <ExpiredCountdown date={props?.expired_at} />
          ) : (
            <div />
          )}
          {/* <div className=" p-1 px-2 max-w-[100px] truncate rounded text-xs text-white font-medium tracking-wide bg-[#B38C32]">{props?.categories[0]?.subcategory_title}</div> */}
          <div className=" p-1 px-2 rounded text-xs text-white font-medium tracking-wide bg-[#B38C32]">
            {props?.categories[0]?.subcategory_title}
          </div>
        </div>
      </div>
      <div className=" flex flex-col-reverse sm:flex-row justify-between border-t border-gray-300 p-3">
        <div className=" flex items-center justify-between pt-1">
          <div className=" flex items-center space-x-2">
            {auth == null ? (
              <TbMessage
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Send Message"
                className=" text-2xl cursor-pointer hover:text-[#B38C32] focus:outline-none"
                onClick={() => props.onMessage(props.user)}
              />
            ) : null}
            {auth !== null && user?.id !== props?.user?.id ? (
              <TbMessage
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Send Message"
                className=" text-2xl cursor-pointer hover:text-[#B38C32] focus:outline-none"
                onClick={() => props.onMessage(props.user)}
              />
            ) : null}
            {props.is_favorite === 1 ? (
              <TbHeartFilled
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Remove Wishlist"
                className=" text-2xl cursor-pointer text-[#B38C32] focus:outline-none"
                onClick={() => props.onFavorite(props.index, props.id)}
              />
            ) : (
              <TbHeart
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Add Wishlist"
                className=" text-2xl cursor-pointer hover:text-[#B38C32] focus:outline-none"
                onClick={() => props.onFavorite(props.index, props.id)}
              />
            )}

            <ReactTooltip id="tooltip-icon" place="top" />
          </div>
          <div className="flex md:hidden items-center">
            <AverageRating rate={props?.average_rating} />
          </div>
        </div>
        <div className=" flex items-center md:space-x-2">
          <div className=" hidden md:flex items-center">
            <AverageRating rate={props?.average_rating} />
          </div>
          <div className=" text-black font-medium text-sm">
            {props?.ads_type === 0
              ? props?.buynow_price_text
              : props?.starting_price_text}
          </div>
        </div>
        {/* <div className=" md:hidden text-black font-medium text-xl">{props?.ads_type === 0 ? props?.buynow_price_text : props?.starting_price_text}</div> */}
      </div>
    </div>
  );
};

export default ListAdItem;
