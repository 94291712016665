import React, { useEffect, useState } from "react";
import {
  HiOutlineInformationCircle,
  HiChevronDown,
  HiXCircle,
} from "react-icons/hi";
import {
  // MdReadMore,
  // MdFavorite,
  MdOutlineFormatListBulleted,
  MdGridView,
  MdLocationPin,
} from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { VscSettings } from "react-icons/vsc";
// import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./Layout";
import {
  ListAdItem,
  ListSlider,
  LoadingThreeDots,
  ModalFilter,
  ModalLocation,
  ModalLoginRegister,
  ModalMessage,
  Pagination,
} from "../components";
import {
  setCategory,
  setCity,
  setColors,
  setFeatures,
  setSizes,
  setYears,
} from "../redux/filterSlice";
import { useDebounce } from "../utils/useDebounce";
import SEO from "../components/seo/SEO";

const MarketPlace = () => {
  // const { category } = useParams()

  const auth = useSelector((state) => state.auth.data);

  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(true);
  const [isLoadingBrand, setLoadingBrand] = useState(false);
  const [keywordBrand, setKeywordBrand] = useState("");
  // const [modalCategory, setModalCategory] = useState(false)
  const [modalLocation, setModalLocation] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [listAd, setListAd] = useState([]);
  const [pageAd, setPageAd] = useState(null);
  const [state, setState] = useState({
    listFilter: [
      {
        id: 1,
        name: "Category",
      },
      {
        id: 2,
        name: "Price",
      },
      {
        id: 3,
        name: "Location",
      },
      {
        id: 4,
        name: "Condition",
      },
      {
        id: 5,
        name: "Year",
      },
      {
        id: 6,
        name: "Features",
      },
      {
        id: 7,
        name: "Colors",
      },
      {
        id: 8,
        name: "Size",
      },
      {
        id: 9,
        name: "Brand",
      },
      {
        id: 10,
        name: "Date Added",
      },
      {
        id: 11,
        name: "Date Ends",
      },
      {
        id: 12,
        name: "Clear Filter",
      },
    ],
    listCategory: filter?.category,
    listSubCategory: [],
    listFeatures: filter?.features,
    listCity: filter?.city,
    listLocal: [],
    listBrand: filter?.brands,
    listColor: filter?.colors,
    listSize: filter?.sizes,
    listYear: filter?.years,
    category: "",
    categoryText: "",
    subCategory: "",
    subCategoryText: "",
    city: "",
    cityText: "",
    local: "",
    localText: "",
    condition: "",
    conditionText: "",
    year: "",
    yearText: "",
    features: "",
    featuresText: "",
    colors: "",
    colorsText: "",
    size: "",
    sizeText: "",
    brand: "",
    brandText: "",
    dateadded: "",
    dateaddedText: "",
    dateends: "",
    dateendsText: "",
    isClassified: false,
    isAuction: false,
    isFeatured: false,
    isHasBids: false,
    isWishlist: false,
    sort: 1,
    textSearch: "",
    range: [0, 0],
    isClear: false,
  });
  const [contentFilter, setContentFilter] = useState({
    title: "",
    list: [],
  });
  const [showModalMsg, setShowModalMsg] = useState(false);
  const [msgUser, setMsgUser] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 36;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchAll = () => {
      Promise.all([
        filter?.category?.length === 0 &&
          getApi("CATEGORY", "/api/category/index"),
        filter?.sizes?.length === 0 && getApi("SIZES", "/api/category/sizes"),
        filter?.years?.length === 0 && getApi("YEARS", "/api/category/years"),
        filter?.colors?.length === 0 &&
          getApi("COLORS", "/api/category/colors"),
        // filter?.brands?.length === 0 && getApi('BRANDS', '/api/category/brands'),
        getApi("BRANDS", "/api/category/brands"),
        filter?.features?.length === 0 &&
          getApi("FEATURES", "/api/category/features"),
        filter?.city?.length === 0 && getApi("CITY", "/api/category/states"),
        // getMarketPlace(),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            if (type === "CATEGORY") {
              let arrCategory = [];
              results.map((row) => {
                return arrCategory.push({
                  ...row,
                  isActive: false,
                });
              });
              dispatch(setCategory(arrCategory));
              setState((prevState) => {
                return {
                  ...prevState,
                  listCategory: arrCategory,
                };
              });
            } else if (type === "SUBCATEGORY") {
              setState((prevState) => {
                return {
                  ...prevState,
                  listSubCategory: results,
                  subCategory: "",
                  subCategoryText: "ALL",
                };
              });
            } else if (type === "SIZES") {
              dispatch(setSizes(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listSize: results,
                };
              });
            } else if (type === "YEARS") {
              dispatch(setYears(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listYear: results,
                };
              });
            } else if (type === "COLORS") {
              dispatch(setColors(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listColor: results,
                };
              });
            } else if (type === "BRANDS") {
              let arrBrands = [];
              results.map((row) => {
                return arrBrands.push({
                  ...row,
                  value: row.id,
                  label: row.title,
                });
              });
              // dispatch(setBrands(arrBrands))
              setState((prevState) => {
                return {
                  ...prevState,
                  listBrand: arrBrands,
                };
              });
              setTimeout(() => {
                setLoadingBrand(false);
              }, 500);
            } else if (type === "FEATURES") {
              let arrFeatures = [];
              results.map((row) => {
                return arrFeatures.push({
                  ...row,
                  isActive: false,
                });
              });
              dispatch(setFeatures(arrFeatures));
              setState((prevState) => {
                return {
                  ...prevState,
                  listFeatures: arrFeatures,
                };
              });
            } else if (type === "CITY") {
              dispatch(setCity(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listCity: results,
                };
              });
            } else {
              setState((prevState) => {
                return {
                  ...prevState,
                  listLocal: results,
                };
              });
            }
            // setLoading(false)
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
        setLoading(false);
      });
  };

  const getMarketPlace = (keyword, category, subcategory) => {
    if (page === 1) {
      setLoadingFilter(true);
    }

    const apiUrl = process.env.REACT_APP_API_URL + "/api/ad/marketplace";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth?.access_token}`,
    };
    const params = {
      sort_by: state?.sort,
      // category_id: state?.category,
      category_id:
        category !== undefined
          ? category !== null
            ? category
            : ""
          : state?.category,
      sub_category_id:
        subcategory !== undefined
          ? subcategory !== null
            ? subcategory
            : ""
          : state?.subCategory,
      min_price: state.range[0],
      max_price: state.range[1],
      state_id: state?.city,
      local_government_id: state?.local,
      condition: state?.condition,
      year_id: state?.year,
      features: state?.features,
      colors_id: state?.colors,
      size_id: state?.size,
      brand_id: state?.brand,
      date_added: state?.dateadded,
      date_ended: state?.dateends,
      ads_type: state?.isAuction ? 1 : 0,
      is_featured: state?.isFeatured ? 1 : 0,
      has_bid: state?.isHasBids ? 1 : 0,
      has_buy_now: state?.isClassified ? 1 : 0,
      is_favorite: state?.isWishlist ? 1 : 0,
      keyword:
        keyword !== undefined
          ? keyword !== null
            ? keyword
            : ""
          : state?.textSearch,
      per_page: limit,
      page: page,
      // created_at: '',
      // expired_at: '',
    };

    axios
      .get(apiUrl, { params: params }, { headers })
      .then((response) => {
        // console.log({response});
        const { results, metadata } = response.data.response;
        setListAd(results);
        setPageAd(metadata);
        setTimeout(() => {
          setLoadingFilter(false);
          setLoadingMore(false);
          setState((prevState) => {
            return {
              ...prevState,
              isClear: false,
            };
          });
        }, 500);
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
        setLoading(false);
      });
  };

  const listDateAdded = [
    {
      id: 1,
      title: "Today",
    },
    {
      id: 2,
      title: "Within 48 Hours",
    },
    {
      id: 3,
      title: "Within 7 Days",
    },
    {
      id: 4,
      title: "Within 2 Weeks",
    },
    {
      id: 5,
      title: "Within 1 Month",
    },
  ];

  const listDateEnd = [
    {
      id: 1,
      title: "Within 24 Hours",
    },
    {
      id: 2,
      title: "Within 48 Hours",
    },
    {
      id: 3,
      title: "This Week",
    },
    {
      id: 4,
      title: "This Month",
    },
  ];

  const listCondition = [
    {
      id: 0,
      title: "New",
    },
    {
      id: 1,
      title: "Used",
    },
  ];

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    let text = "";
    if (type === "select-one") {
      var index = e.nativeEvent.target.selectedIndex;
      text = e.nativeEvent.target[index].text;
    }
    setState((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
        cityText: name === "city" ? text : prevState.cityText,
        localText: name === "local" ? text : prevState.localText,
      };
    });

    if (name === "city") {
      getApi("LOCAL", `/api/category/localgovernments?state_id=${value}`);
    }
  };

  const handleChangePrice = (e) => {
    const { name, value } = e.target;
    let item =
      name === "minPrice" ? [value, state.range[1]] : [state.range[0], value];
    setState((prevState) => {
      return {
        ...prevState,
        range: item,
      };
    });
  };

  const handleChangeSelected = (e, text) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
        [name + "Text"]: text,
      };
    });
  };

  const handleChangeSearch = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        brand: e !== null ? e.value : "",
        brandText: e !== null ? e.title : "",
      };
    });
    if (!e) {
      setLoadingBrand(true);
      getApi("BRANDS", "/api/category/brands");
    }
  };

  const debouncedKeyword = useDebounce(keywordBrand, 500);

  useEffect(() => {
    if (debouncedKeyword !== "") {
      setLoadingBrand(true);
      getApi("BRANDS", "/api/category/brands?keyword=" + keywordBrand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword]);

  const handleCategorySelect = (id, title) => {
    setState((prevState) => {
      return {
        ...prevState,
        category: id,
        categoryText: title,
        listSubCategory: [],
        subCategory: "",
        subCategoryText: "",
      };
    });
    if (!!id) {
      getApi("SUBCATEGORY", `/api/category/subcategories?category_id=${id}`);
    }
  };

  const handleSubCategorySelect = (id, title) => {
    setState((prevState) => {
      return { ...prevState, subCategory: id, subCategoryText: title };
    });
  };

  // const handleSelect = (index, status, nameKey) => {
  //   let list = [...state[nameKey]]
  //   list[index] = {
  //     ...list[index] = {
  //       ...list[index],
  //       isActive: status,
  //     }
  //   }

  //   setState(prevState => {
  //     return{
  //       ...prevState,
  //       [nameKey]: list
  //     }
  //   })
  // }

  // const handleClearSelect = () => {
  //   setState(prevState => {
  //     return{
  //       ...prevState,
  //       listCategory:
  //         state.listCategory.map(r => ({
  //           ...r,
  //           isActive: false
  //         })),
  //       listSubCategory: []
  //     }
  //   })
  // }

  const handleSetContent = (id) => {
    let item = {};
    if (id === 1) {
      // Category
      item = {
        title: "category",
        list: state?.listCategory,
        isType: "category",
      };
    } else if (id === 2) {
      // Price
      item = {
        title: "Price",
        list: listCondition,
        isType: "price",
      };
    } else if (id === 4) {
      // Condition
      item = {
        title: "condition",
        list: listCondition,
        isType: "selected",
      };
    } else if (id === 5) {
      // Year
      item = {
        title: "year",
        list: state?.listYear,
        isType: "selected",
      };
    } else if (id === 6) {
      // Feature
      item = {
        title: "features",
        list: state?.listFeatures,
        isType: "selected",
      };
    } else if (id === 7) {
      // Color
      item = {
        title: "colors",
        list: state?.listColor,
        isType: "selected",
      };
    } else if (id === 8) {
      // Size
      item = {
        title: "size",
        list: state?.listSize,
        isType: "selected",
      };
    } else if (id === 9) {
      // Brand
      item = {
        title: "brand",
        list: state?.listBrand,
        isType: "search",
      };
    } else if (id === 10) {
      // Date Added
      item = {
        title: "date added",
        list: listDateAdded,
        isType: "selected",
      };
    } else if (id === 11) {
      // Date Ends
      item = {
        title: "date ends",
        list: listDateEnd,
        isType: "selected",
      };
    }

    setContentFilter(item);
    setTimeout(() => {
      setModalFilter(true);
    }, 100);
  };

  const handleClearFilter = () => {
    setState((prevState) => {
      return {
        ...prevState,
        category: "",
        categoryText: "",
        subCategory: "",
        subCategoryText: "",
        city: "",
        cityText: "",
        local: "",
        localText: "",
        condition: "",
        conditionText: "",
        year: "",
        yearText: "",
        features: "",
        featuresText: "",
        colors: "",
        colorsText: "",
        size: "",
        sizeText: "",
        brand: "",
        brandText: "",
        dateadded: "",
        dateaddedText: "",
        dateends: "",
        dateendsText: "",
        isClassified: false,
        isAuction: false,
        isFeatured: false,
        isHasBids: false,
        isWishlist: false,
        sort: 1,
        textSearch: "",
        range: [0, 0],
        isClear: true,
      };
    });
  };

  useEffect(() => {
    let urlSearchString = window.location.search;
    let params = new URLSearchParams(urlSearchString);
    setState((prevState) => {
      return {
        ...prevState,
        textSearch: params.get("keyword") !== null ? params.get("keyword") : "",
        category: params.get("category") !== null ? params.get("category") : "",
        categoryText: params.get("title") !== null ? params.get("title") : "",
      };
    });
    getMarketPlace(
      params.get("keyword"),
      params.get("category"),
      params.get("subcategory")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state?.isClassified,
    state?.isAuction,
    state?.isFeatured,
    state?.isHasBids,
    state?.isWishlist,
    state?.sort,
  ]);

  useEffect(() => {
    if (state?.isClear) {
      getMarketPlace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.isClear]);

  const handleFavorite = (index, id) => {
    if (auth === null) {
      setShowModal(true);
      return false;
    }

    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/wishlist/toogle";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          let list = [...listAd];
          list[index] = {
            ...(list[index] = {
              ...list[index],
              is_favorite: results.is_favorite,
            }),
          };
          setListAd(list);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleMessage = (item) => {
    if (auth === null) {
      setShowModal(true);
      return false;
    }

    setMsgUser({
      ...item,
      targeted_user_id: item?.id,
      targeted_user_photo: item?.photo,
      targeted_user_full_name: !!item?.business_name
        ? item?.business_name
        : item?.first_name + " " + item?.last_name,
    });

    setTimeout(() => {
      setShowModalMsg(true);
    }, 100);
  };

  const handlePagination = (type) => {
    setLoadingMore(true);
    let page = parseInt(pageAd?.selectedPage);
    if (type === "prev") {
      page = page - 1;
    } else if (type === "next") {
      page = page + 1;
    } else {
      page = type;
    }
    setPage(page);
  };

  useEffect(() => {
    if (!!pageAd) {
      getMarketPlace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const SwitchButton = ({ title, name, value, onChange }) => {
    return (
      <div className=" flex items-center space-x-2">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            id={"checkbox-" + name}
            name={name}
            checked={value}
            onChange={onChange}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B38C32]"></div>
          {/* <span className=" ml-2 text-lg">{title}</span> */}
        </label>
        <div htmlFor={"checkbox-" + name} className="  lg:text-lg">
          {title}
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <SEO
        title="Marketplace | Paseero - Explore Ads, Categories & Deals"
        description="Browse the Paseero marketplace to discover a wide range of ads, categories, and exclusive deals. Buy, sell, and connect with trusted sellers today!"
        name="Paseero.ng | Online Marketplace"
        type="website"
        robots="index, follow"
      />

      <ListSlider />
      <div className=" flex justify-center w-full ">
        <div className=" w-[1300px] px-2 lg:px-0">
          <div className=" mt-4 flex justify-between items-center">
            <div className=" flex items-center">
              <div className=" lg:text-lg font-semibold">Explore&nbsp;</div>
              <div className=" lg:text-lg italic">
                {" "}
                {!isLoadingFilter ? listAd.length : "..."} Results
              </div>
            </div>
            <div className=" flex items-center">
              <div className=" lg:text-lg font-semibold">
                {state?.categoryText} Sponsored Ads&nbsp;
              </div>
              <HiOutlineInformationCircle className=" text-xl" />
            </div>
          </div>

          <div
            className=" lg:hidden p-4 rounded border bg-white mt-4 flex items-center justify-between cursor-pointer"
            onClick={() => setShowFilter(!showFilter)}
          >
            <div className=" items-center flex">
              <VscSettings className=" text-xl" />
              <span className=" font-medium ml-2">Filters</span>
            </div>
            <FaChevronDown />
          </div>

          <div
            className={` mt-4 ${
              showFilter ? "grid" : " hidden lg:grid"
            } grid-cols-1 lg:grid-cols-4 gap-2 `}
          >
            {!isLoading &&
              state?.listFilter.map((row) => (
                <div
                  key={row.id}
                  className={` flex items-center border rounded-md p-4 justify-between cursor-pointer ${
                    row.id === 12 ? "bg-gray-200" : "bg-white"
                  }`}
                  onClick={() => {
                    if (row?.id === 3) {
                      setModalLocation(true);
                    } else if (row?.id === 12) {
                      handleClearFilter();
                    } else {
                      handleSetContent(row?.id);
                    }
                  }}
                >
                  <div className=" lg:text-lg font-medium">
                    {/* {row.name} */}
                    {row.id === 1 && state?.categoryText !== "" // Category
                      ? "Category: " + state?.categoryText
                      : row.id === 2 // Price
                      ? `Price${
                          parseInt(state?.range[0]) === 0 &&
                          parseInt(state?.range[1]) === 0
                            ? ""
                            : ": " + state?.range[0] + " - " + state?.range[1]
                        } `
                      : row.id === 3 &&
                        state?.cityText !== "" &&
                        state?.localText !== "" // Location
                      ? "Location: " + state?.cityText + ", " + state?.localText
                      : row.id === 4 && state?.conditionText !== "" // Condition
                      ? "Condition: " + state?.conditionText
                      : row.id === 5 && state?.yearText !== "" // Year
                      ? "Year: " + state?.yearText
                      : row.id === 6 && state?.featuresText !== "" // Features
                      ? "Features: " + state?.featuresText
                      : row.id === 7 && state?.colorsText !== "" // Colors
                      ? "Colors: " + state?.colorsText
                      : row.id === 8 && state?.sizeText !== "" // Size
                      ? "Size: " + state?.sizeText
                      : row.id === 9 && state?.brandText !== "" // Brand
                      ? "Brand: " + state?.brandText
                      : row.id === 10 && state?.dateaddedText !== "" // Date Added
                      ? "Date Added: " + state?.dateaddedText
                      : row.id === 11 && state?.dateendsText !== "" // Date Ends
                      ? "Date Ends: " + state?.dateendsText
                      : row.name}
                  </div>
                  {row.id === 12 ? (
                    <HiXCircle className=" text-2xl" />
                  ) : (
                    <HiChevronDown className=" text-2xl" />
                  )}
                </div>
              ))}
          </div>

          <div className=" mt-4 flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between ">
            <div className=" grid grid-cols-2 lg:grid-cols-4 gap-2 items-center">
              <SwitchButton
                title={"Classified"}
                name={"isClassified"}
                value={state.isClassified}
                onChange={handleChange}
              />
              <SwitchButton
                title={"Auction"}
                name={"isAuction"}
                value={state.isAuction}
                onChange={handleChange}
              />
              <SwitchButton
                title={"Featured"}
                name={"isFeatured"}
                value={state.isFeatured}
                onChange={handleChange}
              />
              <SwitchButton
                title={"Has Bids"}
                name={"isHasBids"}
                value={state.isHasBids}
                onChange={handleChange}
              />
              {/* {auth !== null ? (
                <div 
                  className=" flex items-center space-x-2 cursor-pointer"
                  onClick={() => setState(prevState => { return {...prevState, isWishlist:!state.isWishlist}}) }                
                >
                  <MdFavorite className={`text-2xl ${state.isWishlist ? 'text-[#B38C32]' : 'text-black'}`} />
                  <div className=" text-lg hover:underline">Wishlist</div>
                </div>
              ): null} */}
              {/* <div className=" flex items-center space-x-2">
                <MdReadMore className=" text-3xl" />
                <div className=" text-lg">More Filters</div>
              </div> */}
            </div>
            <div className=" flex items-center justify-between lg:space-x-2">
              {/* <div>Most Relevant</div> */}
              <select
                className=" p-2 lg:text-lg bg-[#FAFAFB] w-52 focus:outline-none cursor-pointer"
                name="sort"
                value={state?.sort}
                onChange={handleChange}
              >
                <option value={1}>Default</option>
                {/* <option value={2}>Title: (A-z)</option>
                <option value={3}>Title: (Z-a)</option> */}
                <option value={2}>Date: (New)</option>
                <option value={3}>Date: (Old)</option>
              </select>
              <div className=" flex items-center flex-row space-x-2">
                <MdGridView className=" text-2xl cursor-pointer" />
                <MdOutlineFormatListBulleted className=" text-2xl cursor-pointer" />
                <MdLocationPin className=" text-2xl cursor-pointer" />
              </div>
            </div>
          </div>

          <div className=" flex lg:w-1/2 mt-4">
            <input
              className=" w-full p-3 lg:p-4 rounded-md border rounded-r-none border-r-0 focus:outline-none"
              placeholder="Enter text to search.."
              name="textSearch"
              value={state?.textSearch}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (page === 1) {
                    getMarketPlace();
                  } else {
                    setPage(1);
                  }
                }
              }}
            />
            <button
              className=" px-4 lg:px-8 lg:text-lg font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-[#B38C32] duration-300"
              onClick={() => {
                if (page === 1) {
                  getMarketPlace();
                } else {
                  setPage(1);
                }
              }}
            >
              Search
            </button>
          </div>

          {isLoadingFilter ? (
            <div className=" flex justify-center py-40">
              <LoadingThreeDots size={"50"} color={"#B38C32"} />
            </div>
          ) : null}

          {!isLoadingFilter && listAd.length === 0 ? (
            <div className=" py-40 justify-center text-center flex">
              <div className=" text-xl font-medium text-[#B38C32]">
                No Results Found
              </div>
            </div>
          ) : null}

          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-8 mt-8 pb-12">
            {!isLoadingFilter &&
              listAd.map((row, index) => (
                <ListAdItem
                  {...row}
                  key={row.id}
                  index={index}
                  onFavorite={handleFavorite}
                  onMessage={handleMessage}
                />
              ))}
          </div>

          <Pagination
            isLoading={isLoadingMore}
            listCount={listAd?.length}
            page={pageAd}
            onClick={handlePagination}
          />
        </div>
      </div>
      <hr className=" my-12" />

      {/* {modalCategory ? (
        <ModalCategory
          listCategory={state?.listCategory}
          listSubCategory={state?.listSubCategory}
          onCategorySelect={handleCategorySelect}
          onSubCategorySelect={handleSelect}
          onClearSelect={handleClearSelect}
          onShowModal={() => setModalCategory(false)}
        />
      ): null} */}

      {modalLocation ? (
        <ModalLocation
          {...state}
          isType={"filter"}
          listCity={state?.listCity}
          listLocal={state?.listLocal}
          onChange={handleChange}
          onShowModal={() => {
            setModalLocation(false);
            getMarketPlace();
          }}
        />
      ) : null}

      {/* {modalFilter ? ( */}
      <ModalFilter
        state={state}
        content={contentFilter}
        onChange={handleChangeSelected}
        onChangePrice={handleChangePrice}
        onCategorySelect={handleCategorySelect}
        onSubCategorySelect={handleSubCategorySelect}
        onSetRange={(val) =>
          setState((prevState) => {
            return { ...prevState, range: val };
          })
        }
        onChangeSearch={handleChangeSearch}
        onInputChange={(e) => setKeywordBrand(e)}
        isLoading={isLoadingBrand}
        onCancel={() => setModalFilter(false)}
        onContinue={() => {
          getMarketPlace();
          setTimeout(() => {
            setModalFilter(false);
          }, 100);
        }}
        isActive={modalFilter}
      />
      {/* ): null} */}

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null}

      {showModal ? (
        <ModalLoginRegister type={1} onShowModal={() => setShowModal(false)} />
      ) : null}
    </Layout>
  );
};

export default MarketPlace;
