import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function SEO({ title, description, name, type, image, robots }) {
  const location = useLocation();
  const canonicalUrl = `${process.env.REACT_APP_FRONTEND_URL}${location.pathname}`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="robots" content={robots || "index, follow"} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonicalUrl} />

      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
}
