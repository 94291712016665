import axios from "axios";
import React, { useEffect, useRef, useState } from 'react'
import { 
  PiUser,
  PiSmiley,
  PiPaperclip,
  PiUserPlus,
  PiUserMinus,
  PiXBold,
  PiPaperPlaneRightLight,
  PiDownloadSimpleBold,
  PiFiles,
} from "react-icons/pi";
import EmojiPicker from "emoji-picker-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingThreeDots from "./LoadingThreeDots";
 
const ModalMessage = (props) => {
  const {
    msgUser,
    setMsgUser,
    onShowModal
  } = props

  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const refFiles = useRef()

  const [isLoading, setLoading] = useState(true)
  const [isLoadingSend, setLoadingSend] = useState(false)
  const [showBrowse, setShowBrowse] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [messageList, setMessageList] = useState([])
  const [textMsg, setTextMsg] = useState('')
  const [attachment, setAttachment] = useState(null)

  const refBottom = useRef(null);
  useEffect(() => {
    getApi('MSGLIST', '/api/chat/message_list?targeted_user_id='+msgUser?.targeted_user_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            if(type === 'MSGLIST'){
              if (results.length > 0) {
                setMessageList(results[0].messages.reverse()) 
              }
              setLoading(false)
              setTimeout(() => {
                scrollToBottom()
              }, 500);
              // setShowRoom(true)
            }else{
              setMsgUser(prevMsgUser => { return { ...prevMsgUser, is_following: results.is_following ? 1 : 0}})
            }
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        setLoading(false)
      });
  }

  const handleEmojiClick = (emojiData) => {
    setTextMsg(textMsg.concat(emojiData.emoji))
  }

  const handleSend = () => {
    if (!textMsg) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please type a message!',
        confirmButtonColor: '#B38C32'
      })
    }

    const apiUrl = process.env.REACT_APP_API_URL+'/api/chat/send_message';    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Type': 'multipart/form-data'
    };
    const bodyForm = new FormData()
    bodyForm.append('targeted_user_id', msgUser?.targeted_user_id);
    bodyForm.append('message', textMsg);
    if (attachment !== null) {
      bodyForm.append('attachment', attachment.file);
    }
    // const bodyParams = {
    //   targeted_user_id: msgUser?.targeted_user_id,
    //   message: textMsg,
    // };
    axios
      .post(apiUrl, bodyForm, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          setMessageList(messageList.concat(results.message[0]))
          setTextMsg('')
          setAttachment(null)
          setShowBrowse(false)
          setLoadingSend(false)
          setTimeout(()=>{
            scrollToBottom()
          },500)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { results } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: results.error,
          confirmButtonColor: '#B38C32'
        })
      });
  }
  
  const handleFollow = (id) => {
    getApi('FOLLOW', '/api/auth/user/following?user_id='+id)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (messageList.length > 0) {
        getApi('MSGLIST', '/api/chat/message_list?targeted_user_id='+msgUser?.targeted_user_id)
      }
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  const handleChangeFiles = (e) => {
    const { files } = e.target;
    // console.log('files',files);
    if (files && files.length) {
      // let val = []
      if (files[0].size > 5000000)
        return Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'File size maximal 5mb!',
          confirmButtonColor: '#B38C32'
        })
      // val.push()
      setAttachment({
        name: files[0].name,
        file: files[0]
      })
    }    
  }

  const scrollToBottom = () => {
    refBottom?.current?.scrollIntoView({ bottom: 100, behavior: 'smooth' });
  };
  return (
    <div
      onClick={onShowModal}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <div className=" p-4 lg:p-6 bg-gray-100 rounded-t text-xl font-semibold border-b flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-between items-center">
              <div className=" flex flex-row items-center space-x-2 hover:bg-gray-100 duration-100" >
                <img 
                  alt="avatar-profile" 
                  src={msgUser?.targeted_user_photo !== '' ? msgUser?.targeted_user_photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                  className="w-8 h-8 lg:w-12 lg:h-12 rounded-full"
                />
                <div>
                  <div className=" font-semibold">{msgUser?.targeted_user_full_name}</div>
                  <div className=" text-xs text-gray-500 font-light">{msgUser?.is_online === 1 ? 'Online' : msgUser?.last_online}</div>
                </div>
              </div>
              <div className=" flex items-center space-x-2">
                <div
                  className=" border bg-white text-sm space-x-1 flex justify-center items-center p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300"
                  onClick={() => handleFollow(msgUser?.targeted_user_id)}
                >
                  {msgUser?.is_following === 1 ? <PiUserMinus className=" text-lg" /> : <PiUserPlus className=" text-lg" />}
                  <span className=" hidden lg:block">{msgUser?.is_following === 1 ? 'Unfollow' : 'Follow'}</span>
                </div>
                <Link 
                  to={`/author/${msgUser?.targeted_user_id}`}
                  className=" border bg-white text-sm space-x-1 flex justify-center items-center p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300"
                >
                  <PiUser className=" text-lg" />
                  <span className=" hidden lg:block">View Profile</span>
                </Link>
                <div 
                  className=" border bg-white p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300"
                  onClick={onShowModal}
                >
                  <PiXBold className=" text-lg" />
                </div>
              </div>
            </div>            
            <div className=" p-6 flex flex-col h-[30rem] overflow-y-auto">
              {isLoading? (
                <div className=" flex justify-center">
                  <LoadingThreeDots color={"#B38C32"} />
                </div>
              ): null }
              {!isLoading && messageList?.map(row => (
                row?.sender_id === user?.id ? (
                  <div
                    key={row?.id}
                    className=" relative flex py-2 self-end"
                  >
                    <div className=" text-xs mr-2">{row?.created_at_text}</div>
                    <div className=" flex flex-col space-y-1">
                      {row?.attachment_type === 'image' ? (
                        <div className=" relative">
                          <img
                            alt={row?.attachment_type+'-image'}
                            src={row?.attachment_path}
                            className="w-40 h-24 object-cover rounded"
                          />
                          <a 
                            href={row?.attachment_path}
                            target="_blank"
                            rel="noreferrer"
                            className=" absolute bottom-2 right-2"
                          >
                            <PiDownloadSimpleBold className=" text-white text-xl hover:opacity-75"/>
                          </a>
                        </div>
                      ): null}
                      {row?.attachment_type === 'video' ? (
                        <video controls className=" w-80 rounded">
                          <source src={row?.attachment_path} type="video/mp4" />
                          <source ssrc={row?.attachment_path} type="video/ogg"></source>
                        </video>
                      ): null}
                      {row?.attachment_type === 'application' ? (
                        <div className=" flex flex-row space-x-3 p-2 border rounded bg-gray-50">
                          <PiFiles className=" text-4xl"/>
                          <div className=" flex flex-col space-y-2">
                            <div className=" text-sm">File</div>
                            <a 
                              href={row?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                              className=" flex flex-row space-x-2 items-start text-xs bottom-2 left-2 p-1 border hover:bg-gray-200 duration-300 rounded"
                            >
                              <span>Download</span>
                              <PiDownloadSimpleBold className=" hover:opacity-75"/>
                            </a>
                          </div>
                        </div>
                      ): null}
                      {!!row?.message ? (
                        <div className=" flex justify-end">
                          <div className=" p-2 px-5 rounded-full text-xs lg:text-sm rounded-br-none bg-blue-600 text-white">
                            {row?.message}
                          </div>
                        </div>
                      ): null}                        
                    </div>
                  </div>
                ): (
                  <div
                    key={row?.id} 
                    className=" relative flex py-2 self-start"
                  >
                    <div className=" flex flex-col space-y-1">
                      {row?.attachment_type === 'image' ? (
                        <div className=" relative">
                          <img
                            alt={row?.attachment_type+'-image'}
                            src={row?.attachment_path}
                            className="w-40 h-24 object-cover rounded"
                          />
                          <a 
                            href={row?.attachment_path}
                            target="_blank"
                            rel="noreferrer"
                            className=" absolute bottom-2 right-2"
                          >
                            <PiDownloadSimpleBold className=" text-white text-xl hover:opacity-75"/>
                          </a>
                        </div>
                      ): null}
                      {row?.attachment_type === 'video' ? (
                        <video controls className=" w-80 rounded">
                          <source src={row?.attachment_path} type="video/mp4" />
                          <source ssrc={row?.attachment_path} type="video/ogg"></source>
                        </video>
                      ): null}
                      {row?.attachment_type === 'application' ? (
                        <div className=" flex flex-row space-x-3 p-2 border rounded bg-gray-50">
                          <PiFiles className=" text-4xl"/>
                          <div className=" flex flex-col space-y-2">
                            <div className=" text-sm">File</div>
                            <a 
                              href={row?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                              className=" flex flex-row space-x-2 items-start text-xs bottom-2 left-2 p-1 border hover:bg-gray-200 duration-300 rounded"
                            >
                              <span>Download</span>
                              <PiDownloadSimpleBold className=" hover:opacity-75"/>
                            </a>
                          </div>
                        </div>
                      ): null}
                      {!!row?.message ? (
                        <div className=" flex justify-start">
                          <div className=" p-2 px-5 rounded-full text-xs lg:text-sm rounded-tl-none bg-gray-600 text-white">
                            {row?.message}
                          </div>
                        </div>
                      ): null}                        
                    </div>
                    <div className=" text-xs ml-2">{row?.created_at_text}</div>
                  </div>
                )
              ))}
               <div ref={refBottom}/>
            </div>  
            <div className=" p-4 bg-gray-100 space-y-4 relative rounded-b">
              {showBrowse ? (          
                <div className=" flex items-center flex-row cursor-pointer w-full ">
                  <div 
                    className=" rounded-l border w-full text-sm bg-white p-2 focus:outline-none"
                    onClick={() => refFiles.current.click()}
                  >
                    <label className=" text-xs">{`${attachment !== null ? attachment.name : '.zip, .pdf, .doc, .txt, .jpg or .png files only.'}`}</label>                    
                  </div>
                  <input 
                    className=" hidden rounded-l border bg-white p-2 focus:outline-none"
                    accept=".jpg,.jpeg,.png,.mp4,.pdf,.docx,.doc,.txt,.zip"
                    name="files"
                    ref={refFiles}
                    onChange={handleChangeFiles}
                    type="file"
                  />
                  <button 
                    className=" bg-[#343A3F] p-2 px-4 text-sm text-white border rounded-r border-[#343A3F]"
                    onClick={() => refFiles.current.click()}
                  >
                    Browse
                  </button>
                </div>
              ): null}
              <div className=" relative flex">              
                {/* <input 
                  className=" h-12 px-12 text-sm rounded-full border w-full focus:outline-none" 
                  placeholder="Type a message.."  
                  value={textMsg}
                  onFocus={() => {
                    setShowBrowse(false)
                    setShowEmoji(false)
                  }}
                  onChange={(e) => setTextMsg(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                /> */}
                <textarea
                  row={1}
                  className=" h-12 pt-3 pl-12 pr-24 text-sm rounded-full border w-full focus:outline-none" 
                  placeholder="Type a message.."  
                  value={textMsg}
                  onFocus={() => {
                    setShowBrowse(false)
                    setShowEmoji(false)
                  }}
                  onChange={(e) => setTextMsg(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <PiSmiley 
                  className=" absolute top-3 left-4 text-2xl cursor-pointer" 
                  onClick={() => setShowEmoji(!showEmoji)}
                /> 

                {isLoadingSend ? (
                  <div className=" absolute top-3 right-6 text-2xl cursor-pointer" >
                    <LoadingThreeDots
                      size={'20'}
                      color={"#B38C32"}
                    />
                  </div>
                ):(
                  <>
                    <PiPaperclip 
                      className=" absolute top-3 right-6 text-2xl cursor-pointer" 
                      onClick={() => setShowBrowse(!showBrowse)}
                    />   
                    <PiPaperPlaneRightLight 
                      className=" absolute top-3 right-14 text-2xl cursor-pointer" 
                      onClick={handleSend}
                    />
                  </>
                )}            
              </div>
              {showEmoji ? (
                <div className=" absolute bottom-20">
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    autoFocusSearch={false}
                  />
                </div>
              ): null}
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalMessage