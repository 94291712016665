import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";

import Layout from "./Layout";
import { Dangerously } from "../components";
import SEO from "../components/seo/SEO";

const AboutUs = () => {
  const [isLoading, setLoading] = useState(true);
  const [about, setAbout] = useState(null);
  const [quickStart, setQuickStart] = useState(null);
  useEffect(() => {
    // first

    window.scrollTo(0, 0);
    const fetchAll = () => {
      Promise.all([
        getApi("ABOUT", "/api/master/about/index"),
        getApi("QUICKSTART", "/api/master/quickstart/index"),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          if (type === "ABOUT") {
            setAbout(results);
          } else {
            setQuickStart(results);
          }
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <Layout>
      <SEO
        title="About Us - Paseero | Nigeria's Leading Online Marketplace"
        description="Learn more about Paseero.ng, Nigeria's trusted online marketplace. Discover our mission, values, and how we connect buyers and sellers nationwide."
        name="Paseero.ng | About Us"
        type="website"
        robots="index, follow"
      />
      <div className=" mt-4 lg:mt-8" />
      <div className=" flex justify-center min-h-screen w-full">
        <div className=" w-[1300px]">
          {!isLoading ? (
            <div className=" flex flex-col lg:flex-row lg:justify-between items-start space-y-8 lg:space-y-0 lg:space-x-12 px-4 lg:px-0">
              <img
                alt="about-img"
                src={about?.image}
                className=" lg:w-1/2 rounded"
              />
              <div className=" lg:w-1/2 flex flex-col space-y-4">
                <Dangerously content={about?.title} />
                <Dangerously content={about?.description} />
                {/* <div className=" text-black text-3xl font-bold">About Us</div>
                <div className=" text-black text-xl font-medium">Our mission is to build the largest online auction and bidding community in Nigeria</div>
                <p className=" text-black leading-7 tracking-wide text-justify">
                  Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                </p> */}
                <div>
                  <Link
                    to={"/ad-pricings"}
                    className=" p-3 px-8 text-sm rounded text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer"
                  >
                    Ad Pricings
                  </Link>
                </div>
              </div>
            </div>
          ) : null}

          <div className=" flex justify-between mt-12 lg:mt-20 px-4 lg:px-0">
            <div className=" flex flex-col space-y-8 lg:w-1/2">
              <div className=" text-xl lg:text-3xl font-bold text-black">
                {quickStart?.title}
              </div>
              <div className=" lg:text-lg font-light tracking-wide">
                {quickStart?.description}
              </div>
              <div className=" space-y-8">
                {quickStart?.detail.map((row) => (
                  <div
                    key={row.id}
                    className=" flex items-start space-x-2 lg:space-x-8"
                  >
                    <FaCheckCircle className=" text-2xl lg:text-6xl text-[#B38C32]" />
                    <div className=" w-11/12 lg:w-4/5">
                      <div className=" text-sm lg:text-xl font-bold">
                        {row.title}
                      </div>
                      <div className=" text-xs lg:text-lg font-light tracking-wide mt-2">
                        {row.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className=" pt-16">
                <div className=" text-black tracking-wide font-bold">Follow our blog for news in business, economics, politics, entertainment, etc. </div>
                <button className="mt-4 p-3 px-6 rounded text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer">Blog</button>
              </div> */}
            </div>
            <div className=" w-1/2 hidden lg:block">
              <img alt="quickstart-img" src={quickStart?.image} />
            </div>
          </div>
          <div className=" lg:text-xl pt-16 px-4 lg:px-0">
            Follow our&nbsp;
            <Link
              to={"/blog"}
              className=" text-[#B38C32] hover:underline font-medium"
            >
              blog&nbsp;
            </Link>
            for news in business, economics, politics, entertainment, etc.
          </div>
        </div>
      </div>
      <hr className=" my-8 lg:my-12" />
    </Layout>
  );
};

export default AboutUs;
