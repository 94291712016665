import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiPlusCircle,
  HiOutlineMapPin,
  HiMiniArrowSmallRight,
  HiMiniArrowSmallLeft,
} from "react-icons/hi2";
import {
  FaRegFileImage,
  FaRegFileAudio,
  FaRegFileVideo,
  FaTimes,
  // FaVimeo,
  FaYoutube,
  FaTimesCircle,
  FaLink,
} from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import {
  LoadingThreeDots,
  ModalAdTermsBeforeAdPost,
  ModalBuyCredit,
  ModalCategory,
  ModalCreditPurchase,
  ModalLocation,
  ModalPayUpgrade,
} from "../components";
import axios from "axios";
import {
  setCategory,
  setCity,
  setColors,
  setFeatures,
  setSizes,
  setYears,
} from "../redux/filterSlice";
import { TagsInput } from "react-tag-input-component";
import Swal from "sweetalert2";
import Select from "react-select";
import { setUser } from "../redux/authSlice";
import { useDebounce } from "../utils/useDebounce";
import SEO from "../components/seo/SEO";

const AddListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const filter = useSelector((state) => state.filter);
  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.auth.user);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingSave, setLoadingSave] = useState(false);
  const [isLoadingPay, setLoadingPay] = useState(false);
  // const [isLoadingPayUpgrade, setLoadingPayUpgrade] = useState(false)
  const [tabActive, setTabActive] = useState(1);
  const [showModalBC, setShowModalBC] = useState(false);
  const [showModalCP, setShowModalCP] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [showModalPayUpgrade, setShowModalPayUpgrade] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [payPrice, setPayPrice] = useState("0");
  const [isEdit, setEdit] = useState(false);
  const [isSave, setSave] = useState(false);
  const [isBuynow, setBuynow] = useState(false);
  const [isLoadingBrand, setLoadingBrand] = useState(false);
  const [keywordBrand, setKeywordBrand] = useState("");
  const [showModalAdTerms, setShowModalAdTerms] = useState(
    user.total_ads == 0 ? true : false
  );
  const [state, setState] = useState({
    listCategory: filter?.category,
    listSubCategory: [],
    listFeatures: filter?.features,
    listCity: filter?.city,
    listLocal: [],
    listBrand: filter?.brands,
    listColor: filter?.colors,
    listSize: filter?.sizes,
    listYear: filter?.years,
    listMembership: user?.membership,
    listUpgrade: [],
    title: "",
    desc: "",
    // keyword: '',
    fulladdress: "",
    country: 1, // Defualt for Nigeria
    city: "",
    local: "",
    // longitude: '',
    // latitude: '',
    adsType: "0",
    startingPrice: "",
    buyNowPrice: "",
    // reservePrice: null,
    shippingPrice: 0,
    delivery: "0",
    stock: 0,
    isUsed: 0,
    isRefund: 0,
    modelNumber: "",
    brand: "",
    color: "",
    size: "",
    year: "",
    photos: [],
    video: [],
    videoLinkYt: "",
    videoLinkVimeo: "",
    audio: [],
    upgrade: [],
    membershipPlanId:
      user?.membership?.length > 0 ? user?.membership[0].membership_plan : "",
    membershipPlantext:
      user?.membership?.length > 0 ? user?.membership[0].lifetime_text : "",
    sumTotal: 0,
  });

  const tabs = [
    {
      id: 1,
      title: "Basics",
    },
    {
      id: 2,
      title: "Description",
    },
    {
      id: 3,
      title: "Details",
    },
    {
      id: 4,
      title: "Media",
    },
    {
      id: 5,
      title: "Finish",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAll = () => {
      Promise.all([
        filter?.category?.length === 0 &&
          getApi("CATEGORY", "/api/category/index"),
        filter?.sizes?.length === 0 && getApi("SIZES", "/api/category/sizes"),
        filter?.years?.length === 0 && getApi("YEARS", "/api/category/years"),
        filter?.colors?.length === 0 &&
          getApi("COLORS", "/api/category/colors"),
        // filter?.brands?.length === 0 && getApi('BRANDS', '/api/category/brands'),
        getApi("BRANDS", "/api/category/brands"),
        filter?.features?.length === 0 &&
          getApi("FEATURES", "/api/category/features"),
        filter?.city?.length === 0 && getApi("CITY", "/api/category/states"),
        getApi("MEMBERSHIP", "/api/auth/membership"),
        getApi("UPGRADE", "/api/master/upgrade"),
        params.id !== undefined &&
          getApi("DETAIL", "/api/ad/list/" + params.id),
      ])
        .then(() => {
          if (params.id !== undefined) {
            setTimeout(() => {
              setLoading(false);
            }, 2500);
          } else {
            setState((prevState) => {
              return {
                ...prevState,
                city: user?.address?.state_id,
                fulladdress: user?.address?.address,
              };
            });
            getApi(
              "LOCALFIRST",
              `/api/category/localgovernments?state_id=${user?.address?.state_id}`
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };

    if (user.adTemp && params.id === undefined) {
      // if (user.adTemp) {
      setState(user.adTemp);
      setKeywords(user.adTemp.keywords);
      // if (params.id !== undefined ) {
      //   // setEdit(true)
      //   getApi('DETAIL', '/api/ad/list/'+params.id)
      // }
      setLoading(false);
    } else {
      fetchAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let total = state?.listUpgrade
      .filter((f) => f.isActive === true)
      .reduce((sum, { price }) => parseInt(sum) + parseInt(price), 0);
    setState((prevState) => {
      return {
        ...prevState,
        sumTotal: total,
      };
    });
  }, [state.listUpgrade]);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            if (type === "CATEGORY") {
              let arrCategory = [];
              results.map((row) => {
                return arrCategory.push({
                  ...row,
                  isActive: false,
                });
              });
              dispatch(setCategory(arrCategory));
              setState((prevState) => {
                return {
                  ...prevState,
                  listCategory: arrCategory,
                };
              });
            } else if (type === "SUBCATEGORY") {
              let arrSubCategory = [];
              results.map((row) => {
                return arrSubCategory.push({
                  ...row,
                  isActive: false,
                });
              });
              setState((prevState) => {
                return {
                  ...prevState,
                  // listSubCategory: [...state.listSubCategory, ...arrSubCategory]
                  // listSubCategory: state.listSubCategory.concat(arrSubCategory)
                  listSubCategory: arrSubCategory,
                };
              });
            } else if (type === "SIZES") {
              dispatch(setSizes(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listSize: results,
                };
              });
            } else if (type === "YEARS") {
              dispatch(setYears(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listYear: results,
                };
              });
            } else if (type === "COLORS") {
              dispatch(setColors(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listColor: results,
                };
              });
            } else if (type === "BRANDS") {
              let arrBrands = [];
              results.map((row) => {
                return arrBrands.push({
                  ...row,
                  value: row.id,
                  label: row.title,
                });
              });
              // dispatch(setBrands(arrBrands))
              setState((prevState) => {
                return {
                  ...prevState,
                  listBrand: arrBrands,
                };
              });
              setTimeout(() => {
                setLoadingBrand(false);
              }, 500);
            } else if (type === "FEATURES") {
              let arrFeatures = [];
              results.map((row) => {
                return arrFeatures.push({
                  ...row,
                  isActive: false,
                });
              });
              dispatch(setFeatures(arrFeatures));
              setState((prevState) => {
                return {
                  ...prevState,
                  listFeatures: arrFeatures,
                };
              });
            } else if (type === "CITY") {
              dispatch(setCity(results));
              setState((prevState) => {
                return {
                  ...prevState,
                  listCity: results,
                };
              });
            } else if (type === "LOCALFIRST") {
              setState((prevState) => {
                return {
                  ...prevState,
                  listLocal: results,
                  local: user?.address?.local_government_id,
                };
              });
            } else if (type === "LOCAL") {
              setState((prevState) => {
                return {
                  ...prevState,
                  listLocal: results,
                };
              });
            } else if (type === "MEMBERSHIP") {
              let newUser = {
                ...user,
                membership: results.membership,
              };
              dispatch(setUser(newUser));
              setState((prevState) => {
                return {
                  ...prevState,
                  listMembership: results.membership,
                };
              });
            } else if (type === "UPGRADE") {
              let arrUpgrade = [];
              results.map((row) => {
                return arrUpgrade.push({
                  ...row,
                  isActive: false,
                });
              });
              setState((prevState) => {
                return {
                  ...prevState,
                  listUpgrade: arrUpgrade,
                };
              });
            } else {
              setEdit(true);
              let listCat = [];
              let listSubCat = [];
              // eslint-disable-next-line
              results[0]?.category_helper.map((c) => {
                if (c.selected) {
                  c.subcategories.map((s) =>
                    listSubCat.push({
                      ...s,
                      isActive: s.selected,
                    })
                  );
                }
                listCat.push({
                  ...c,
                  isActive: c.selected,
                });
              });
              let keywords = results[0]?.keywords;
              if (!!keywords) {
                setKeywords(keywords?.split(","));
              }
              setShowImage(true);
              // if (results[0]?.videos[0]?.is_uploaded === 0) {
              //   setShowVideoLink(true)
              // }else{
              //   setShowVideoFile(true)
              // }
              if (results[0]?.audios.length > 0) {
                setShowAudio(true);
              }
              getApi(
                "LOCAL",
                `/api/category/localgovernments?state_id=${results[0]?.address?.state_id}`
              );

              let arrFeatures = [];
              state.listFeatures.map((r) => {
                let isActive = r.isActive;
                results[0]?.features.forEach((f) => {
                  if (r.id === f.feature_id) {
                    isActive = true;
                  }
                });
                return arrFeatures.push({
                  ...r,
                  isActive: isActive,
                });
              });

              // let arrUpgrade = []
              // state.listUpgrade.map((r) => {
              //   let isActive = r.isActive
              //   results[0]?.upgrades.forEach((f) => {
              //     if (r.id === f.upgrade_id) {
              //       isActive = true
              //     }
              //   })
              //   return arrUpgrade.push({
              //     ...r,
              //     isActive: isActive
              //   })
              // })

              let arrPhotos = [];
              results[0]?.photos.map((r) => {
                return arrPhotos.push({
                  ...r,
                  isEdit: true,
                });
              });

              setState((prevState) => {
                return {
                  ...prevState,
                  // Basics
                  title: results[0]?.title,
                  listCategory: listCat,
                  listSubCategory: listSubCat,
                  fulladdress: results[0]?.address?.address,
                  city: results[0]?.address?.state_id.toString(),
                  local: results[0]?.address?.local_government_id.toString(),
                  // Description
                  desc: results[0]?.description,
                  listFeatures: arrFeatures,
                  // Details
                  adsType: results[0]?.ads_type.toString(),
                  // delivery: results[0]?.delivery_type.toString(),
                  delivery: results[0]?.shipping_status.toString(),
                  startingPrice: parseInt(results[0]?.starting_price),
                  buyNowPrice: parseInt(results[0]?.buynow_price),
                  shippingPrice: parseInt(results[0]?.shipping_price),
                  stock: parseInt(results[0]?.qty),
                  isUsed: parseInt(results[0]?.condition),
                  isRefund: parseInt(results[0]?.refund),
                  // brand: results[0]?.brand_id.toString(),
                  brand: results[0]?.brand_id,
                  modelNumber: results[0]?.model_number,
                  color: results[0]?.color_id.toString(),
                  size: results[0]?.size_id.toString(),
                  year: results[0]?.year_id.toString(),
                  // Media
                  photos: arrPhotos,
                  // videoLinkYt: results[0]?.videos[0]?.is_uploaded === 0 ? results[0]?.videos[0]?.url : ''

                  //Finish
                  // listUpgrade: arrUpgrade,
                };
              });
            }
            // setLoading(false)
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making GET request:", error);
        setLoading(false);
      });
  };

  const handleCategorySelect = (index, status) => {
    let list = [...state.listCategory];
    // list[index] = {
    //   ...list[index] = {
    //     ...list[index],
    //     isActive: status,
    //   }
    // }

    let newSubCategory = [];
    // let arrFind = state.listSubCategory.filter(f => f.category_id === list[index].id)
    // if (arrFind.length === 0 && status) {
    getApi(
      "SUBCATEGORY",
      `/api/category/subcategories?category_id=${list[index].id}`
    );
    // }else{
    //   newSubCategory = state.listSubCategory.filter(f => f.category_id !== list[index].id)
    // }

    setState((prevState) => {
      return {
        ...prevState,
        // listCategory: list,
        listCategory: state.listCategory.map((r, i) => ({
          ...r,
          isActive: index === i ? true : false,
        })),
        listSubCategory: newSubCategory,
      };
    });
  };

  const handleSelect = (index, status, nameKey) => {
    let list = [...state[nameKey]];
    list[index] = {
      ...(list[index] = {
        ...list[index],
        isActive: status,
      }),
    };

    setState((prevState) => {
      return {
        ...prevState,
        [nameKey]: list,
      };
    });
  };

  const handleClearSelect = () => {
    setState((prevState) => {
      return {
        ...prevState,
        listCategory: state.listCategory.map((r) => ({
          ...r,
          isActive: false,
        })),
        listSubCategory: [],
      };
    });
  };

  const handleChangeType = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
        buyNowPrice:
          name === "adsType" && value === "1" ? "" : prevState.buyNowPrice,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === "city") {
      getApi("LOCAL", `/api/category/localgovernments?state_id=${value}`);
    }
  };

  const handleChangeSelect = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        brand: e !== null ? e.value : "",
      };
    });

    if (!e) {
      setLoadingBrand(true);
      getApi("BRANDS", "/api/category/brands");
    }
  };

  const debouncedKeyword = useDebounce(keywordBrand, 500);

  useEffect(() => {
    if (debouncedKeyword !== "") {
      setLoadingBrand(true);
      getApi("BRANDS", "/api/category/brands?keyword=" + keywordBrand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword]);

  useEffect(() => {
    // if (user.adTemp !== null || user.adTemp !== undefined) {
    if (isSave) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSave]);

  const handleSave = (paymentMethod) => {
    if (isLoadingSave) return false;

    let getSubCategory = state.listSubCategory.filter((f) => f.isActive);
    let getFeatures = state.listFeatures.filter((f) => f.isActive);
    let getUpgrade = state.listUpgrade.filter((f) => f.isActive);
    let getPhotos = isEdit
      ? state.photos.filter((f) => !f.isEdit)
      : [...state.photos];

    if (!isEdit && getPhotos.length < 3)
      return ShowAlert("Upload minimum of three photos!");

    setLoadingSave(true);

    const apiUrl = isEdit
      ? process.env.REACT_APP_API_URL + "/api/ad/update/" + params.id
      : process.env.REACT_APP_API_URL + "/api/ad/create";
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      "Content-Type": "multipart/form-data",
    };

    const bodyForm = new FormData();

    // Basics Tab
    bodyForm.append("title", state.title);
    for (let i = 0; i < getSubCategory.length; i++) {
      bodyForm.append(`categories[${i}]`, getSubCategory[i].id);
    }
    bodyForm.append("address", state.fulladdress);
    bodyForm.append("country_id", state.country);
    bodyForm.append("state_id", state.city);
    bodyForm.append("local_government_id", state.local);
    // bodyForm.append('longitude', state.longitude);
    // bodyForm.append('latitude', state.latitude);

    // Description Tab
    bodyForm.append("description", state.desc);
    // bodyForm.append('keywords', state.keyword);
    bodyForm.append("keywords", keywords.toLocaleString());
    for (let i = 0; i < getFeatures.length; i++) {
      bodyForm.append(`features[${i}]`, getFeatures[i].id);
    }

    // Details Tab
    bodyForm.append("ads_type", state.adsType);
    bodyForm.append("starting_price", state.startingPrice);
    bodyForm.append("buynow_price", state.buyNowPrice);
    // bodyForm.append('reserve_price', state.reservePrice);
    bodyForm.append("shipping_price", state.shippingPrice);
    if (state.adsType === "0") {
      bodyForm.append("qty", state.stock);
    }
    // bodyForm.append('delivery_type', state.delivery);
    bodyForm.append("shipping_status", state.delivery);
    bodyForm.append("condition", state.isUsed);
    bodyForm.append("refund", state.isRefund);
    bodyForm.append("brand_id", state.brand);
    bodyForm.append("model_number", state.modelNumber);
    bodyForm.append("color_id", state.color);
    bodyForm.append("size_id", state.size);
    bodyForm.append("year_id", state.year);

    // Media Tabs
    for (let i = 0; i < getPhotos.length; i++) {
      bodyForm.append(`photos[${i}]`, getPhotos[i].images);
    }
    if (state.video.length > 0) {
      bodyForm.append("video_file", state.video[0].file);
    } else {
      bodyForm.append("video_url", state.videoLinkYt);
    }
    if (state.audio.length > 0) {
      bodyForm.append("audio_file", state.audio[0].file);
    }

    // Finish Tabs
    if (!isEdit) {
      bodyForm.append("membership_plan_id", state.membershipPlanId);
    }
    // if (state?.sumTotal > 0) {
    if (getUpgrade.length) {
      for (let i = 0; i < getUpgrade.length; i++) {
        bodyForm.append(`upgrades[${i}]`, getUpgrade[i].id);
      }
      bodyForm.append("payment_method", paymentMethod);
    }

    axios
      .post(apiUrl, bodyForm, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response;
          if (state?.sumTotal > 0 && paymentMethod === 1) {
            window.location.replace(results.authorization_url.url);
          } else {
            setTimeout(() => {
              if (!isEdit) {
                let arrMembership = [...state.listMembership];
                let index = arrMembership
                  .map((x) => {
                    return x.membership_plan;
                  })
                  .indexOf(state.membershipPlanId);
                let item = { ...arrMembership[index] };
                item.quota_left = parseInt(item.quota_left) - 1;
                arrMembership[index] = item;
                dispatch(
                  setUser({ ...user, membership: arrMembership, adTemp: null })
                );
              }

              Swal.fire({
                icon: "success",
                title: "Success",
                text: messages,
                confirmButtonColor: "#B38C32",
              });
              setSave(false);
              setLoadingSave(false);
              // setLoadingPayUpgrade(false)
              if (isEdit) {
                navigate("/my-account?tabs=listings");
              } else {
                navigate("/");
              }
            }, 100);
          }
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        if (error.response) {
          // Server responded with a status code other than 2xx
          const { messages } = error.response.data.response;
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: messages,
            confirmButtonColor: "#B38C32",
          });
          setSave(false);
          setLoadingSave(false);
        } else if (error.request) {
          // Request was made but no response received
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: error.message,
            confirmButtonColor: "#B38C32",
          });
          setSave(false);
          setLoadingSave(false);
        } else {
          // Something else went wrong
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: error.message,
            confirmButtonColor: "#B38C32",
          });
          setSave(false);
          setLoadingSave(false);
        }
      });
  };
  /* const getMyAdsCount = () => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/ad/count";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios.get(apiUrl, { headers }).then((response) => {
      if (response.status === 200) {
        const { results } = response.data.response;
        dispatch(setUser({ ...user, total_ads: results.total_ads }));
      }
    });
  }; */
  const postApi = (bodyParams) => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/ad/remove_file";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            // const { results } = response.data.response
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Your photo has been removed!",
              confirmButtonColor: "#B38C32",
            });
          }, 100);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        const { messages } = error.response.data.response;
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: messages,
          confirmButtonColor: "#DC2625",
        });
      });
  };

  const handleTab = (tab) => {
    if (tabActive === 1) {
      if (state.title === "")
        return ShowAlert("Ad title is required!", "Enter ad title");
      let getSubCategory = state.listSubCategory.filter((f) => f.isActive);
      if (getSubCategory.length === 0)
        return ShowAlert(
          "Select at least one sub category!",
          "Choose sub category"
        );
      // if (state.fulladdress === '') return ShowAlert('Full address is required!', 'Enter full address')
      if (state.city === "")
        return ShowAlert("State is required!", "Enter State address");
      if (state.local === "")
        return ShowAlert(
          "Local government is required!",
          "Enter local government"
        );
    }
    if (tabActive === 2) {
      if (state.desc === "")
        return ShowAlert("Description is required!", "Enter ad description");
      if (state.desc.length < 50)
        return ShowAlert("Description minimum 50 character!");
    }
    if (tabActive === 3) {
      if (state.adsType === "0" && state.buyNowPrice === "")
        return ShowAlert(
          "Buy now price must be greater than 20!",
          "Invalid buy now price"
        );
      if (state.adsType === "0" && parseInt(state.buyNowPrice) <= 20)
        return ShowAlert(
          "Buy now price must be greater than 20!",
          "Invalid buy now price"
        );
      if (state.adsType === "0" && parseInt(state.stock) <= 0)
        return ShowAlert(
          "Stock value must be greater than zero!",
          "Invalid stock value"
        );
      if (state.adsType === "0" && state.stock === "")
        return ShowAlert(
          "Stock value must be greater than zero!",
          "Invalid stock value"
        );
      if (state.adsType === "1" && state.startingPrice === "")
        return ShowAlert("Starting price is required!");
      if (state.adsType === "1" && parseInt(state.startingPrice) <= 20)
        return ShowAlert("Starting price must be greater than 20!");
      // if (state.adsType === '1' && state.buyNowPrice === 0) return ShowAlert('Buy Now Price is required!')
      if (state.adsType === "1" && isBuynow && state.buyNowPrice === "")
        return ShowAlert("Buy Now Price is required!");
      if (
        state.adsType === "1" &&
        isBuynow &&
        parseInt(state.buyNowPrice) <= parseInt(state.startingPrice)
      )
        return ShowAlert(
          "Buy Now Price must be greater than the Starting Price!"
        );
      // if (state.delivery === '0' && state.shippingPrice === 0) rteeturn ShowAlert('Shipping Price is required!')

      if (state.brand === "") return ShowAlert("Brand is required!");
      // if (state.modelNumber === '') return ShowAlert('Model Number is required!')
      if (state.color === "") return ShowAlert("Color is required!");
      if (state.size === "") return ShowAlert("Size is required!");
      if (state.year === "") return ShowAlert("Year is required!");
    }
    if (tabActive === 4) {
      let getPhotos = isEdit
        ? state.photos.filter((f) => f.isEdit)
        : [...state.photos];
      if (getPhotos.length < 3)
        return ShowAlert("Please upload minimum of three photos!");
      if (getPhotos.length > 15)
        return ShowAlert(
          "Upload not more than 15 photos.",
          "Photos Limit Exceeded"
        );
    }

    dispatch(
      setUser({
        ...user,
        adTemp: {
          ...state,
          keywords: keywords,
          photos: [],
          video: [],
          audio: [],
        },
      })
    );
    setTabActive(tab);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 500);
  }, [tabActive]);

  const ShowAlert = (text, title) => {
    Swal.fire({
      icon: "warning",
      title: !title ? "Warning" : title,
      text: text,
      confirmButtonColor: "#B38C32",
    });
  };

  function TopTitle() {
    return (
      <div className=" flex items-center space-x-4">
        <div
          className={` bg-gray-500 w-8 h-8 lg:w-10 lg:h-10 rounded-full  text-white font-semibold lg:text-lg justify-center flex items-center`}
        >
          0{tabActive}
        </div>
        <div className=" text-xl lg:text-3xl font-semibold">
          {tabs[tabActive - 1].title === "Basics"
            ? isEdit
              ? "Edit auction"
              : "Create a new ad"
            : tabs[tabActive - 1].title}
        </div>
      </div>
    );
  }

  function ButtonPrevNext() {
    return (
      <div className=" mt-12 flex justify-between px-4 lg:px-12 pb-4">
        {tabActive !== 1 ? (
          <button
            onClick={() => setTabActive(tabActive - 1)}
            className=" flex space-x-2 text-sm lg:text-base items-center p-2 px-4 lg:px-6 text-black font-semibold rounded bg-white border border-black hover:shadow-md hover:bg-black hover:text-white focus:outline-none duration-300"
          >
            <HiMiniArrowSmallLeft className=" text-2xl lg:text-4xl" />
            Back
          </button>
        ) : (
          <div />
        )}
        {tabActive !== 5 ? (
          <button
            // onClick={() => setTabActive(tabActive+1)}
            onClick={() => handleTab(tabActive + 1)}
            className=" flex space-x-2 text-sm lg:text-base items-center p-2 px-4 lg:px-6 text-black font-semibold rounded bg-white border border-black hover:shadow-md hover:bg-black hover:text-white focus:outline-none duration-300"
          >
            Next
            <HiMiniArrowSmallRight className=" text-2xl lg:text-4xl" />
          </button>
        ) : (
          <button
            // onClick={() => state?.sumTotal > 0 ? setShowModalPayUpgrade(true) : handleSave()}
            onClick={() => {
              if (state?.sumTotal > 0) {
                setShowModalPayUpgrade(true);
              } else {
                // dispatch(setUser({...user, adTemp: {...state, keywords: keywords}}))
                setSave(true);
              }
            }}
            className=" p-2 px-6 w-40 text-sm lg:text-base flex justify-center items-center text-white font-semibold rounded bg-[#F8BA09] border border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] focus:outline-none duration-300"
          >
            {isLoadingSave ? (
              <LoadingIndicator />
            ) : state?.sumTotal > 0 ? (
              "Pay Now"
            ) : isEdit ? (
              "Update Ad"
            ) : (
              "Post Ad"
            )}
          </button>
        )}
      </div>
    );
  }

  const basicsComponent = () => {
    return (
      <div className=" flex flex-col space-y-8 mt-8">
        {!isEdit ? (
          <>
            <div className=" flex items-center flex-row space-x-4">
              <div className=" lg:text-2xl font-medium w-40">Ad Plan</div>
              <div className=" border-b border-gray-500 w-full" />
            </div>
            <label className=" text-sm lg:text-base font-medium">
              Choose an ad plan for your ad creation.{" "}
              <span className=" text-red-500">*</span>
            </label>
            {state?.listMembership.length > 0 ? (
              <div className=" flex space-y-3 flex-col">
                {state?.listMembership.map((row) => (
                  <div
                    key={row.membership_plan}
                    className=" p-4 px-2 lg:p-8 rounded flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-start justify-between border cursor-pointer"
                    onClick={() =>
                      setState((prevState) => {
                        return {
                          ...prevState,
                          membershipPlanId: row.membership_plan,
                          membershipPlantext: row.lifetime_text,
                        };
                      })
                    }
                  >
                    <div className=" flex items-start">
                      <div className=" flex items-start">
                        <input
                          type={"radio"}
                          className="  mt-2 cursor-pointer"
                          id={`radio-membership-${row.membership_plan}`}
                          name="membershipPlanId"
                          value={row?.membership_plan}
                          checked={
                            state.membershipPlanId === row.membership_plan
                              ? true
                              : false
                          }
                          onChange={() =>
                            setState((prevState) => {
                              return {
                                ...prevState,
                                membershipPlanId: row.membership_plan,
                                membershipPlantext: row.lifetime_text,
                              };
                            })
                          }
                        />
                        <div className=" ml-4">
                          <label
                            className=" lg:text-xl font-medium cursor-pointer"
                            htmlFor={`radio-membership-${row.membership_plan}`}
                          >
                            {row.title}
                          </label>
                          <div className=" text-gray-500 text-xs lg:text-sm">
                            {row.expired_at_text}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" rounded-full p-2 px-3 ml-8 lg:ml-0 text-white font-medium bg-[#B38C32] text-xs lg:text-sm">
                      {row?.quota_left + " ads left"}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className=" flex flex-col justify-center items-center space-y-2">
                <div className=" text-xl text-gray-500">
                  Your Account has no ad plans
                </div>
                <div className=" flex">
                  {/* <div 
                    className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                    onClick={() => setShowModalBC(true)}
                  >
                    Buy Credit
                  </div> */}
                  <div
                    className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                    onClick={() => navigate("/my-account?tabs=membership")}
                  >
                    Buy Ad Plan
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}

        <div className=" flex flex-col space-y-2">
          <div className=" justify-between flex-row flex">
            <label className=" text-sm lg:text-base font-medium">
              Create a title for your ad.{" "}
              <span className=" text-red-500">*</span>
            </label>
            <label className=" text-xs lg:text-sm">
              <span className=" font-semibold">{75 - state.title.length}</span>{" "}
              Maximum characters remaining
            </label>
          </div>
          <input
            className=" text-sm lg:text-base border w-full bg-gray-50 rounded p-4 focus:outline-none "
            placeholder="eg. Used Sony TV"
            name="title"
            value={state?.title}
            onChange={handleChange}
            maxLength={75}
          />
        </div>
        <div className=" flex flex-col space-y-2">
          <label className=" text-sm lg:text-base font-medium">
            Where do you want your ad displayed?{" "}
            <span className=" text-red-500">*</span>
          </label>
          {/* <div className=" grid grid-cols-2 lg:grid-cols-5 gap-2">
            <button 
              className=" p-2 px-4 text-sm lg:text-base border focus:outline-none rounded cursor-pointer flex items-center"
              onClick={() => setModalCategory(true)}
            >
              <HiPlusCircle  className=" text-2xl mr-2"/>
              Category
            </button>
           
            {state?.listSubCategory.filter(f => f.isActive).map(row => (
              <div 
                key={row?.id}
                className=" p-2 px-4 bg-gray-50 text-sm border focus:outline-none rounded cursor-pointer flex items-center"
              >
                <HiCheckCircle  className=" mr-1 text-[#366C38]"/>
                <span className=" line-clamp-1 ">{row?.title}</span>
              </div>
            ))}
          </div> */}
          <div className=" flex flex-wrap">
            <button
              className=" p-2 px-4 mr-2 mb-2 text-sm lg:text-base border focus:outline-none rounded cursor-pointer flex items-center"
              onClick={() => setModalCategory(true)}
            >
              <HiPlusCircle className=" text-2xl mr-2" />
              Category
            </button>

            {state?.listSubCategory
              .filter((f) => f.isActive)
              .map((row) => (
                <div
                  key={row?.id}
                  className=" p-2 px-4 mr-2 mb-2 bg-gray-50 text-sm border focus:outline-none rounded cursor-pointer flex items-center"
                >
                  <HiCheckCircle className=" mr-1 text-[#366C38]" />
                  <span className=" line-clamp-1 ">{row?.title}</span>
                </div>
              ))}
          </div>
        </div>
        <div className=" flex flex-col space-y-2">
          <label className=" text-sm lg:text-base font-medium">
            Update location <span className=" text-red-500">*</span>
          </label>
          <div>
            <button
              className=" text-sm lg:text-base border rounded-lg p-4 px-8 flex flex-col space-y-3 items-center w-full lg:w-auto"
              onClick={() => setModalLocation(true)}
            >
              <HiOutlineMapPin className=" text-4xl" />
              <span>
                {/* {`Nigeria, ${state?.fulladdress !== '' ? state.fulladdress : 'Add location'}`} */}
                {`Nigeria, ${
                  state?.city !== "" && state?.local
                    ? state?.listCity.filter(
                        (f) => f.id === parseInt(state?.city)
                      )[0]?.name +
                      ", " +
                      state?.listLocal.filter(
                        (f) => f.id === parseInt(state?.local)
                      )[0]?.name
                    : "Add location"
                }`}
              </span>
              {state?.fulladdress !== "" ? (
                <span>
                  {/* {state?.listCity.filter(f => f.id === parseInt(state?.city))[0]?.name +', '+state?.listLocal.filter(f => f.id === parseInt(state?.local))[0]?.name} */}
                  {state?.fulladdress}
                </span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const descriptionComponent = () => {
    return (
      <div className=" flex flex-col space-y-8 mt-8">
        <div className=" flex flex-col space-y-2">
          <div className=" flex flex-col space-y-1 lg:space-y-0 lg:flex-row justify-between lg:items-center">
            <label className="  text-sm lg:text-base font-medium">
              Description <span className=" text-red-500">*</span>
            </label>
            {state.desc.length < 51 ? (
              <label className=" text-xs lg:text-sm">
                <span className=" font-semibold">{50 - state.desc.length}</span>{" "}
                Minimum characters remaining
              </label>
            ) : null}
          </div>
          <textarea
            className=" border w-full text-sm lg:text-base bg-gray-50 rounded p-4 focus:outline-none "
            rows={8}
            name="desc"
            value={state?.desc}
            onChange={handleChange}
          />
        </div>
        <div className=" flex flex-col space-y-2">
          <label className=" text-sm lg:text-base font-medium">Keyword</label>
          <TagsInput
            value={keywords}
            onChange={setKeywords}
            name="keyword"
            // placeHolder="enter keyword"
          />
          <span className=" text-sm text-gray-400">
            Press enter to add new keyword.
          </span>
          {/* <span className=" text-sm text-gray-400">Separate each keyword with a comma.</span> */}
        </div>
        <div className=" flex flex-col space-y-2">
          <label className=" text-sm lg:text-base font-medium">Features</label>
          <div className=" grid grid-cols-2 lg:grid-cols-3 gap-4">
            {!isLoading &&
              state?.listFeatures.map((row, index) => (
                <div
                  key={row.id}
                  className=" flex items-center space-x-2"
                  // onClick={() => handleFeaturesSelect(index, !row.isActive)}
                >
                  <input
                    type={"checkbox"}
                    checked={row.isActive}
                    id={`checkbox-features-${index}`}
                    onChange={() =>
                      handleSelect(index, !row.isActive, "listFeatures")
                    }
                  />
                  <label
                    htmlFor={`checkbox-features-${index}`}
                    className=" cursor-pointer  text-xs lg:text-sm"
                  >
                    {row.title}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const detailsComponent = () => {
    return (
      <div className=" flex flex-col space-y-8 mt-8">
        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium">Details</div>
          <div className=" border-b border-gray-500 w-full" />
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-8">
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">Ad Type</label>
            <select
              className=" w-full p-3 px-4 text-sm lg:text-base bg-gray-50 border rounded focus:outline-none"
              name="adsType"
              value={state.adsType}
              onChange={handleChangeType}
            >
              <option value={0}>Classifieds (Buy Now Only)</option>
              <option value={1}>Normal Auction</option>
            </select>
            <span className=" text-xs lg:text-sm text-gray-400">
              Here you can choose the format of your auction.
            </span>
          </div>
          {state?.adsType === "1" ? (
            <div className=" flex flex-col space-y-2">
              <label className=" text-sm lg:text-base font-medium">
                Starting Price <span className=" text-red-500">*</span>
              </label>
              <div className=" flex items-center">
                <div className=" p-3 w-12 text-center text-sm lg:text-base text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">
                  N
                </div>
                <input
                  type={"number"}
                  min={0}
                  className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none"
                  name="startingPrice"
                  value={state.startingPrice}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <span className=" text-xs lg:text-sm text-gray-400">
                Here you can choose the format of your auction.
              </span>
            </div>
          ) : null}
          <div className=" flex flex-col space-y-2">
            {state?.adsType === "0" ? (
              <label className=" text-sm lg:text-base font-medium">
                Buy Now Price <span className=" text-red-500">*</span>
              </label>
            ) : (
              <div
                className=" flex items-center space-x-2"
                // onClick={() => handleFeaturesSelect(index, !row.isActive)}
              >
                <input
                  type={"checkbox"}
                  id={`checkbox-buynow`}
                  checked={isBuynow}
                  onChange={() => setBuynow(!isBuynow)}
                />
                <label
                  htmlFor={`checkbox-buynow`}
                  className=" cursor-pointer text-sm lg:text-base font-medium"
                >
                  Buy Now Price (Optional)
                </label>
              </div>
            )}
            <div className=" flex items-center">
              <div className=" p-3 w-12 text-sm lg:text-base text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">
                N
              </div>
              <input
                type={"number"}
                min={0}
                className={` w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none ${
                  state?.adsType === "1" && !isBuynow
                    ? "disabled:bg-gray-200"
                    : ""
                } `}
                name="buyNowPrice"
                value={state.buyNowPrice}
                onChange={handleChange}
                disabled={state?.adsType === "1" && !isBuynow}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <span className=" text-xs lg:text-sm text-gray-400">
              Here you can set a price for the user to buy this item outright.
            </span>
          </div>
          {/* {state?.adsType === '1' ? (
            <div className=" flex flex-col space-y-2">
              <label className=" font-medium">Reserve Price <span className=" text-red-500">*</span></label>
              <div className=" flex items-center">
                <div className=" p-3 w-12 text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                <input 
                  type={'number'} 
                  min={0}
                  className=" w-full p-3 px-4 bg-gray-50 border rounded-r focus:outline-none"
                  name="reservePrice"
                  value={state.reservePrice}
                  onChange={handleChange}
                />
              </div>
              <span className=" text-sm text-gray-400">Here you can set the lowest price your willing to sell this item for.</span>
            </div>
          ): null} */}
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Delivery
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="delivery"
              value={state.delivery}
              onChange={handleChange}
            >
              <option value={0}>Pickup Only</option>
              <option value={1}>Shipping From My Location</option>
            </select>
          </div>
          {/* {state.delivery === '0' ? (
            <div className=" flex flex-col space-y-2">
              <label className=" text-sm lg:text-base font-medium">Shipping Price <span className=" text-red-500">*</span></label>
              <div className=" flex items-center">
                <div className=" p-3 w-12 text-sm lg:text-base  text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                <input 
                  type={'number'} 
                  min={0}
                  className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                  name="shippingPrice"
                  value={state.shippingPrice}
                  onChange={handleChange}
                />
              </div>
              <span className=" text-xs lg:text-sm text-gray-400">Here you enter an amount for shipping this item.</span>
            </div>
          ): null}    */}
          {state.adsType === "0" ? (
            <div className=" flex flex-col space-y-2">
              <label className=" text-sm lg:text-base font-medium">
                Stock <span className=" text-red-500">*</span>
              </label>
              <div className=" flex items-center">
                <input
                  type={"number"}
                  min={1}
                  className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none"
                  name="stock"
                  value={state.stock}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <span className=" text-xs lg:text-sm text-gray-400">
                Quantity in stock.
              </span>
            </div>
          ) : null}
        </div>

        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium">Refunds</div>
          <div className=" border-b border-gray-500 w-full" />
        </div>
        <div className=" grid md:grid-cols-2 gap-2 lg:gap-8">
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Condition
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="isUsed"
              value={state.isUsed}
              onChange={handleChange}
            >
              <option value={0}>New</option>
              <option value={1}>Used</option>
            </select>
          </div>
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              7 Day Refunds
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="isRefund"
              value={state.isRefund}
              onChange={handleChange}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </div>
        </div>

        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium">Brand</div>
          <div className=" border-b border-gray-500 w-full" />
        </div>
        <div className=" grid md:grid-cols-2 gap-2 lg:gap-8">
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Brand <span className=" text-red-500">*</span>
            </label>
            {/* <select 
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none" 
              name="brand"
              value={state?.brand}
              onChange={handleChange}  
            >
              <option disabled value=''>Select</option>
              {state?.listBrand.map(row => (
                <option 
                  key={row.id} 
                  value={row.id}
                >
                  {row.title}
                </option>
              ))}
            </select> */}
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Type to search brand"
              // defaultValue={state?.brand}
              isDisabled={false}
              isLoading={isLoadingBrand}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="brand"
              options={state?.listBrand}
              onChange={(e) => handleChangeSelect(e)}
              value={state?.listBrand.filter(function (option) {
                return option.value === state?.brand;
              })}
              onInputChange={(e) => setKeywordBrand(e)}
            />
          </div>
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">Model</label>
            {/* <label className=" lg:hidden text-sm lg:text-base font-medium">Model</label> */}
            <input
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="modelNumber"
              value={state?.modelNumber}
              onChange={handleChange}
              placeholder="e.g. Camry, if brand is Toyota"
            />
          </div>
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Color <span className=" text-red-500">*</span>
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="color"
              value={state?.color}
              onChange={handleChange}
            >
              <option disabled value="">
                Select
              </option>
              {state?.listColor.map((row) => (
                <option key={row.id} value={row.id}>
                  {row.title}
                </option>
              ))}
            </select>
          </div>
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Size <span className=" text-red-500">*</span>
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="size"
              value={state?.size}
              onChange={handleChange}
            >
              <option disabled value="">
                Select
              </option>
              {state?.listSize.map((row) => (
                <option key={row.id} value={row.id}>
                  {row.title}
                </option>
              ))}
            </select>
          </div>
          <div className=" flex flex-col space-y-2">
            <label className=" text-sm lg:text-base font-medium">
              Year <span className=" text-red-500">*</span>
            </label>
            <select
              className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded focus:outline-none"
              name="year"
              value={state?.year}
              onChange={handleChange}
            >
              <option disabled value="">
                Select
              </option>
              {state?.listYear.map((row) => (
                <option key={row.id} value={row.id}>
                  {row.title}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const [showImage, setShowImage] = useState(false);
  // const [showVideo, setShowVideo] = useState(false)
  const [showVideoFile, setShowVideoFile] = useState(false);
  const [showVideoLink, setShowVideoLink] = useState(false);
  const [showAudio, setShowAudio] = useState(false);

  const refPhotos = useRef();
  const refVideo = useRef();
  const refAudio = useRef();

  const handleChangeFiles = (e) => {
    const { name, files } = e.target;
    // console.log('files',files);
    if (files && files.length) {
      let val = [];
      if (name === "photos") {
        if (files[0].size > 5000000) return ShowAlert("File size maximal 5mb!");
        val = state.photos;
        for (let i = 0; i < files.length; i++) {
          val.push({
            dateTime: new Date(),
            url: URL.createObjectURL(files[i]),
            images: files[i],
          });
        }
      } else {
        let getFormat = files[0].name.split(".");
        let accVideoFormat = [
          "mp4",
          "avi",
          "mkv",
          "mov",
          "wmv",
          "flv",
          "webm",
          "3gp",
          "m4v",
          "mpeg",
          "mpg",
        ];
        let accAudioFormat = [
          "mp3",
          "wav",
          "flac",
          "aac",
          "ogg",
          "wma",
          "m4a",
          "ape",
          "alac",
          "mid",
          "midi",
        ];
        if (name === "video" && files[0].size > 10000000)
          return ShowAlert("File size maximal 10mb!");
        if (name === "video" && !accVideoFormat.includes(getFormat[1]))
          return ShowAlert("File format video not support!");
        if (name === "audio" && files[0].size > 5000000)
          return ShowAlert("File size maximal 5mb!");
        if (name === "audio" && !accAudioFormat.includes(getFormat[1]))
          return ShowAlert("File format audio not support!");
        val.push({
          name: files[0].name,
          file: files[0],
        });
      }

      setState((prevState) => {
        return {
          ...prevState,
          [name]: val,
        };
      });
    }
  };

  const handleRemoveFile = (name, index) => {
    let arr = [...state.photos];
    if (index !== -1) {
      // if (window.confirm('Are you sure you wish to delete this item?'))
      Swal.fire({
        title: "Delete photo?",
        text: "Confirm to delete selected item!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        confirmButtonColor: "#B38C32",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          arr.splice(index, 1);
          setState((prevState) => {
            return {
              ...prevState,
              [name]: arr,
            };
          });
          if (isEdit) {
            let params = {
              id: state.photos[index]?.id,
              type: "image",
            };
            postApi(params);
          }
        }
      });
    }
  };

  const mediaComponent = () => {
    return (
      <div className=" flex flex-col space-y-8 mt-8">
        <div className=" flex items-center flex-row space-x-4">
          <div className="w-full lg:w-60">
            <div className=" lg:text-2xl font-medium ">My Photos</div>
            <div className=" text-xs lg:text-sm font-light">
              Upload min of 3 photos
            </div>
          </div>
          <div className=" border-b border-gray-500 md:w-full" />
          <div className=" lg:text-2xl font-medium">
            {state.photos.length}/15
          </div>
        </div>
        <div className=" flex">
          {!showImage ? (
            <div
              className="border-dashed border-2 p-8 px-16 bg-gray-50 rounded-lg flex flex-col items-center space-y-2 cursor-pointer hover:bg-gray-100 duration-300"
              onClick={() => setShowImage(true)}
            >
              <FaRegFileImage className=" text-4xl text-gray-400" />
              <label className=" font-medium">Add new</label>
            </div>
          ) : (
            <div className=" flex flex-col w-full duration-500">
              <div className=" bg-gray-50 rounded flex flex-row p-4 lg:p-8 items-start justify-between w-full duration-500">
                <div className=" flex items-start">
                  <FaRegFileImage className=" hidden md:block text-4xl lg:text-6xl text-[#343A3F]" />
                  <div className=" ml-0 lg:ml-4 space-y-3">
                    <div className=" flex items-center flex-row cursor-pointer ">
                      <div
                        className=" rounded-l text-sm lg:text-base border w-3/4 lg:w-80 bg-white p-2 focus:outline-none"
                        onClick={() =>
                          state.photos.length < 15 && refPhotos.current.click()
                        }
                      >
                        <label>Add new photo</label>
                      </div>
                      <input
                        className=" hidden text-sm lg:text-base rounded-l border lg:w-80 bg-white p-2 focus:outline-none"
                        accept=".jpg,.jpeg,.png"
                        name="photos"
                        ref={refPhotos}
                        onChange={handleChangeFiles}
                        type="file"
                        multiple
                      />
                      <button
                        className=" bg-[#343A3F] p-2 text-sm lg:text-base text-white border rounded-r border-[#343A3F]"
                        onClick={() =>
                          state.photos.length < 15 && refPhotos.current.click()
                        }
                      >
                        Browse
                      </button>
                    </div>
                    <label className="text-xs lg:text-sm ml-1">
                      Photos are set private until you go live. We support .jpg,
                      .jpeg and .png images only. <br />
                      (File size max 5mb)
                    </label>
                  </div>
                </div>
                <FaTimes
                  className=" text-2xl text-[#343A3F] cursor-pointer ml-2 md:ml-0"
                  onClick={() => setShowImage(false)}
                />
              </div>
              <div className=" mt-8 grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4">
                {state?.photos.map((row, index) => (
                  <div
                    key={index}
                    className=" border rounded border-gray-500 relative"
                  >
                    <FaTimesCircle
                      className=" text-2xl absolute -right-1 -top-1 cursor-pointer"
                      onClick={() => handleRemoveFile("photos", index)}
                    />
                    <img
                      alt="img-upload"
                      src={row.url}
                      className=" rounded-sm object-cover w-full h-40"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium w-full lg:w-60">
            My Videos
          </div>
          <div className=" border-b border-gray-500 md:w-full" />
          <div className=" lg:text-2xl font-medium">
            {state.video.length > 0 || state.videoLinkYt !== "" ? 1 : 0}/1
          </div>
        </div>
        <div className=" flex">
          {!showVideoFile && !showVideoLink ? (
            <div className=" flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-4">
              <div
                className="border-dashed border-2 p-8 px-16 bg-gray-50 rounded-lg flex flex-col items-center space-y-2 cursor-pointer hover:bg-gray-100 duration-300"
                onClick={() => {
                  setShowVideoFile(true);
                  setShowVideoLink(false);
                }}
              >
                <FaRegFileVideo className=" text-4xl text-gray-400" />
                <label className=" font-medium">Add new</label>
              </div>
              <div
                className="border-dashed border-2 p-8 px-16 bg-gray-50 rounded-lg flex flex-col items-center space-y-2 cursor-pointer hover:bg-gray-100 duration-300"
                onClick={() => {
                  setShowVideoFile(false);
                  setShowVideoLink(true);
                }}
              >
                <FaLink className=" text-4xl text-gray-400" />
                <label className=" font-medium">Add link</label>
              </div>
            </div>
          ) : (
            <div className=" flex flex-col w-full duration-500">
              <div className=" bg-gray-50 rounded flex flex-row p-4 lg:p-8 items-start justify-between w-full">
                <div className=" flex items-start w-full">
                  <FaRegFileVideo className="hidden md:block text-4xl lg:text-6xl text-[#343A3F]" />
                  {showVideoFile ? (
                    <div className=" lg:ml-4 flex flex-col">
                      <div className=" flex items-center flex-row cursor-pointer ">
                        <div
                          className=" text-sm lg:text-base rounded-l border w-3/4 lg:w-80 bg-white p-2 focus:outline-none"
                          onClick={() => refVideo.current.click()}
                        >
                          <label>{`${
                            state.video.length > 0
                              ? state.video[0].name
                              : "Add new video"
                          }`}</label>
                        </div>
                        <input
                          className=" hidden text-sm lg:text-base rounded-l border lg:w-80 bg-white p-2 focus:outline-none"
                          accept=".mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .3gp, .m4v, .mpeg, .mpg"
                          name="video"
                          ref={refVideo}
                          onChange={handleChangeFiles}
                          type="file"
                          // multiple
                        />
                        <button
                          className=" bg-[#343A3F] text-sm lg:text-base p-2 text-white border rounded-r border-[#343A3F]"
                          onClick={() => refVideo.current.click()}
                        >
                          Browse
                        </button>
                      </div>
                      <label className=" text-xs lg:text-sm ml-1">
                        Videos are set private until you go live. We support
                        .flv and .mp4 videos only. <br />
                        (File size max 10mb)
                      </label>
                    </div>
                  ) : (
                    <div className=" md:ml-4 space-y-3 w-full">
                      <div className=" relative w-5/6 lg:w-1/2">
                        <input
                          className=" rounded border p-2 px-4 w-full text-sm lg:text-base focus:outline-none bg-white"
                          placeholder="Youtube Video ID"
                          name="videoLinkYt"
                          value={state?.videoLinkYt}
                          onChange={handleChange}
                        />
                        <FaYoutube className=" absolute text-2xl right-4 top-2" />
                      </div>
                      {/* <div className=" relative w-1/2">
                        <input className=" rounded border p-2 px-4 w-full focus:outline-none bg-white" placeholder="Vimeo Video ID"/>
                        <FaVimeo className=" absolute text-2xl right-4 top-2" />
                      </div> */}
                    </div>
                  )}
                </div>
                <FaTimes
                  className=" text-2xl text-[#343A3F] cursor-pointer ml-2 md:ml-0"
                  onClick={() => {
                    setShowVideoFile(false);
                    setShowVideoLink(false);
                  }}
                />
              </div>
            </div>
          )}
          {/* // <div className=" flex flex-col w-full duration-500">
            //   <div className=" mt-8 ml-2 flex flex-col space-y-3">
            //     <div className=" relative w-1/2">
            //       <input className=" rounded border p-2 px-4 w-full focus:outline-none bg-white" placeholder="Youtube Video ID"/>
            //       <FaYoutube className=" absolute text-2xl right-4 top-2" />
            //     </div>
            //     <div className=" relative w-1/2">
            //       <input className=" rounded border p-2 px-4 w-full focus:outline-none bg-white" placeholder="Vimeo Video ID"/>
            //       <FaVimeo className=" absolute text-2xl right-4 top-2" />
            //     </div>
            //   </div>
            // </div>*/}
        </div>

        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium w-full lg:w-60">
            My Audio
          </div>
          <div className=" border-b border-gray-500 md:w-full" />
          <div className=" lg:text-2xl font-medium">
            {state.audio.length > 0 ? 1 : 0}/1
          </div>
        </div>
        <div className=" flex">
          {!showAudio ? (
            <div
              className="border-dashed border-2 p-8 px-16 bg-gray-50 rounded-lg flex flex-col items-center space-y-2 cursor-pointer hover:bg-gray-100 duration-300"
              onClick={() => setShowAudio(true)}
            >
              <FaRegFileAudio className=" text-4xl text-gray-400" />
              <label className=" font-medium">Add new</label>
            </div>
          ) : (
            <div className=" bg-gray-50 rounded flex flex-row p-4 lg:p-8 items-start justify-between w-full duration-500">
              <div className=" flex items-start">
                <FaRegFileAudio className=" hidden md:block text-4xl lg:text-6xl text-[#343A3F]" />
                <div className=" md:ml-4 space-y-3">
                  <div className=" flex items-center flex-row cursor-pointer ">
                    <div
                      className=" text-sm lg:text-base rounded-l border w-3/4 lg:w-80 bg-white p-2 focus:outline-none"
                      onClick={() => refAudio.current.click()}
                    >
                      <label>{`${
                        state.audio.length > 0
                          ? state.audio[0].name
                          : "Add music file"
                      }`}</label>
                    </div>
                    <input
                      className=" hidden text-sm lg:text-base rounded-l border lg:w-80 bg-white p-2 focus:outline-none"
                      accept=".mp3, .wav, .flac, .aac, .ogg, .wma, .m4a, .ape, .alac, .mid, .midi"
                      name="audio"
                      ref={refAudio}
                      onChange={handleChangeFiles}
                      type="file"
                    />
                    <button
                      className=" bg-[#343A3F] text-sm lg:text-base p-2 text-white border rounded-r border-[#343A3F]"
                      onClick={() => refAudio.current.click()}
                    >
                      Browse
                    </button>
                  </div>
                  <label className=" text-xs lg:text-sm ml-1">
                    Music files are set private until you go live. We support
                    .mp3 and .mpeg files only. <br />
                    (File size max 5mb)
                  </label>
                </div>
              </div>
              <FaTimes
                className=" text-2xl text-[#343A3F] cursor-pointer ml-2 md:ml-0"
                onClick={() => setShowAudio(false)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleBuyNow = () => {
    if (parseInt(payPrice) < 100) return ShowAlert("Pay Amount minimal ₦100!");
    setShowModalCP(true);
  };

  const handleTransactionPay = () => {
    setLoadingPay(true);
    const apiUrl = process.env.REACT_APP_API_URL + "/api/transaction/pay";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      // amount: selectCP?.price,
      amount: payPrice,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            window.location.replace(results.authorization_url.url);
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        const { messages } = error.response.data.response;
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: messages,
          confirmButtonColor: "#DC2625",
        });
        setLoadingPay(false);
      });
  };

  const finishComponent = () => {
    return (
      <div className=" flex flex-col space-y-8 mt-8">
        {!isEdit ? (
          <>
            <div className=" flex items-center flex-row space-x-4">
              <div className=" lg:text-2xl font-medium w-40">Upgrades</div>
              <div className=" border-b border-gray-500 w-full" />
            </div>
            <div className=" flex space-y-3 flex-col">
              {state?.listUpgrade.map((row, index) => (
                <div
                  key={row.id}
                  className=" p-3 lg:p-8 rounded flex flex-row items-start justify-between border cursor-pointer"
                  onClick={() =>
                    handleSelect(index, !row.isActive, "listUpgrade")
                  }
                >
                  <div className=" flex items-start">
                    <div className=" flex items-start">
                      <input
                        type={"checkbox"}
                        className="  mt-2 cursor-pointer"
                        id={`checkbox-upgrade-${row.id}`}
                        checked={row.isActive}
                        readOnly
                        // onChange={() => handleSelect(index, !row.isActive, 'listUpgrade')}
                      />
                      <div className=" ml-4">
                        <label
                          className=" lg:text-xl font-medium cursor-pointer"
                          htmlFor={`checkbox-upgrade-${row.id}`}
                        >
                          {row.title}
                        </label>
                        <div className=" text-xs lg:text-base text-gray-500">
                          {row.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:text-xl font-bold">{row.price_text}</div>
                </div>
              ))}
            </div>
          </>
        ) : null}
        <div className=" flex items-center flex-row space-x-4">
          <div className=" lg:text-2xl font-medium w-40">Summary</div>
          <div className=" border-b border-gray-500 w-full" />
        </div>
        <div className=" flex justify-end">
          <div className=" lg:w-1/2 rounded bg-gray-50 p-2 lg:p-8 grid grid-cols-2 gap-4">
            <div className=" font-semibold lg:text-lg">Created</div>
            <div className=" lg:text-lg">Just Now</div>

            <div className=" font-semibold lg:text-lg">Active Period</div>
            <div className=" lg:text-lg">{state?.membershipPlantext}</div>

            <div className=" font-semibold lg:text-lg">Views</div>
            <div className=" lg:text-lg">0</div>

            <div className=" font-semibold lg:text-lg">Status</div>
            <div className=" lg:text-lg">Pending Approval</div>
          </div>
        </div>
      </div>
    );
  };

  const LoadingIndicator = () => <LoadingThreeDots size={"20"} />;

  console.log({ state });
  console.log({ user });

  return (
    <Layout>
      <SEO
        title="Post Your Ad - Paseero | Sell Online in Nigeria"
        description="List your products and services on Paseero.ng, Nigeria's trusted online marketplace. Post an ad today and reach thousands of buyers!"
        name="Paseero.ng | Post an Ad"
        type="website"
        robots="noindex, nofollow"
      />
      <style>
        {`
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          
          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      <div className=" flex justify-center w-full mt-8">
        <div className=" w-[1300px] border rounded bg-white">
          <div className=" flex flex-row">
            <div className="hidden w-[30%] border-r bg-gray-200 p-4 lg:flex flex-col space-y-4 justify-end">
              {/* <img
                alt="img-add"
                src="https://premiummod.com/demoimages/img.php?sidebar=sform_basic&t=at"
              /> */}
              {state?.sumTotal > 0 ? (
                <div className=" p-4 rounded bg-[#343A3F] flex items-center justify-between">
                  <div className=" text-lg font-semibold text-white">Total</div>
                  <div className=" text-lg font-semibold text-white">
                    ₦ {state?.sumTotal}
                  </div>
                </div>
              ) : null}

              <button
                onClick={() => {
                  if (state?.sumTotal > 0) {
                    setShowModalPayUpgrade(true);
                  } else {
                    // dispatch(setUser({...user, adTemp: {...state, keywords: keywords}}))
                    setSave(true);
                  }
                }}
                className=" flex text-sm lg:text-base justify-center w-full p-3 text-white font-semibold rounded bg-[#F8BA09] border border-[#F8BA09] hover:text-[#F8BA09] hover:bg-white focus:outline-none duration-300"
              >
                {isLoadingSave ? (
                  <LoadingIndicator />
                ) : state?.sumTotal > 0 ? (
                  "Pay Now"
                ) : isEdit ? (
                  "Update Ad"
                ) : (
                  "Post Ad"
                )}
              </button>
            </div>
            <div className=" w-full lg:w-[70%] ">
              <div className="p-4 lg:p-12">
                {isLoading ? (
                  <div className=" flex justify-center">
                    <LoadingThreeDots size={"50"} color={"#B38C32"} />
                  </div>
                ) : (
                  <>
                    <TopTitle />
                    {tabActive === 1
                      ? basicsComponent()
                      : tabActive === 2
                      ? descriptionComponent()
                      : tabActive === 3
                      ? detailsComponent()
                      : tabActive === 4
                      ? mediaComponent()
                      : finishComponent()}
                  </>
                )}
              </div>
              <ButtonPrevNext />
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center w-full mt-8">
        <div className=" w-[1300px] grid grid-cols-1 lg:grid-cols-5 gap-y-4 lg:gap-x-4 px-4 lg:px-0">
          {tabs.map((row, index) => (
            <div key={index} className=" flex items-center">
              <div
                className=" flex items-center cursor-pointer"
                // onClick={() => setTabActive(row.id)}
                onClick={() => handleTab(row.id)}
              >
                <div
                  className={` ${
                    tabActive === row.id ? "bg-[#B38C32]" : "bg-gray-500"
                  } w-8 h-8 rounded-full  text-white font-semibold text-sm justify-center flex items-center`}
                >
                  0{row.id}
                </div>
                <div
                  className={`ml-4 text-sm lg:text-base font-semibold ${
                    tabActive === row.id ? "text-[#B38C32]" : "text-black"
                  } `}
                >
                  {row.title}
                </div>
              </div>
              <div
                className={`w-full ml-4 border-b ${
                  tabActive === row.id ? "border-[#B38C32]" : "border-gray-500"
                } `}
              />
            </div>
          ))}
        </div>
      </div>

      <hr className=" my-12" />

      {modalCategory ? (
        <ModalCategory
          listCategory={state?.listCategory}
          listSubCategory={state?.listSubCategory}
          onCategorySelect={handleCategorySelect}
          onSubCategorySelect={handleSelect}
          onClearSelect={handleClearSelect}
          onShowModal={() => setModalCategory(false)}
        />
      ) : null}

      {modalLocation ? (
        <ModalLocation
          {...state}
          isType={"create"}
          listCity={state?.listCity}
          listLocal={state?.listLocal}
          onChange={handleChange}
          onShowModal={() => setModalLocation(false)}
        />
      ) : null}

      {showModalBC ? (
        <ModalBuyCredit
          isType={1} // For my account
          payPrice={payPrice}
          onChange={setPayPrice}
          onBuyNow={handleBuyNow}
          onShowModalBC={setShowModalBC}
        />
      ) : null}

      {showModalCP ? (
        <ModalCreditPurchase
          payPrice={payPrice}
          onTransactionPay={handleTransactionPay}
          onShowModalCP={setShowModalCP}
          isLoading={isLoadingPay}
        />
      ) : null}

      {showModalPayUpgrade ? (
        <ModalPayUpgrade
          selected={{ title: "Pay Upgrade", price_text: state?.sumTotal }}
          onPay={handleSave}
          onShowModal={setShowModalPayUpgrade}
          // isLoading={isLoadingPayUpgrade}
          isLoading={isLoadingSave}
        />
      ) : null}

      {showModalAdTerms ? (
        <ModalAdTermsBeforeAdPost
          onConfirm={() => setShowModalAdTerms(false)}
          onCancel={() => {
            navigate(-1);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default AddListing;
