import React from "react";
import { FaCheck } from "react-icons/fa";
import { LiaTimesCircleSolid } from "react-icons/lia";
import LoadingThreeDots from "./LoadingThreeDots";

const ModalBid = (props) => {
  const { onShowModal } = props;

  const LoadingIndicator = () => <LoadingThreeDots size={"20"} />;
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid
              className=" absolute text-3xl right-1 top-1 cursor-pointer "
              onClick={() => onShowModal(false)}
            />
            <div className=" m-8 mx-6 flex flex-col">
              <div className=" flex flex-col space-y-4 lg:space-y-0 lg:flex-row items-start pb-8 lg:space-x-4">
                <div className=" lg:w-1/2">
                  <div className=" text-lg lg:text-2xl font-semibold">
                    Standard
                  </div>
                  <div className=" text-sm font-light">
                    Enter the amount you wish to bid.
                  </div>
                  {props.user_has_bid === 0 ? (
                    <div className=" text-sm font-light mt-4">
                      You will need to deposit{" "}
                      <span className=" font-semibold text-xl text-[#B38C32] text-blink">
                        {props?.deposit_amount_text}
                      </span>{" "}
                      Paseero credit to bid on these ad. you will be refunded
                      100% if you lose the ads.
                    </div>
                  ) : null}
                </div>

                <div className=" w-full lg:w-1/2 flex space-y-2 flex-col">
                  <div className=" flex justify-between items-center">
                    <div className=" text-lg lg:text-2xl font-medium ">
                      {props.actual_price_text}
                    </div>
                    <div className=" text-sm font-light text-gray-500">
                      Price Now
                    </div>
                  </div>
                  <div className=" flex items-center">
                    <div className=" p-3 w-12 text-sm lg:text-base text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">
                      N
                    </div>
                    <input
                      type={"number"}
                      min={0}
                      className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none"
                      name="bidPrice"
                      value={props.bidPrice}
                      onChange={(e) => props.onChange(e.target.value)}
                    />
                  </div>
                  <div
                    className={`p-2 px-4 text-sm lg:text-base text-center font-medium border rounded focus:outline-none
    ${
      props.isLoading
        ? "bg-gray-400 text-gray-200 border-gray-400 cursor-not-allowed"
        : "bg-[#B38C32] text-white border-[#B38C32] hover:bg-white hover:text-[#B38C32] hover:cursor-pointer"
    } 
    duration-300 flex justify-center items-center`}
                    onClick={() => !props.isLoading && props.onBid()}
                  >
                    {props.isLoading ? <LoadingIndicator /> : "Bid Now"}
                  </div>

                  <div
                    className=" p-2 px-4  text-sm lg:text-base text-center text-black font-medium border rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white hover:border-gray-400 duration-300"
                    onClick={() => onShowModal(false)}
                  >
                    Cancel
                  </div>

                  {props.isSuccessBid ? (
                    <div className=" bg-green-100 text-green-600 p-4 flex items-center justify-between">
                      <span>Bid Added</span>
                      <FaCheck className=" animate-ping" />
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />

              {/* <div className=" flex flex-row items-start pt-8 space-x-4">
                <div className=" w-1/2">
                  <div className=" text-2xl font-semibold">Automated</div>
                  <div className=" font-light text-sm">Enter a maximum bid amount and the system will auto bid upto that amount.</div>
                </div>

                <div className=" w-1/2 flex space-y-2 flex-col">
                  <div className=" flex justify-between items-center">
                    <div className=" text-2xl font-medium ">N222 <span className=" text-lg">NGN</span></div>
                    <div className=" text-sm font-light text-gray-500">Auto bid up to</div>
                  </div>             
                  <div className=" flex items-center">
                    <div className=" p-3 w-12 text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                    <input 
                      type={'number'} 
                      min={0}
                      className=" w-full p-3 px-4 bg-gray-50 border rounded-r focus:outline-none"
                      name="autoBid"
                    />
                  </div>

                  <div 
                    className=" p-2 px-4 text-center text-black font-medium border rounded focus:outline-none cursor-pointer hover:bg-[#B38C32] hover:text-white hover:border-[#B38C32] duration-300"
                    onClick={() => alert('Soon!')}
                  >
                    Update Auto Bid
                  </div>
                </div>         
              </div>          */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBid;
