import axios from "axios";
import EmojiPicker, { 
  // EmojiStyle, 
  // Emoji
} from "emoji-picker-react";
import React, { useEffect, useRef, useState } from 'react'
import { 
  PiUser, 
  PiSignOut,
  PiSmiley,
  PiPaperclip,
  // PiPlayFill,
  PiUserPlus,
  PiUserMinus,
  PiPaperPlaneRightLight,
  PiDownloadSimpleBold,
  PiFiles,
} from "react-icons/pi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingThreeDots from "./LoadingThreeDots";
import Pagination from "./Pagination";

const MessagesContent = (props) => {
  const refFiles = useRef()
  const refBottom = useRef(null);

  const scrollToBottom = () => {
    refBottom?.current?.scrollIntoView({ bottom: 100, behavior: 'smooth' });
  };

  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [isLoadingMsg, setLoadingMsg] = useState(false)
  const [isLoadingSend, setLoadingSend] = useState(false)
  const [showBrowse, setShowBrowse] = useState(false)
  const [showRoom, setShowRoom] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [roomList, setRoomList] = useState([])
  const [pageRoomList, setPageRoomList] = useState(null)
  const [msgUser, setMsgUser] = useState(null)
  const [textMsg, setTextMsg] = useState('')
  const [targetId, setTargetId] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const limit = 15
  
  useEffect(() => {
    document.title = 'Messages | Paseero';

    getApi('ROOMLIST', '/api/chat/room_list?per_page='+limit+'&page='+1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  console.log({pageRoomList});
  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results, metadata } = response.data.response
            if(type === 'ROOMLIST') {
              setRoomList(results)
              setPageRoomList(metadata)
              setLoadingMore(false)
            }else if(type === 'MSGFIRST'){
              setMsgUser({
                ...results[0],
                messages: results[0]?.messages.reverse()
              })
              setLoadingMsg(false)
              setShowRoom(true)
              setTimeout(() => {
                scrollToBottom()
              }, 500);
            }else if(type === 'MSGLIST'){
              setMsgUser({
                ...results[0],
                messages: results[0]?.messages.reverse()
              })
              setLoadingMsg(false)
              setShowRoom(true)
            }else{
              setMsgUser(prevMsgUser => { return { ...prevMsgUser, is_following: results.is_following ? 1 : 0}})
            }
            setLoading(false)
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        setLoading(false)
      });
  }

  const handleMsgList = (id) => {
    setLoadingMsg(true)
    setTargetId(id)
    getApi('MSGFIRST', '/api/chat/message_list?targeted_user_id='+id)
  }

  const handleEmojiClick = (emojiData) => {
    setTextMsg(textMsg.concat(emojiData.emoji))
  }

  const handleSend = (type, file) => {
    if (!textMsg && type === 'all') {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please type a message!',
        confirmButtonColor: '#B38C32'
      })
    }
    setLoadingSend(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/chat/send_message';    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Type': 'multipart/form-data'
    };

    const bodyForm = new FormData()
    bodyForm.append('targeted_user_id', targetId);
    bodyForm.append('message', textMsg);
    if (attachment !== null) {
      bodyForm.append('attachment', attachment.file);
    }
    if (type === 'file') {
      bodyForm.append('attachment', file);
    }
    
    // const bodyParams = {
    //   targeted_user_id: targetId,
    //   message: textMsg,
    // };

    axios
      .post(apiUrl, bodyForm, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          setMsgUser({
            ...msgUser,
            messages: msgUser.messages.concat(results.message[0])
          })
          setTextMsg('')
          setAttachment(null)
          setShowBrowse(false)
          setLoadingSend(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { results } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: results.error,
          confirmButtonColor: '#B38C32'
        })
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (targetId !== '') {
        getApi('MSGLIST', '/api/chat/message_list?targeted_user_id='+targetId)
      }
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  useEffect(() => {
    const interval = setInterval(() => {
      getApi('ROOMLIST', '/api/chat/room_list?per_page='+limit+'&page='+(pageRoomList === null ? 1 : pageRoomList?.selectedPage))
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, pageRoomList]);

  const handleFollow = (id) => {
    getApi('FOLLOW', '/api/auth/user/following?user_id='+id)
  }

  const handleChangeFiles = (e) => {
    const { files } = e.target;
    // console.log('files',files);
    if (files && files.length) {
      // let val = []
      if (files[0].size > 5000000)
        return Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'File size maximal 5mb!',
          confirmButtonColor: '#B38C32'
        })
      // val.push()
      setAttachment({
        name: files[0].name,
        file: files[0]
      })
      handleSend('file', files[0])
    }    
  }

  function detectWindowSize () {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);        
  }

  useEffect(() => {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
    window.onresize = detectWindowSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.onresize])

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageRoomList?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('ROOMLIST', '/api/chat/room_list?per_page='+limit+'&page='+page)
  }
  
  return (
    <div className=" lg:w-[75%] flex flex-col lg:flex-row">
      <div className=" w-full lg:w-4/12 border-r h-full">
        <div className=" p-4 lg:p-6 lg:py-10 bg-gray-100 text-xl font-semibold border-b">Chat List</div>
        <div className=" flex flex-col">          
          {isLoading? (
            <div className=" flex justify-center mt-8">
              <LoadingThreeDots color={"#B38C32"} />
            </div>
          ): null }

          {!isLoading && roomList?.map(row => (
            <div 
              key={row.id}
              className={`flex flex-row items-center space-x-2 p-2 px-4 border-b hover:bg-gray-100 duration-100 cursor-pointer ${targetId === row.targeted_user_id ? 'bg-gray-200' : ''}`}
              onClick={() => {
                if (isMobile) {
                  props.setMsgUser(row)    
                  setTimeout(() => {
                    props.setShowModalMsg(true)
                  }, 100);
                }else{
                  handleMsgList(row?.targeted_user_id)
                }
              }}
            >
              <img 
                alt="avatar" 
                src={row?.targeted_user_photo !== '' ? row?.targeted_user_photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                className=" w-12 h-12 rounded-full"
              />
              <div className=" w-full">
                <div className=" flex flex-row justify-between items-center">
                  <div className=" font-semibold text-sm">{row.targeted_user_full_name}</div>
                  <div className=" text-xs">{row.message?.created_at_text}</div>                  
                </div>
                <div className=" line-clamp-1 text-xs text-gray-500 mt-1">{row?.message?.message ? row?.message?.message : 'File'}</div>
              </div>
            </div>
          ))}

          <Pagination
            isLoading={isLoadingMore}
            listCount={roomList?.length}
            page={pageRoomList}
            onClick={handlePagination}
          />
        </div>
      </div>
        
      <div className=" w-full lg:w-8/12 h-full flex justify-between flex-col">
        {isLoadingMsg? (
          <div className=" flex justify-center my-8">
            <LoadingThreeDots color={"#B38C32"} />
          </div>
        ): null }

        {!isLoadingMsg && showRoom ? (    
          <>
            <div>
              <div className=" p-4 lg:p-6 lg:py-[1.9rem] bg-gray-100 text-xl font-semibold border-b flex justify-between items-center">
                <div
                  className=" flex flex-row items-center space-x-2 hover:bg-gray-100 duration-100"
                  // onClick={() => alert("Soon!")}
                >
                  <img 
                    alt="avatar-profile" 
                    src={msgUser?.targeted_user_photo !== '' ? msgUser?.targeted_user_photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                    className=" w-12 h-12 rounded-full"
                  />
                  <div>
                    <div className=" text-sm lg:text-base font-semibold">{msgUser?.targeted_user_full_name}</div>
                    <div className=" text-xs text-gray-500 font-light">{msgUser?.is_online === 1 ? 'Online' : msgUser?.last_online}</div>
                  </div>
                </div>
                <div className=" flex items-center space-x-2">
                  <div
                    className=" border bg-white text-sm space-x-1 flex justify-center items-center p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300"
                    onClick={() => handleFollow(msgUser?.targeted_user_id)}
                  >
                    {msgUser?.is_following === 1 ? <PiUserMinus className=" text-lg" /> : <PiUserPlus className=" text-lg" />}
                    <span className=" hidden lg:block">{msgUser?.is_following === 1 ? 'Unfollow' : 'Follow'}</span>
                  </div>
                  
                  <Link 
                    to={`/author/${msgUser?.targeted_user_id}`}
                    className=" border bg-white text-sm space-x-1 flex justify-center items-center p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300"
                  >
                    <PiUser className=" text-lg" />
                    <span className=" hidden lg:block">View Profile</span>
                  </Link>
                  <div className=" border bg-white p-2 px-4 rounded focus:outline-none cursor-pointer hover:bg-gray-400 hover:text-white duration-300">
                    <PiSignOut className=" text-lg" />
                  </div>
                </div>
              </div>
              <div className=" p-4 py-6 flex flex-col max-h-screen overflow-y-auto">
                {msgUser?.messages?.map(row => (
                  row?.sender_id === user?.id ? (
                    <div
                      key={row?.id}
                      className=" relative flex py-2 self-end"
                    >
                      {/* <PiPlayFill className=" text-gray-600 absolute bottom-2 -right-[8px]" /> */}
                      <div className=" text-xs mr-2">{row?.created_at_text}</div>
                      <div className=" flex flex-col space-y-1">
                        {row?.attachment_type === 'image' ? (
                          <div className=" relative">
                            <img
                              alt={row?.attachment_type+'-image'}
                              src={row?.attachment_path}
                              className="w-40 h-24 object-cover rounded"
                            />
                            <a 
                              href={row?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                              className=" absolute bottom-2 right-2"
                            >
                              <PiDownloadSimpleBold className=" text-white text-xl hover:opacity-75"/>
                            </a>
                          </div>
                        ): null}
                        {row?.attachment_type === 'video' ? (
                          <video controls className=" w-80 rounded">
                            <source src={row?.attachment_path} type="video/mp4" />
                            <source ssrc={row?.attachment_path} type="video/ogg"></source>
                          </video>
                        ): null}
                        {row?.attachment_type === 'application' ? (
                          <div className=" flex flex-row space-x-3 p-2 border rounded bg-gray-50">
                            <PiFiles className=" text-4xl"/>
                            <div className=" flex flex-col space-y-2">
                              <div className=" text-sm">File</div>
                              <a 
                                href={row?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                                className=" flex flex-row space-x-2 items-start text-xs bottom-2 left-2 p-1 border hover:bg-gray-200 duration-300 rounded"
                              >
                                <span>Download</span>
                                <PiDownloadSimpleBold className=" hover:opacity-75"/>
                              </a>
                            </div>
                          </div>
                        ): null}
                        {!!row?.message ? (
                          <div className=" flex justify-end">
                            <div className=" p-2 px-5 rounded-full text-xs lg:text-sm rounded-br-none bg-blue-600 text-white">
                              {row?.message}
                            </div>
                          </div>
                        ): null}                        
                      </div>
                    </div>
                  ): (
                    <div
                      key={row?.id} 
                      className=" relative flex py-2 self-start"
                    >
                      {/* <div className=" p-2 px-5 rounded-full text-xs lg:text-sm rounded-tl-none bg-gray-600 text-white">
                        {row?.message}
                      </div> */}
                      {/* <PiPlayFill className=" text-gray-600 rotate-180 absolute top-2 -left-[8px]" /> */}                      
                      <div className=" flex flex-col space-y-1">
                        {row?.attachment_type === 'image' ? (
                          <div className=" relative">
                            <img
                              alt={row?.attachment_type+'-image'}
                              src={row?.attachment_path}
                              className="w-40 h-24 object-cover rounded"
                            />
                            <a 
                              href={row?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                              className=" absolute bottom-2 right-2"
                            >
                              <PiDownloadSimpleBold className=" text-white text-xl hover:opacity-75"/>
                            </a>
                          </div>
                        ): null}
                        {row?.attachment_type === 'video' ? (
                          <video controls className=" w-80 rounded">
                            <source src={row?.attachment_path} type="video/mp4" />
                            <source ssrc={row?.attachment_path} type="video/ogg"></source>
                          </video>
                        ): null}
                        {row?.attachment_type === 'application' ? (
                          <div className=" flex flex-row space-x-3 p-2 border rounded bg-gray-50">
                            <PiFiles className=" text-4xl"/>
                            <div className=" flex flex-col space-y-2">
                              <div className=" text-sm">File</div>
                              <a 
                                href={row?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                                className=" flex flex-row space-x-2 items-start text-xs bottom-2 left-2 p-1 border hover:bg-gray-200 duration-300 rounded"
                              >
                                <span>Download</span>
                                <PiDownloadSimpleBold className=" hover:opacity-75"/>
                              </a>
                            </div>
                          </div>
                        ): null}
                        {!!row?.message ? (
                          <div className=" flex justify-start">
                            <div className=" p-2 px-5 rounded-full text-xs lg:text-sm rounded-tl-none bg-gray-600 text-white">
                              {row?.message}
                            </div>
                          </div>
                        ): null}                        
                      </div>
                      <div className=" text-xs ml-2">{row?.created_at_text}</div>
                    </div>
                  )
                ))}
                <div ref={refBottom}/>
              </div>
            </div>
            <div className=" p-4 bg-gray-100 space-y-4 relative">
              {showBrowse ? (          
                <div className=" flex items-center flex-row cursor-pointer w-full ">
                  <div 
                    className=" rounded-l border w-full text-sm bg-white p-2 focus:outline-none"
                    onClick={() => refFiles.current.click()}
                  >
                    <label className=" text-xs">{`${attachment !== null ? attachment.name : '.zip, .pdf, .doc, .txt, .jpg or .png files only.'}`}</label>
                  </div>
                  <input 
                    className=" hidden rounded-l border bg-white p-2 focus:outline-none"
                    accept=".jpg,.jpeg,.png,.mp4,.pdf,.docx,.doc,.txt,.zip"
                    name="files"
                    ref={refFiles}
                    onChange={handleChangeFiles}
                    type="file"
                    // multiple
                  />
                  <button 
                    className=" bg-[#343A3F] p-2 px-4 text-sm text-white border rounded-r border-[#343A3F]"
                    onClick={() => refFiles.current.click()}
                    // onClick={() => alert('Soon!')}
                  >
                    Browse
                  </button>
                </div>
              ): null}
              <div className=" relative flex">              
                {/* <input 
                  className=" h-12 px-12 text-sm rounded-full border w-full focus:outline-none" 
                  placeholder="Type a message.."  
                  value={textMsg}
                  onFocus={() => {
                    setShowBrowse(false)
                    setShowEmoji(false)
                  }}
                  onChange={(e) => setTextMsg(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSend('all')}
                /> */}
                <textarea
                  row={1}
                  className=" h-12 pt-3 pl-12 pr-24 text-sm rounded-full border w-full focus:outline-none" 
                  placeholder="Type a message.."  
                  value={textMsg}
                  onFocus={() => {
                    setShowBrowse(false)
                    setShowEmoji(false)
                  }}
                  onChange={(e) => setTextMsg(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <PiSmiley 
                  className=" absolute top-3 left-4 text-2xl cursor-pointer" 
                  onClick={() => setShowEmoji(!showEmoji)}
                />
                {isLoadingSend ? (
                  <div className=" absolute top-3 right-6 text-2xl cursor-pointer" >
                    <LoadingThreeDots
                      size={'20'}
                      color={"#B38C32"}
                    />
                  </div>
                ):(
                  <>
                    <PiPaperclip 
                      className=" absolute top-3 right-6 text-2xl cursor-pointer" 
                      onClick={() => setShowBrowse(!showBrowse)}
                    />   
                    <PiPaperPlaneRightLight 
                      className=" absolute top-3 right-14 text-2xl cursor-pointer" 
                      onClick={() => handleSend('all')}
                    />
                  </>
                )}
              </div>
              {showEmoji ? (
                <div className=" absolute bottom-20">
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    autoFocusSearch={false}
                  />
                </div>
              ): null}
            </div>
          </>
        ): null}
      </div>
    </div>
  )
}

export default MessagesContent