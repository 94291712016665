import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ModalLoginRegister } from "../components";
import { setUser } from "../redux/authSlice";
import Layout from "./Layout";
import SEO from "../components/seo/SEO";

const Faq = () => {
  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionActive, setOptionActive] = useState({
    id: 0,
    isActive: false,
  });
  const [isLoading, setLoading] = useState(true);
  const [listFaq, setListFaq] = useState([]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    document.title = "FAQs | Paseero";
    window.scrollTo(0, 0);

    const fetchAll = () => {
      Promise.all([getApi("FAQS", "/api/master/faqs/index")])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          if (type === "FAQS") {
            setListFaq(results);
          }
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleShowHide = (id) => {
    setOptionActive({
      id: optionActive.id === id ? 0 : id,
      isActive: optionActive.id === id ? false : true,
    });
  };

  // const topics = [
  //   {
  //     id: 1,
  //     title: 'How do I join paseero.com?',
  //     desc: `<p>On any of the platform pages, use the 'signup' button at top right to navigate to the ad plans page. <br>
  //     1. Provided requested information such as full name, email address, password, and username. <br>
  //     2. Check the 'Accept terms and conditions' box. <br>
  //     3. Click the 'Create Account' button. <br>
  //     4. Check your email for the verification email. <br>
  //     5. Click the link in the verification email received to complete the registration process.​</p>`
  //   },
  //   {
  //     id: 2,
  //     title: 'Records of subscribed plans?',
  //     desc: `<p>To view the records of your purchased subscription plans, follow the steps below.<br><br>1. Login into your account.<br>2. In the dashboard, click 'Balance &amp; invoices'.<br>3. Navigate and view the records of your subscribed plans,  including invoices.</p>`
  //   },
  //   {
  //     id: 3,
  //     title: 'How to subscribe for an ad listing plan?​',
  //     desc: `<p>In order for you to complete the process of listing your ads for auction, an ad listing plan is required. The steps below highlights the ad listing plans.<br><br>1. To see the different ad listing plans, hover your cursor over the 'subscribe' button at the top left of the page, and then click the 'Ad Listing Plans' button button.<br>2. On the page for ad listing plans, choose a plan that suits your need and pay the ad listing fee as appropriate.<br>3. You will receive a confirmation email if the process is successful, and your posted ad will go live shortly.</p>`
  //   },
  //   {
  //     id: 4,
  //     title: 'How do I reset my account password?',
  //     desc: `<p>In the top right corner of any of the pages, click the 'login' button. On the 'login' page, click the 'Lost password?' and follow the subsequent prompts to conclusion, which include entering your registration email address. You will receive an email with the instructions for resetting your password.</p>`
  //   },
  //   {
  //     id: 5,
  //     title: 'Do you have a credit system?​',
  //     desc: `<p>Paseero.com has a credit system that allows you to buy credit using your credit cards, debit cards and Paypal accounts. The credit can be used to pay for any service on the platform. The credit system offers discounts as opposed to direct payment for the different services.</p>`
  //   },
  //   {
  //     id: 6,
  //     title: 'Do you make delivery?',
  //     desc: `<p>Paseero.com does not control the delivery of items. Each seller determines how purchased items are delivered to the buyers. Generally, you can find this information on the item page of interest. When in doubt, please contact the seller of the item that is of interest to you.</p>`
  //   },
  //   {
  //     id: 7,
  //     title: 'How do I pay for subscription plans?',
  //     desc: `<p>We accept only electronic payment. There are two main methods of payments, which are below.<br><br>1. Directly enter a valid credit/debit card or paypal account information to purchase different plans.<br>2. The platform grants every user an electronic wallet, which can be recharged using a valid debit/credit card or paypal account. To do this, go into your dashboard after registration. Click the 'balance &amp; invoices' option, and then choose the 'buy credit' button; enter a valid credit/debit/paypal information to finalise the purchase.<br>3. You will receive a confirmation email containing the purchase details if the transaction is successful.</p>`
  //   },
  //   {
  //     id: 8,
  //     title: 'How can I handle a dispute with a seller?',
  //     desc: `<p>Please, contact us using the email address contact@paseero.com or with the form on our 'contact us' page. We will do our best to investigate the dispute and make a prompt resolution.</p>`
  //   }
  // ]

  return (
    <Layout>
      <SEO
        title="Frequently Asked Questions (FAQs) | Paseero - Your Trusted Marketplace"
        description="Find answers to common questions about Paseero, including account registration, subscription plans, payment methods, dispute resolution, and more. Get instant support or contact us for further assistance"
        name="Paseero.ng | Paseero FAQs"
        type="website"
        robots="index, follow"
      />
      {/* <hr className=" my-12" /> */}
      <div className=" mt-4 lg:mt-8" />
      <div className=" flex justify-center w-full">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" flex flex-col lg:flex-row lg:justify-between lg:items-center space-y-8 lg:space-y-0 lg:space-x-20 ">
            <img
              alt="faq-img"
              src="https://i.ibb.co/wLfdbmk/Whats-App-Image-2024-01-04-at-16-59-29-1.jpg"
              className=" lg:w-1/2"
            />
            <div className=" lg:w-1/2 flex flex-col space-y-4">
              <h1 className=" text-black text-xl lg:text-3xl font-bold">
                Frequently Asked Questions (FAQs)
              </h1>
              <div className=" text-blacl text-sm lg:text-xl font-medium">
                Find instant answers for FAQs on this page
              </div>
              <p className=" text-black text-sm lg:text-base leading-7 tracking-wide text-justify">
                The 'Post Ad' button below provides you a quick access to start
                listing your items for sale.
              </p>
              <div>
                <button
                  className=" p-3 px-8 text-sm lg:text-base rounded text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer"
                  onClick={() => {
                    if (auth === null) {
                      setShowModal(true);
                    } else {
                      dispatch(setUser({ ...user, adTemp: null }));
                      setTimeout(() => {
                        navigate("/add-listing");
                      }, 100);
                    }
                  }}
                >
                  Post Ad
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center w-full border mt-12 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">Common FAQs</div>
          <div className=" mt-2 text-sm lg:text-xl text-[#212529]">
            Click the topics below to see details
          </div>
        </div>
      </div>

      <div className=" flex justify-center w-full mt-12">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" grid grid-cols-1 gap-4 gap-x-8">
            {!isLoading &&
              listFaq.map((row) => (
                <div
                  key={row.id}
                  className=" p-3 lg:p-4 lg:px-6 bg-white border rounded cursor-pointer"
                  onClick={() => handleShowHide(row.id)}
                >
                  <div className=" flex items-center justify-between">
                    <h2 className=" text-sm lg:text-xl font-semibold">
                      {row.title}
                    </h2>
                    {optionActive.id === row.id && optionActive.isActive ? (
                      <FaChevronDown className=" lg:text-2xl" />
                    ) : (
                      <FaChevronRight className=" lg:text-2xl" />
                    )}
                  </div>
                  {optionActive.id === row.id && optionActive.isActive ? (
                    <div
                      className=" text-sm lg:text-lg leading-7 font-light my-4"
                      dangerouslySetInnerHTML={{ __html: row.description }}
                    />
                  ) : null}
                </div>
              ))}
          </div>

          <div className=" mt-8 lg:mt-20 font-bold text-xl lg:text-3xl tracking-wide">
            For additional support
          </div>
          <div className=" text-sm lg:text-xl mt-4">
            Contact us via email at contact@paseero.ng or go to our dedicated
            contact page using the&nbsp;
            <Link
              to={"/contact-us"}
              className=" text-[#B38C32] hover:underline font-medium"
            >
              contact us&nbsp;
            </Link>
            link.
          </div>
        </div>
      </div>

      <hr className=" my-12" />

      {showModal ? (
        <ModalLoginRegister type={1} onShowModal={() => setShowModal(false)} />
      ) : null}
    </Layout>
  );
};

export default Faq;
