import React, { useEffect, useState } from "react";
import {
  PiChatText,
  PiLifebuoy,
  PiLockSimple,
  PiEnvelopeSimple,
} from "react-icons/pi";
import { AiOutlineSync } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";

import Layout from "./Layout";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ModalBankAccount, ModalLoginRegister } from "../components";
import Swal from "sweetalert2";
import { setUser } from "../redux/authSlice";
import SEO from "../components/seo/SEO";

const HowItWorks = () => {
  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [how, setHow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingBank, setLoadingBank] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [showModalBank, setShowModalBank] = useState(false);
  const [listBank, setListBank] = useState([]);
  const [stateBank, setStateBank] = useState({
    accountName: "",
    businessName: "",
    iban: "",
    swiftCode: "",
    country: 1,
    phoneNumber: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (user?.check_bank_information === 1) {
      getApi("BANK", "/api/master/banks");
    }

    const fetchAll = () => {
      Promise.all([getApi("HOW", "/api/master/how_it_work/index")])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          if (type === "HOW") {
            setHow(results);
          } else {
            setListBank(results);
            // setStateBank(prevState => {
            //   return {
            //     ...prevState,
            //     swiftCode: results[0].code
            //   }
            // })
          }
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleLink = () => {
    if (auth === null) {
      setShowModal(true);
    } else {
      if (user?.check_bank_information === 1) {
        setShowModalBank(true);
      } else {
        if (user?.membership.length > 0) {
          dispatch(setUser({ ...user, adTemp: null }));
          setTimeout(() => {
            navigate("/add-listing");
          }, 100);
        } else {
          Swal.fire({
            icon: "warning",
            title: "You have no active ad plan(s)",
            text: `Purchase an ad plan to start posting ads.`,
            showCancelButton: true,
            confirmButtonText: "Get ad plan",
            confirmButtonColor: "#B38C32",
          }).then((result) => {
            if (result.isConfirmed) {
              // navigate('/ad-pricings')
              navigate("/my-account?tabs=membership");
            }
          });
        }
      }
    }
  };

  const handleChangeBank = (e) => {
    const { name, value } = e.target;
    setStateBank((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmitBank = () => {
    setLoadingBank(true);
    const apiUrl = process.env.REACT_APP_API_URL + "/api/auth/bank-create";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      account_name: stateBank?.accountName,
      business_name: stateBank?.businessName,
      iban: stateBank?.iban,
      swift_code_bic: stateBank?.swiftCode,
      country: stateBank?.country,
      // phone_number: stateBank?.phoneNumber,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response;
          setTimeout(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: messages,
              confirmButtonColor: "#B38C32",
            });
          }, 500);
          let userNew = {
            ...user,
            bank: results.bank,
          };
          // dispatch(clearUser())
          dispatch(setUser(userNew));
          setLoadingBank(false);
          setShowModalBank(false);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        const { messages } = error.response.data.response;
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: messages,
          confirmButtonColor: "#DC2625",
        });
        setLoadingBank(false);
      });
  };

  return (
    <Layout>
      <SEO
        title="How It Works | Paseero – Buy & Sell with Ease"
        description="Learn how Paseero works! Easily buy, sell, and list items for auction. Follow simple steps to create an account, subscribe to plans, and start selling today."
        name="Paseero.ng | How Paseero Works"
        type="website"
        robots="index, follow"
      />

      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">
            How it works
          </div>
          <div className=" mt-2 text-sm lg:text-xl text-[#212529]">
            {how[0]?.title}
          </div>
        </div>
      </div>
      <div className=" flex justify-center w-full py-8 lg:py-16 bg-white">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-8 w-[1300px] px-4 lg:px-0">
          {!isLoading &&
            how[0]?.detail?.map((row) => (
              <div key={row.id} className=" flex justify-start space-x-4">
                <div>
                  <PiChatText className=" text-white text-2xl lg:text-4xl bg-[#b38c32] p-1 rounded" />
                </div>
                <div>
                  <div
                    className=" lg:text-xl font-bold tracking-wide"
                    dangerouslySetInnerHTML={{ __html: row.title }}
                  />
                  <div
                    className=" leading-5 lg:leading-8 text-sm lg:text-lg font-normal mt-3"
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className=" flex justify-center w-full py-12">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl font-bold text-[#212529]">
            {how[1]?.title}
          </div>
          <div className=" bg-white p-8 shadow-xl mt-8 rounded-xl text-center">
            <div className=" text-sm lg:text-xl font-semibold text-[#B38B32] text-blink">
              Warning!
            </div>
            <div className=" text-sm lg:text-xl font-semibold text-[#B38B32] text-blink">
              {how[1]?.description}.
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-center w-full py-8 lg:py-16 bg-white">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-8 w-[1300px] px-4 lg:px-0">
          {!isLoading &&
            how[1]?.detail?.map((row) => (
              <div key={row.id} className=" flex justify-start space-x-4">
                <div>
                  <PiChatText className=" text-white text-2xl lg:text-4xl bg-[#b38c32] p-1 rounded" />
                </div>
                <div>
                  <div
                    className=" lg:text-xl font-bold tracking-wide"
                    dangerouslySetInnerHTML={{ __html: row.title }}
                  />
                  <div
                    className=" leading-5 lg:leading-8 text-sm lg:text-lg font-normal mt-3"
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className=" flex justify-center w-full py-4 lg:py-8">
        <div className=" w-[1300px] ">
          <div className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-20 items-center px-4 lg:px-0">
            <img
              alt="img how"
              src={how[0]?.image}
              className=" rounded lg:w-1/2"
            />
            <div className=" space-y-6">
              <div className=" text-xl lg:text-3xl font-bold">
                Quick start on the Platform
              </div>
              <div className=" lg:text-xl">
                The buttons below give you a quick start on the platform after
                fully completing the registration process.
              </div>
              <div className=" flex-row space-x-4">
                <button
                  className="p-3 px-8 rounded text-sm lg:text-base text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer"
                  onClick={() => handleLink()}
                >
                  Post Ad
                </button>
                <Link
                  to={"/market-place"}
                  className="p-3 px-8 rounded text-sm lg:text-base text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer"
                >
                  Market Place
                </Link>
              </div>
            </div>
          </div>
          <div className=" py-8 lg:py-16 px-4 lg:px-0">
            <div className=" text-xl lg:text-3xl font-bold">
              Why Work with Us
            </div>
            <div className=" grid grid-cols-1 lg:grid-cols-4 gap-6 lg:gap-8 pt-8 lg:pt-16">
              <div className=" flex flex-col items-center text-center justify-center shadow-xl p-6 lg:py-12 lg:px-6 space-y-2 lg:space-y-6 border hover:scale-105 duration-300 cursor-pointer">
                <PiLifebuoy className=" text-5xl lg:text-8xl text-[#B38C32]" />
                <div className=" font-bold lg:text-xl">24/7 Support</div>
                <div className=" text-sm lg:text-xl font-light">
                  We're committed to providing support to our clients as soon as
                  we receive complaints.
                </div>
              </div>

              <div className=" flex flex-col items-center text-center justify-center shadow-xl p-6 lg:py-12 lg:px-6 space-y-2 lg:space-y-6 border hover:scale-105 duration-300 cursor-pointer">
                <PiLockSimple className=" text-5xl lg:text-8xl text-[#B38C32]" />
                <div className=" font-bold lg:text-xl">Secure Payment</div>
                <div className=" text-sm lg:text-xl font-light">
                  We provide a secure online payment system for clients who
                  choose this payment method.
                </div>
              </div>

              <div className=" flex flex-col items-center text-center justify-center shadow-xl p-6 lg:py-12 lg:px-6 space-y-2 lg:space-y-6 border hover:scale-105 duration-300 cursor-pointer">
                <PiEnvelopeSimple className=" text-5xl lg:text-8xl text-[#B38C32]" />
                <div className=" font-bold lg:text-xl">Regular Free Ads</div>
                <div className=" text-sm lg:text-xl font-light">
                  Free ads using coupon codes are provided occasionally to
                  appreciate our clients .
                </div>
              </div>

              <div className=" flex flex-col items-center text-center justify-center shadow-xl p-6 lg:py-12 lg:px-6 space-y-2 lg:space-y-6 border hover:scale-105 duration-300 cursor-pointer">
                <AiOutlineSync className=" text-5xl lg:text-8xl text-[#B38C32]" />
                <div className=" font-bold lg:text-xl">Connect with others</div>
                <div className=" text-sm lg:text-xl font-light">
                  We provide a platform that allows you to follow and message
                  other users.
                </div>
              </div>
            </div>
            <div className="lg:text-xl pt-16">
              For additional questions, please use the&nbsp;
              <Link
                to={"/contact-us"}
                className=" text-[#B38C32] hover:underline font-medium"
              >
                contact us&nbsp;
              </Link>
              page.
            </div>
            <div className=" lg:text-xl pt-8">
              Please see the&nbsp;
              <Link
                to={"/terms-conditions"}
                className=" text-[#B38C32] hover:underline font-medium"
              >
                terms and conditions&nbsp;
              </Link>
              and&nbsp;
              <Link
                to={"/privacy-policy"}
                className=" text-[#B38C32] hover:underline font-medium"
              >
                privacy policy&nbsp;
              </Link>
              page.
            </div>
          </div>
        </div>
      </div>
      {/* <hr className=" my-12" /> */}

      {showModalBank ? (
        <ModalBankAccount
          {...stateBank}
          listBank={listBank}
          onChange={handleChangeBank}
          onShowModal={setShowModalBank}
          onSubmit={handleSubmitBank}
          isLoading={isLoadingBank}
          onHandleLink={() => {
            if (user?.membership.length > 0) {
              dispatch(setUser({ ...user, adTemp: null }));
              setTimeout(() => {
                navigate("/add-listing");
              }, 100);
            } else {
              Swal.fire({
                icon: "warning",
                title: "You have no active ad plan(s)",
                text: `Purchase an ad plan to start posting ads.`,
                showCancelButton: true,
                confirmButtonText: "Get ad plan",
                confirmButtonColor: "#B38C32",
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate('/ad-pricings')
                  navigate("/my-account?tabs=membership");
                }
              });
            }
          }}
        />
      ) : null}

      {showModal ? (
        <ModalLoginRegister
          type={1}
          onShowModal={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default HowItWorks;
