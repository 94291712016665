import React, { useEffect } from "react";
import { Header, Footer } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth, clearUser, setUser } from "../redux/authSlice";
import axios from "axios";
import { clearFilter, setShowNav } from "../redux/filterSlice";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const getMyAdsCount = () => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/ad/count";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          const { results } = response.data.response;
          dispatch(setUser({ ...user, total_ads: results.total_ads }));
        }
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(clearAuth());
          dispatch(clearUser());
          dispatch(clearFilter());
          dispatch(setShowNav(false));
          navigate("/");
        }
      });
  };

  useEffect(() => {
    if (auth && auth.access_token) {
      getMyAdsCount();
    }
  }, []);

  return (
    <div className=" font-poppins bg-[#FAFAFB]">
      <Header />
      {/* <Outlet /> */}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
