import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { LuFileAudio, LuFileText, LuFileVideo } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import Layout from "./Layout";
import { ModalLoginRegister, ModalPayMembership } from "../components";
import Swal from "sweetalert2";
import SEO from "../components/seo/SEO";

const AdPricings = () => {
  const auth = useSelector((state) => state.auth.data);
  const navigate = useNavigate();

  const [adPricings, setAdPricings] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPay, setLoadingPay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPay, setShowModalPay] = useState(false);
  const [membershipSelected, setMembershipSelected] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchAll = () => {
      Promise.all([getApi("/api/master/ads_pricing/index")])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error promise data:", error);
          setLoading(false);
        });
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response;
          setAdPricings(results);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSelect = (item) => {
    if (auth === null) {
      setShowModal(true);
      return false;
    }
    setMembershipSelected(item);
    setTimeout(() => {
      setShowModalPay(true);
    }, 100);
  };

  const handleMembershipPay = (method) => {
    setLoadingPay(true);
    const apiUrl = process.env.REACT_APP_API_URL + "/api/membership/pay";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      membership_plan: membershipSelected?.id,
      payment_method: method,
      // is_wna: 1, // For default value
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then((response) => {
        console.log({ response });
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response;
            window.location.replace(results.url);
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        const { messages } = error.response.data.response;
        if (error.response.data.code === 401) {
          Swal.fire({
            title: "Insufficient Credit",
            text: messages,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#B38C32",
            confirmButtonText: "Buy credit",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/my-account?tabs=orders");
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: messages,
            confirmButtonColor: "#DC2625",
          });
        }
        setLoadingPay(false);
      });
  };

  return (
    <Layout>
      <SEO
        title="Ad Pricings | Paseero.ng - Affordable Ad Listings in Nigeria"
        description="Discover flexible and affordable ad pricing plans on Paseero.ng. Choose the best package to promote your ads and reach more buyers in Nigeria."
        name="Paseero.ng | Ad Pricing"
        type="website"
        robots="index, follow"
      />
      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className="text-xl lg:text-3xl text-[#212529]">Ad Plans</div>
          <div className=" mt-2 text-sm lg:text-xl text-[#212529]">
            These are pricing plans that provide you ad listing bundles.
          </div>
        </div>
      </div>
      <div className=" text-xl lg:text-3xl text-blue-700 text-center font-bold py-8">
        All ad pricing plans include
      </div>
      <div className=" flex justify-center w-full">
        <div className=" flex flex-col space-y-8 w-[1300px] px-4 lg:px-0">
          <div className=" flex flex-col lg:flex-row space-y-8 lg:space-y-0 justify-between bg-white rounded border p-4 lg:p-8">
            {!isLoading &&
              adPricings?.static_membership_detail?.map((row, index) => (
                <div
                  key={row.id}
                  className=" flex flex-row items-center space-x-2"
                >
                  {index === 0 ? (
                    <LuFileText className=" text-5xl" />
                  ) : index === 1 ? (
                    <LuFileVideo className=" text-5xl" />
                  ) : (
                    <LuFileAudio className=" text-5xl" />
                  )}
                  <div className="">
                    <div className=" lg:text-xl font-bold">{row?.title}</div>
                    <div className=" text-sm lg:text-xl">
                      {row?.description}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className=" flex flex-col lg:flex-row lg:flex-wrap justify-center gap-12">
            {!isLoading &&
              adPricings?.membership_plan?.map((row, index) => (
                <div
                  key={row.id}
                  className=" flex flex-col justify-start text-center items-center space-y-8 lg:w-[30%] bg-white rounded-xl shadow-xl py-12 hover:scale-105 duration-300 cursor-pointer"
                >
                  <div className=" p-4 rounded-full rounded-bl-none bg-[#B38C32] border-8 border-[#D7E0FB]">
                    <i
                      className={`fa ${row.image} text-white text-xl lg:text-4xl w-6 h-6 lg:w-11 lg:h-11`}
                    />
                  </div>
                  <div className=" text-xl lg:text-4xl font-bold text-black">
                    {row?.title}
                  </div>
                  <div className=" py-4 bg-[#B38C32] text-white text-xl lg:text-3xl font-bold w-full">
                    ₦{row?.price}
                  </div>
                  <div className=" font-bold px-4 text-sm lg:text-base">
                    {row?.description}
                  </div>
                  <div className=" space-y-3">
                    {row?.detail?.map((res) => (
                      <div
                        key={res?.id}
                        className=" items-center flex flex-row space-x-2"
                      >
                        <FaCheck />
                        <div className=" text-sm lg:text-base">
                          {res?.title}
                        </div>
                      </div>
                    ))}
                  </div>
                  {row?.is_having === 1 ? (
                    <button className="p-3 px-6 text-sm lg:text-base rounded text-[#B38C32] font-medium tracking-wide bg-white border border-[#B38C32]">
                      Current Plan
                    </button>
                  ) : (
                    <button
                      className="p-3 px-6 text-sm lg:text-base rounded text-white font-medium tracking-wide bg-[#B38C32] border border-[#B38C32] hover:bg-white hover:text-[#B49C32] duration-300 cursor-pointer"
                      onClick={() => handleSelect(row)}
                    >
                      Select Packages
                    </button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <hr className=" my-12" />

      {showModal ? (
        <ModalLoginRegister type={1} onShowModal={() => setShowModal(false)} />
      ) : null}

      {showModalPay ? (
        <ModalPayMembership
          selected={membershipSelected}
          onMembershipPay={handleMembershipPay}
          onShowModal={setShowModalPay}
          isLoading={isLoadingPay}
        />
      ) : null}
    </Layout>
  );
};

export default AdPricings;
