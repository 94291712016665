import React from 'react'
import { Link } from "react-router-dom";
import { useCountdown } from "../utils/useCountdown";

const ListOffersItem = (props) => {

  const ExpiredCountdown = ({date}) => {
    const NOW_IN_MS = new Date(date).getTime();
    const [days, hours, minutes, seconds] = useCountdown(NOW_IN_MS);
    
    return (
      <div className=" font-medium text-xs lg:text-xl">
        {days < 0 ? `0d 0:0:0` : `${days}d ${hours}:${minutes}:${seconds}`}
      </div>
    )
  }

  return (
    <div 
      key={props?.id}
      className=" border flex flex-col lg:flex-row rounded p-1 lg:p-3 justify-between lg:items-center"
    >
      <div className=" flex items-start space-x-2 lg:space-x-4 ">
        <Link 
          to={`/ads/${props?.slug}`}
          className=" w-2/5 lg:w-auto"  
        >
          <img 
            alt={props?.title}
            // src={props?.photos[0]?.url}
            src={props?.thumbnail}
            className=" h-32 w-44 rounded object-cover"
          />
        </Link>
        <div className=" w-3/5 lg:w-auto">
          <Link 
            to={`/ads/${props?.slug}`}
            className=" lg:text-xl font-medium hover:underline line-clamp-1"
          >
            {props?.title}
          </Link>
          <div>
            {/* <div className=" text-sm lg:text-lg font-bold">{props?.actual_price_text} {props?.bid_label === 'Won' ? ' - '+props?.deposit_amount_text + ' - ₦'+ (props?.actual_price-props?.deposit_amount)  : ''}</div> */}
            <div className=" flex flex-col text-sm lg:text-lg">
              {props?.bid_label === 'Won' ? 
                <div>
                  <span className=" text-sm">Ad price: </span> 
                  <span className=" font-bold">{props?.actual_price_text}</span>
                </div>
              : props?.bid_label === 'Buying' ?  
                <div>
                  <span className=" text-sm">Top bid: </span> 
                  <span className=" font-bold">{props?.actual_price_text}</span>
                </div>
              : props?.bid_label === 'Selling' ?  
                <div>
                  <span className=" text-sm text-green-500">Current price: </span> 
                  <span className=" font-bold text-green-500">{props?.actual_price_text}</span>
                </div>
              : <span className=" font-bold">{props?.actual_price_text}</span> }
              
              {props?.bid_label === 'Won' ?
                <div>
                  <span className=" text-sm">Bid deposit: </span> 
                  <span className=" font-bold">{props?.deposit_amount_text}</span>
                </div> 
              : null }
              {props?.bid_label === 'Won' ? 
                <div>
                  <span className=" text-sm">Payable amount: </span>
                  <span className=" font-bold">{'₦'+(props?.actual_price-props?.deposit_amount)}</span>
                </div>
              : null }
              {props?.bid_label === 'Selling' ?
                <div>
                  <span className=" text-sm">Starting price: </span> 
                  <span className=" font-bold">{props?.starting_price_text}</span>
                </div> 
              : null }
            </div>
            {/* {props?.bid_label === 'Buying' ? (
              <div className=" text-xs lg:text-sm text-gray-500">Top Bid</div>
            ): null} */}
            {props?.bid_label === 'Buying' ? (
              // <>
              //   <div className=" text-sm lg:text-lg font-bold">{props?.my_bid_price_text}</div>
              //   <div className=" text-xs lg:text-sm text-gray-500">My Bid</div>
              // </>
              <div>
                  <span className=" text-sm">My bid: </span> 
                  <span className=" font-bold">{props?.my_bid_price_text}</span>
                </div>
            ): null}
            <div className=" flex flex-row space-x-2 lg:hidden items-center ">
              <div>
                <div className={` ${props?.bid_label === 'Won' ? 'bg-green-500' : props?.bid_label === 'Lost' ? 'bg-gray-500' : 'bg-blue-500' } text-white rounded p-1 px-3 text-xs lg:text-base `}>{props?.bid_label}</div>
              </div>
              {props?.bid_label === 'Buying' ? (
                <div className=" text-right" >
                  <ExpiredCountdown date={props?.expired_at} />
                  <div className=" text-xs lg:text-sm text-gray-500">Time left</div>
                </div>
              ):null}

              {props?.bid_label === 'Won' ? (
                <div 
                  className="p-1 text-xs px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                  onClick={props.onPayNow}
                >
                  Pay Now
                </div>
              ): null}
            </div>  
          </div>
        </div>       
      </div>

      <div className=" hidden lg:flex lg:w-1/3 justify-between items-center ">
        <div>
          <div className={` ${props?.bid_label === 'Won' ? 'bg-green-500' : props?.bid_label === 'Lost' ? 'bg-gray-500' : 'bg-blue-500' } text-white rounded p-1 px-3 text-sm `}>{props?.bid_label}</div>
        </div>
        {props?.bid_label === 'Buying' ? (
          <div className=" text-right" >
            <ExpiredCountdown date={props?.expired_at} />
            {/* <div className=" font-medium text-xl">90d 14:01:30</div> */}
            <div className=" text-sm text-gray-500">Time left</div>
          </div>
        ):null}

        {props?.bid_label === 'Won' ? (
          <div 
            className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
            onClick={props.onPayNow}
          >
            Pay Now
          </div>
        ): null}
      </div>
    </div>
  )
}

export default ListOffersItem