import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import {
  Home,
  AboutUs,
  HowItWorks,
  NoPage,
  Categories,
  AdPricings,
  MarketPlace,
  ContactUs,
  Faq,
  PrivacyPolicy,
  TermsConditions,
  CreditSystem,
  Blog,
  MyAccount,
  AddListing,
  AdDetail,
  AuthorDetail,
  Callback,
} from "./pages";
import TrackPageView from "./pages/TrackPageView";


function ProtectedRoute({ children }) {
  const auth = useSelector(state => state.auth.data)  
  if (auth === null) {
    return <Navigate to="/" replace />
  }
  return children
}

const App = () => {
  return (
    <Provider store={store}>
        {typeof window !== "undefined" ? (
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
            <TrackPageView />
              <Routes>
                <Route index element={<Home />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="how-it-works" element={<HowItWorks />} />
                <Route path="categories" element={<Categories />} />
                <Route path="ad-pricings" element={<AdPricings />} />
                <Route path="market-place" element={<MarketPlace />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="faq" element={<Faq />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-conditions" element={<TermsConditions />} />
                <Route path="credit-system" element={<CreditSystem />} />
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:slug" element={<Blog />} />
                {/* <Route path="listing/:id/:title" element={<AdDetail />} /> */}
                <Route path="ads/:slug" element={<AdDetail />} />
                {/* <Route path="author/:userid" element={<AuthorDetail />} /> */}
                <Route path="author/:slug" element={<AuthorDetail />} />
                <Route
                  path="my-account"
                  element={
                    <ProtectedRoute>
                      <MyAccount />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="add-listing"
                  element={
                    <ProtectedRoute>
                      <AddListing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="add-listing/:id"
                  element={
                    <ProtectedRoute>
                      <AddListing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="callback"
                  element={
                    <ProtectedRoute>
                      <Callback />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NoPage />} />
              </Routes>
            </BrowserRouter>
          </PersistGate>
        ) : null}
      </Provider>
  );
};

export default App;
