import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingThreeDots from "./LoadingThreeDots";

const ListSlider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [listAd, setListAd] = useState([]);

  useEffect(() => {
    fetchAds();
  }, []);

  const fetchAds = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/category/list?carousel=0`;
      const response = await axios.get(apiUrl, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        setListAd(response.data.response.results);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToScroll: 1, arrows: false } },
      { breakpoint: 600, settings: { slidesToScroll: 1, arrows: false } },
      { breakpoint: 480, settings: { slidesToScroll: 1, arrows: false } },
      { breakpoint: 325, settings: { slidesToScroll: 1, arrows: false } },
    ],
  };

  return (
    <div className="flex justify-center w-full pt-4 lg:pt-12">
      <div className="w-full px-2 md:px-0 md:w-[768px] lg:w-[1300px]">
        {isLoading ? (
          <div className="flex justify-center pb-8">
            <LoadingThreeDots size="50" color="#B38C32" />
          </div>
        ) : (
          <Slider {...settings} className="mx-0 md:mx-4 lg:mx-0">
            {listAd.map(({ id, slug, thumbnail, title }) => (
              <Link
                to={`/ads/${slug}`}
                key={`list-slider-${id}`}
                className="h-28 w-28 lg:h-40 lg:w-40 rounded px-1"
              >
                <img
                  src={thumbnail}
                  alt={title || "Sponsored Ad"}
                  className="h-28 w-28 lg:h-40 lg:w-40 rounded object-cover border"
                />
              </Link>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ListSlider;
