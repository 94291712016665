
import axios from "axios"
import React, { useEffect, useState } from 'react'
import { 
  FaRegTrashAlt, 
  FaRegCalendar
} from 'react-icons/fa'
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import LoadingThreeDots from "./LoadingThreeDots"
import Pagination from "./Pagination"

const CommentContent = () => {
  const auth = useSelector(state => state.auth.data)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(true)
  const [listDiscussion, setListDiscussion] = useState([])
  const [pageDiscussion, setPageDiscussion] = useState(null)
  const limit = 15

  useEffect(() => {
    document.title = 'Comments | Paseero';

    const fetchAll = () => {
      Promise.all([
        getApi('MYDISCUSSION', '/api/ad/my_discussion?per_page='+limit),
      ])
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYDISCUSSION') {
            setListDiscussion(results)
            setPageDiscussion(metadata)
            setLoadingMore(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleDelete = (id, index) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/discussion-delete';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      id: id,
      // reply_id: 1, // Optional
    };

    Swal.fire({
      title: "Warning",
      text: 'Are you sure to delete this discussion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I Confirm',
      confirmButtonColor: '#B38C32',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(apiUrl, bodyParams, { headers })
          .then(response => {
            // console.log({response});
            if (response.status === 200) {  
              let arr = [...listDiscussion]
              arr.splice(index, 1);
              setListDiscussion(arr) 
              Swal.fire({
                icon: 'success',
                title: 'Accepted!',
                text: 'Your discussion has been deleted.',
                confirmButtonColor: '#B38C32'
              })
            }else{
              // Do something else
            } 
          })
          .catch(error => {
            console.error('Error making POST request:', error);
          });          
      }
    })
  }

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageDiscussion?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('MYDISCUSSION', '/api/ad/my_discussion?per_page='+limit+'&page='+page)
  }

  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">Ads Discussion</div>
        <div className=" mt-2 text-sm lg:text-lg">Here you can manage your comments and feedback.</div>
      </div>
      {/* <select 
        className=" p-2 w-1/3 bg-gray-50 border rounded focus:outline-none mt-8"
        name="adsType"
        // value={state.adsType}
        // onChange={handleChange}
      >
        <option value={0}>All Comment</option>
        <option value={1}>Published</option>
      </select> */}

      <div className=" flex flex-col space-y-4 mt-4 lg:pr-8 h-[1000px] overflow-y-auto">
        {/* <div className=" border flex flex-row rounded p-3 justify-between items-center">
          <div className=" flex items-start space-x-4 ">
            <div className=" w-1/4">
              <img 
                alt="img-comment"
                src="http://154.56.56.56/staging/api/uploads/ads/photos/3/566831602_Xiaomi-Redmi-Note-13-Pro-Max-5G-Bawa-Lensa-108MP-RAM-6GB.jpg"
                className=" h-36 w-full rounded object-cover"
              />
            </div>
            <div className=" flex flex-col h-36 justify-between w-3/4">
              <p className=" font-light line-clamp-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem ipsum dolor sit amet.
              </p>
              
              <div className=" flex space-x-4 justify-between ">
                <div className=" flex flex-row items-center space-x-4">
                  <div>
                    <div className=" p-1 px-2  bg-yellow-500 text-white rounded text-sm">Under Review</div>
                  </div>
                  <div className=" items-center flex text-sm">
                    <FaRegCalendar className="  mr-1"/>
                    <span>July 20, 2023 12:50 pm</span>
                  </div>
                </div>
                <div className="p-1 px-3 flex text-sm items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300">
                  <FaRegTrashAlt className="  mr-1"/>
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {isLoading ?
          <div className=" flex justify-center mt-8">
            <LoadingThreeDots
              size={'50'}
              color={"#B38C32"}
            />
          </div>
        : null }

        {!isLoading && listDiscussion.map((row, index) => (
          <div key={row.id} className=" border flex flex-col lg:flex-row space-y-4 lg:space-y-0 rounded lg:space-x-4 p-3 justify-between lg:items-center">  
            <div className=" w-full lg:w-1/4">
              <Link to={`/ads/${row?.ad?.slug}`}>
                <img 
                  alt="img-comment"
                  // src={row?.ad?.photos[0]?.url}
                  src={row?.ad?.thumbnail}
                  className=" h-40 lg:h-36 w-full rounded object-cover"
                  />
              </Link>          
            </div>
            <div className=" flex flex-col h-36 justify-between lg:w-3/4">
              <p className=" font-light line-clamp-3">
                {row?.content}
              </p>
              
              <div className=" flex space-x-4 justify-between ">
                <div className=" flex flex-row items-center space-x-4">
                  {/* <div>
                    <div className=" p-1 px-2  bg-yellow-500 text-white rounded text-sm">Under Review</div>
                  </div> */}
                  <div className=" items-center flex text-sm">
                    <FaRegCalendar className="  mr-1"/>
                    <span>{row?.created_at_text}</span>
                  </div>
                </div>
                <div 
                  className="p-1 px-3 flex text-sm items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                  onClick={() => handleDelete(row.id, index)}
                >
                  <FaRegTrashAlt className="  mr-1"/>
                  <span>Delete</span>
                </div>
              </div>
            </div>            
          </div>    
        ))}

        <Pagination
          isLoading={isLoadingMore}
          listCount={listDiscussion?.length}
          page={pageDiscussion}
          onClick={handlePagination}
        />

      </div>

    </div>
  )
}

export default CommentContent